import {
  Alert,
  Col,
  Dropdown,
  Form,
  Row,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  IWotTournamentRulesDto,
  WotTournamentRulesBattleHoursDtoAdapter,
} from '../../../../../../services/api/wot-tournaments-api.service';
import { HoursValues, Hours } from '../../../../../../utils/hours-utils';
import { toRoman } from '../../../../../../utils/number.util';
import { TankTiersValues, TankTiers } from '../../../../../../utils/tank-tiers';
import { TankTypesValues, TankTypes } from '../../../../../../utils/tank-types';
import {
  LoadingParts,
  selectLoadingState,
} from '../../../../loading/loadingSlice';
import { useSelector } from 'react-redux';
import {
  getWotTanksAsync,
  selectWotTanks,
} from '../../redux/wot-tournament-slice';
import { useAppDispatch } from '../../../../../../redux/store';
import { useEffect } from 'react';

export function WotTournamentFilters(props: {
  battlesLimitMode: IWotTournamentRulesDto['battlesLimitMode'];
  onBattlesLimitModeChange: (
    mode: IWotTournamentRulesDto['battlesLimitMode'],
  ) => void;

  battlesLimit: IWotTournamentRulesDto['battlesLimit'];
  onBattlesLimitChange: (
    battlesLimit: IWotTournamentRulesDto['battlesLimit'],
  ) => void;
  isBattlesLimitValid: boolean;

  battlesOfLimit: IWotTournamentRulesDto['battlesOfLimit'];
  onBattlesOfLimitChange: (
    battlesOfLimit: IWotTournamentRulesDto['battlesOfLimit'],
  ) => void;
  isBattlesOfLimitValid: boolean;

  acceptMergedBattles: IWotTournamentRulesDto['acceptMergedBattles'];
  onAcceptMergedBattlesChange: (
    acceptMergedBattles: IWotTournamentRulesDto['acceptMergedBattles'],
  ) => void;

  battleHoursUtc: IWotTournamentRulesDto['battleHoursUtc'];
  onBattleHoursUtcChange: (
    battleHoursUtc: IWotTournamentRulesDto['battleHoursUtc'],
  ) => void;
  isBattleHoursUtcValid: boolean;

  tankTiers: IWotTournamentRulesDto['tankTiers'];
  onTankTiersChange: (tankTiers: IWotTournamentRulesDto['tankTiers']) => void;
  isTankTiersValid: boolean;

  tankTypes: IWotTournamentRulesDto['tankTypes'];
  onTankTypesChange: (tankTypes: IWotTournamentRulesDto['tankTypes']) => void;
  isTankTypesValid: boolean;

  onlyRegularTanks: IWotTournamentRulesDto['onlyRegularTanks'];
  onOnlyRegularTanksChange: (
    onlyRegularTanks: IWotTournamentRulesDto['onlyRegularTanks'],
  ) => void;

  tanks: IWotTournamentRulesDto['tanks'];
  onTanksChange: (tanks: IWotTournamentRulesDto['tanks']) => void;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const loading = useSelector(selectLoadingState);
  const apiTanks = useSelector(selectWotTanks);

  const battleHours = WotTournamentRulesBattleHoursDtoAdapter.convertToHours(
    props.battleHoursUtc,
  );

  const tankTiers = props.tankTiers as unknown as Record<
    TankTiers,
    boolean | null
  >;

  const tankTypes = props.tankTypes as unknown as Record<
    TankTypes,
    boolean | null
  >;

  const tanksList = apiTanks?.filter((tank) => {
    if (
      tankTiers[tank.tier < 10 ? `0${tank.tier}` : `${tank.tier}`] === false
    ) {
      return false;
    }

    if (tankTypes[tank.type] === false) {
      return false;
    }

    if (props.onlyRegularTanks && tank.is_premium) {
      return false;
    }

    return true;
  });

  useEffect(() => {
    dispatch(getWotTanksAsync());
  }, [dispatch]);

  return (
    <>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={2}>
          {t('WotTournament.BattlesLimit', 'Battles limit')}
        </Form.Label>

        <Col sm={10}>
          <Stack direction="horizontal" gap={3}>
            <Dropdown>
              <Dropdown.Toggle variant="secondary">
                {props.battlesLimitMode === 'best'
                  ? t(
                      'WotTournament.SelectBattlesLimitModeBest',
                      'Best battles',
                    )
                  : props.battlesLimitMode === 'first'
                    ? t(
                        'WotTournament.SelectBattlesLimitModeFirst',
                        'First battles',
                      )
                    : t(
                        'WotTournament.UnlimitedBattlesLimitMode',
                        'No battles limit',
                      )}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  key="best"
                  active={props.battlesLimitMode === 'best'}
                  onClick={() => props.onBattlesLimitModeChange('best')}
                >
                  {t(
                    'WotTournament.SelectBattlesLimitModeBest',
                    'Best battles',
                  )}
                </Dropdown.Item>
                <Dropdown.Item
                  key="first"
                  active={props.battlesLimitMode === 'first'}
                  onClick={() => props.onBattlesLimitModeChange('first')}
                >
                  {t(
                    'WotTournament.SelectBattlesLimitModeFirst',
                    'First battles',
                  )}
                </Dropdown.Item>
                <Dropdown.Item
                  key="unlimited"
                  active={props.battlesLimitMode === 'unlimited'}
                  onClick={() => props.onBattlesLimitModeChange('unlimited')}
                >
                  {t(
                    'WotTournament.SelectBattlesLimitModeUnlimited',
                    'No battles limit',
                  )}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Form.Control
              className="standard-input-width"
              type="number"
              min={1}
              value={props.battlesLimit ?? ''}
              onChange={(event) =>
                props.onBattlesLimitChange(
                  event.target.value === '' ? null : +event.target.value,
                )
              }
              isInvalid={!props.isBattlesLimitValid}
              disabled={props.battlesLimitMode === 'unlimited'}
            />

            <p className="mt-2">{t('WotTournament.Of', 'of')}</p>

            <Form.Control
              className="standard-input-width"
              type="number"
              min={1}
              value={props.battlesOfLimit ?? ''}
              onChange={(event) =>
                props.onBattlesOfLimitChange(
                  event.target.value === '' ? null : +event.target.value,
                )
              }
              isInvalid={!props.isBattlesOfLimitValid}
              disabled={props.battlesLimitMode !== 'best'}
            />
          </Stack>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={2}>
          {t('WotTournament.AcceptMergedBattles', 'Accept merged battles')}
        </Form.Label>

        <Col sm={10}>
          <Stack direction="horizontal" gap={3}>
            <Form.Check
              type="switch"
              label={
                props.acceptMergedBattles
                  ? t('WotTournament.AcceptMergedBattlesEnabled', 'Enabled')
                  : t('WotTournament.AcceptMergedBattlesDisabled', 'Disabled')
              }
              checked={props.acceptMergedBattles}
              onChange={(event) =>
                props.onAcceptMergedBattlesChange(event.target.checked)
              }
              disabled={props.battlesLimitMode === 'best'}
            />
          </Stack>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={2}>
          {t('WotTournament.BattleHoursLimit', 'Battle hours')}
        </Form.Label>

        <Col sm={10}>
          <ToggleButtonGroup
            type="checkbox"
            value={[...HoursValues]
              .map((hour) => (battleHours[hour] === false ? undefined : hour))
              .filter((x) => x != null)}
            onChange={(value) =>
              props.onBattleHoursUtcChange(
                new WotTournamentRulesBattleHoursDtoAdapter(
                  HoursValues.reduce(
                    (acc, cur) => ({
                      ...acc,
                      [cur as Hours]: value.includes(cur),
                    }),
                    {} as {
                      [key in Hours]: boolean;
                    },
                  ),
                ),
              )
            }
          >
            {[...HoursValues].map((hour) => (
              <ToggleButton
                key={hour}
                id={`tbg-btn-hour-${hour}`}
                variant="outline-secondary"
                value={hour}
              >
                <span>{hour}</span>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>

          <p>
            {props.isBattleHoursUtcValid ? (
              <Form.Text className="text-muted">
                {t(
                  'WotTournament.BattleHoursLimitNote',
                  'The time is in +03 timezone.',
                )}
              </Form.Text>
            ) : (
              <Alert variant="danger" className="mt-2 pt-1 pb-1">
                {t(
                  'WotTournament.BattleHoursLimitAlert',
                  'You have disabled all battle hours. At least one hour must be selected.',
                )}
              </Alert>
            )}
          </p>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={2}>
          {t('WotTournament.TanksTierFilter', 'Tanks tier')}
        </Form.Label>

        <Col sm={10}>
          <ToggleButtonGroup
            type="checkbox"
            value={[...TankTiersValues]
              .map((tier) => (tankTiers[tier] === false ? undefined : tier))
              .filter((x) => x != null)}
            onChange={(value) =>
              props.onTankTiersChange(
                TankTiersValues.reduce(
                  (acc, cur) => ({
                    ...acc,
                    [cur as TankTiers]: value.includes(cur),
                  }),
                  {} as {
                    [key in TankTiers]: boolean;
                  },
                ) as IWotTournamentRulesDto['tankTiers'],
              )
            }
          >
            {[...TankTiersValues].map((tier) => (
              <ToggleButton
                key={tier}
                id={`tbg-btn-tier-${tier}`}
                value={tier}
                variant="outline-secondary"
              >
                <span>{toRoman(+tier)}</span>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>

          <div>
            {props.isTankTiersValid ? (
              <Form.Text className="text-muted">
                {t(
                  'WotTournament.SelectedTankTierNote',
                  'You have selected: {{tankTiers}} tanks tiers',
                  {
                    tankTiers: Object.entries(tankTiers).filter(
                      ([_, value]) => value === true,
                    ).length,
                  },
                )}
              </Form.Text>
            ) : (
              <Alert variant="danger" className="mt-2 pt-1 pb-1">
                {t(
                  'WotTournament.TanksTierFilterAlert',
                  'You have disabled all tank tiers. At least one tier must be selected.',
                )}
              </Alert>
            )}
          </div>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={2}>
          {t('WotTournament.TanksTypeFilter', 'Tanks type')}
        </Form.Label>

        <Col sm={10}>
          <ToggleButtonGroup
            type="checkbox"
            value={[...TankTypesValues]
              .map((type) => (tankTypes?.[type] === false ? undefined : type))
              .filter((x) => x != null)}
            onChange={(value) =>
              props.onTankTypesChange(
                TankTypesValues.reduce(
                  (acc, cur) => ({
                    ...acc,
                    [cur as TankTypes]: value.includes(cur),
                  }),
                  {} as {
                    [key in TankTypes]: boolean;
                  },
                ) as IWotTournamentRulesDto['tankTypes'],
              )
            }
          >
            {[...TankTypesValues].map((type) => (
              <ToggleButton
                key={type}
                id={`tbg-btn-type-${type}`}
                value={type}
                variant="outline-secondary"
              >
                <span>{type}</span>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>

          <p>
            {props.isTankTypesValid ? (
              <Form.Text className="text-muted">
                {t(
                  'WotTournament.SelectedTankTypesNote',
                  'You have selected: {{tankTypes}}',
                  {
                    tankTypes: Object.entries(tankTypes ?? {}).filter(
                      ([_, value]) => value === true,
                    ).length,
                  },
                )}
              </Form.Text>
            ) : (
              <Alert variant="danger" className="mt-2 pt-1 pb-1">
                {t(
                  'WotTournament.TanksTypeFilterAlert',
                  'You have disabled all tank types. At least one type must be selected.',
                )}
              </Alert>
            )}
          </p>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={2}>
          {t('WotTournament.OnlyRegularTanks', 'Only regular tanks')}
        </Form.Label>

        <Col sm={10}>
          <Stack direction="horizontal" gap={3}>
            <Form.Check
              type="switch"
              label={
                props.onlyRegularTanks
                  ? t('WotTournament.OnlyRegularTanksEnabled', 'Enabled')
                  : t('WotTournament.OnlyRegularTanksDisabled', 'Disabled')
              }
              checked={props.onlyRegularTanks === true}
              onChange={(event) =>
                props.onOnlyRegularTanksChange(event.target.checked)
              }
            />
          </Stack>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={2}>
          {t('WotTournament.TanksFilter', 'Tanks')}
        </Form.Label>

        <Col sm={10}>
          <Dropdown autoClose="outside">
            <Dropdown.Toggle
              variant="secondary"
              disabled={
                loading.find((x) => x.key === LoadingParts.WotTanks)?.value ===
                true
              }
            >
              {t('WotTournament.SelectTanksFilter', 'Select tanks')}
            </Dropdown.Toggle>

            <Dropdown.Menu className="wot-tanks-filter-dropdown-menu">
              {tanksList?.map((tank) => (
                <Dropdown.Item
                  key={tank.tank_id}
                  active={props.tanks.some((x) => x === tank.tank_id)}
                  onClick={() => {
                    let newTanks = [...props.tanks];

                    if (newTanks.some((x) => x === tank.tank_id)) {
                      newTanks = newTanks.filter((x) => x !== tank.tank_id);
                    } else {
                      newTanks = [...newTanks, tank.tank_id];
                    }

                    props.onTanksChange(newTanks);
                  }}
                >
                  {tank.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          <p>
            {loading.find((x) => x.key === LoadingParts.WotTanks)?.value ===
            true ? (
              <Form.Text className="text-muted">
                {t('WotTournament.WotTanksLoadingWarning', 'Loading tanks...')}
              </Form.Text>
            ) : (
              <Form.Text className="text-muted">
                {props.tanks.length === 0
                  ? t(
                      'WotTournament.AllTanksSelectedNote',
                      'All tanks selected.',
                    )
                  : t(
                      'WotTournament.SelectedTanksNote',
                      'You have selected: {{tanksCount}} tanks',
                      {
                        tanksCount: props.tanks.length,
                      },
                    )}
              </Form.Text>
            )}
          </p>
        </Col>
      </Form.Group>
    </>
  );
}
