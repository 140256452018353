import { Button, Form, Modal } from 'react-bootstrap';
import { t } from 'i18next';
import { IWotTournamentAccountBattleDto } from '../../../../../../services/api/wot-tournaments-api.service';

export function WotTournamentBattlesTabModalDelete(props: {
  battle:
    | (Pick<IWotTournamentAccountBattleDto, 'snapshotDate'> & {
        merged: boolean;
        manual: boolean;
      })
    | undefined;

  silently: boolean;
  onSilentlyChange: () => void;

  force: boolean;
  onForceChange: () => void;

  onCancelClick: () => void;
  onOkClick: () => void;
}) {
  return (
    <Modal show={props.battle != null} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>
          {t('WotTournamentBattlesTab.DeletingModalTitle', 'Deleting battle')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {t(
          'WotTournamentBattlesTab.DeletingModalBody',
          'You are about to delete a battle that was snapshotted {{date}}. Are you sure?',
          {
            date: props.battle?.snapshotDate,
          },
        )}
      </Modal.Body>
      <Modal.Body>
        <Form.Switch
          label={t(
            'WotTournamentBattlesTab.SilentDeleteBattleSwitch',
            'Delete silently',
          )}
          checked={
            props.battle?.merged === true ||
            props.battle?.manual === true ||
            props.silently
          }
          disabled={
            props.battle?.merged === true || props.battle?.manual === true
          }
          onChange={() => props.onSilentlyChange()}
        />

        <Form.Switch
          label={t(
            'WotTournamentBattlesTab.ForceDeleteBattleSwitch',
            'Force delete',
          )}
          checked={props.force}
          onChange={() => props.onForceChange()}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.onCancelClick()}>
          {t('WotTournamentBattlesTab.DeletingCancelButton', 'Cancel')}
        </Button>
        <Button variant="danger" onClick={() => props.onOkClick()}>
          {t('WotTournamentBattlesTab.DeletingDeleteButton', 'Delete')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
