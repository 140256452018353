import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../../redux/store';
import {
  IWotTournamentDto,
  WotTournamentsApiService,
} from '../../../../services/api/wot-tournaments-api.service';
import { LogService } from '../../../../services/log.service';
import {
  startLoading,
  LoadingParts,
  stopLoading,
} from '../../../main/loading/loadingSlice';

export interface WidgetState {
  wotTournament?: IWotTournamentDto | null;
}

const InitialState: WidgetState = {
  wotTournament: undefined,
};

export const widgetSlice = createSlice({
  name: 'widget',
  initialState: InitialState,
  reducers: {
    setWidgetWotTournament: (
      state,
      action: PayloadAction<IWotTournamentDto | undefined | null>,
    ) => {
      state.wotTournament = action.payload;
    },
  },
});

export const { setWidgetWotTournament } = widgetSlice.actions;

export const getWidgetWotTournamentAsync =
  (tournamentId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.WotTournaments));

      const svc = new WotTournamentsApiService();
      const response = await svc.getByIdPublic({ tournamentId });

      if (response.success === true) {
        dispatch(setWidgetWotTournament(response.data));
      }
    } catch (e) {
      LogService.error(
        `Error while loading WoT tournament item with id ${tournamentId}`,
      );
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.WotTournaments));
    }
  };

export const selectWidgetWotTournament = (state: RootState) =>
  state.widget.wotTournament;

export default widgetSlice.reducer;
