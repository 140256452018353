import './wot-blitz-tournament.scss';
import { Card, Col, Nav, Row } from 'react-bootstrap';
import { TournamentHeader } from '../item/components/tournament-header';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../redux/store';
import {
  getWotBlitzTournamentAsync,
  selectWotBlitzTournamentItem,
} from './wot-blitz-tournaments-slice';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { WotBlitzTournamentGeneralTab } from './components/wot-blitz-tournament-general-tab';
import { WotBlitzTournamentAccountsTab } from './components/wot-blitz-tournament-accounts-tab';
import { WotBlitzTournamentWidgetTab } from './components/wot-blitz-tournament-widget-tab';
import { WotBlitzTournamentBonusesTab } from './components/wot-blitz-tournament-bonuses-tab';
import { WotBlitzTournamentTeamsTab } from './components/wot-blitz-tournament-teams-tab';

export function WotBlitzTournament() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const tournament = useSelector(selectWotBlitzTournamentItem);

  const { id } = useParams<{ id: string }>();
  const [activeTab, setActiveTab] = useState<
    'general' | 'accounts' | 'widget' | 'bonuses' | 'teams'
  >('general');

  useEffect(() => {
    // TODO: why is loading twice?
    if (id != null && id !== '' && id !== 'new' && id !== tournament?.id) {
      dispatch(getWotBlitzTournamentAsync(id));
    }
  }, [dispatch, id, tournament?.id]);

  return (
    <>
      <Row>
        <Col>
          <TournamentHeader name={tournament?.name ?? 'New'} />
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <Card>
            <Card.Header>
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link
                    active={activeTab === 'general'}
                    onClick={() => setActiveTab('general')}
                  >
                    <h5>{t('WotBlitzTournament.GeneralTab', 'General')}</h5>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    active={activeTab === 'accounts'}
                    onClick={() => setActiveTab('accounts')}
                  >
                    <h5>{t('WotBlitzTournament.AccountsTab', 'Accounts')}</h5>
                  </Nav.Link>
                </Nav.Item>
                {tournament?.rules.mode === 'team' && (
                  <Nav.Item>
                    <Nav.Link
                      active={activeTab === 'teams'}
                      onClick={() => setActiveTab('teams')}
                    >
                      <h5>{t('WotBlitzTournament.TeamsTab', 'Teams')}</h5>
                    </Nav.Link>
                  </Nav.Item>
                )}
                <Nav.Item>
                  <Nav.Link
                    active={activeTab === 'widget'}
                    onClick={() => setActiveTab('widget')}
                  >
                    <h5>{t('WotBlitzTournament.WidgetTab', 'Widget')}</h5>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    active={activeTab === 'bonuses'}
                    onClick={() => setActiveTab('bonuses')}
                  >
                    <h5>
                      {t('WotBlitzTournament.BonusesTab', 'Bonuses/Penalties')}
                    </h5>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Header>

            <Card.Body>
              {activeTab === 'general' && <WotBlitzTournamentGeneralTab />}
              {activeTab === 'accounts' && <WotBlitzTournamentAccountsTab />}
              {activeTab === 'widget' && (
                <WotBlitzTournamentWidgetTab tournament={tournament} />
              )}
              {activeTab === 'bonuses' && <WotBlitzTournamentBonusesTab />}
              {activeTab === 'teams' && <WotBlitzTournamentTeamsTab />}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
