import { Button, Form, Modal } from 'react-bootstrap';
import { t } from 'i18next';
import { IWotTournamentAccountDto } from '../../../../../../services/api/wot-tournaments-api.service';

export function WotTournamentAccountsTabModalDelete(props: {
  account:
    | Pick<IWotTournamentAccountDto, 'name' | 'wotId' | 'server'>
    | undefined;

  force: boolean;
  onForceChange: () => void;

  onCancelClick: () => void;
  onOkClick: () => void;
}) {
  return (
    <Modal show={props.account != null} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>
          {t('WotTournamentAccountsTab.DeletingModalTitle', 'Deleting account')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {t(
          'WotTournamentAccountsTab.DeletingModalBody',
          'You are about to delete WoT account {{name}} ({{id}}, {{server}}) from the tournament. This action could not be undone and the account will lose all scores earned in the tournament. Are you sure?',
          {
            name: props.account?.name,
            id: props.account?.wotId,
            server: props.account?.server,
          },
        )}
      </Modal.Body>
      <Modal.Body>
        <Form.Switch
          label={t(
            'WotTournamentAccountsTab.ForceDeleteSwitch',
            'Force delete',
          )}
          checked={props.force}
          onChange={() => props.onForceChange()}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.onCancelClick()}>
          {t('WotTournamentAccountsTab.DeletingCancelButton', 'Cancel')}
        </Button>
        <Button variant="danger" onClick={() => props.onOkClick()}>
          {t('WotTournamentAccountsTab.DeletingDeleteButton', 'Delete')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
