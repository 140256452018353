import { useNavigate } from 'react-router-dom';
import { Row, Stack, Button, Col, Card, Form } from 'react-bootstrap';
import { ArrowLeftCircle } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { LoadingParts, selectLoadingState } from '../loading/loadingSlice';
import { useSelector } from 'react-redux';
import {
  getSettingsAsync,
  selectSettings,
  updateSettingsAsync,
} from './settings.slice';
import { useAppDispatch } from '../../../redux/store';

export function Settings() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const loading = useSelector(selectLoadingState);
  const settings = useSelector(selectSettings);

  const [dirty, setDirty] = useState(false);
  const [numberOfWorkers, setNumberOfWorkers] = useState<number | null>(1);

  const isNumberOfWorkersValid =
    numberOfWorkers != null && numberOfWorkers >= 1;

  useEffect(() => {
    dispatch(getSettingsAsync());
  }, [dispatch]);

  useEffect(() => {
    if (settings != null) {
      setNumberOfWorkers(settings.NumberOfWorkers ?? null);
    }
  }, [dispatch, settings]);

  const handleSave = () => {
    dispatch(
      updateSettingsAsync({
        NumberOfWorkers: numberOfWorkers ?? 1,
      }),
    );
    setDirty(false);
  };

  return (
    <>
      <Row>
        <Stack direction="horizontal">
          <Button onClick={() => navigate(`/`)} variant="link">
            <ArrowLeftCircle size={35} />
          </Button>

          <h1 className="ps-3 label-no-wrap">
            {t('Settings.Header', 'Settings')}
          </h1>
        </Stack>
      </Row>

      <Row className="mt-3">
        <Col className="narrow-page">
          <Card>
            <Card.Body>
              <Form className="p-2">
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={2}>
                    {t('Settings.NumberOfWorkers', 'Number of workers')}
                  </Form.Label>

                  <Col sm={10}>
                    <Form.Control
                      className="standard-input-width"
                      type="number"
                      min={1}
                      value={numberOfWorkers ?? ''}
                      onChange={(event) => {
                        setNumberOfWorkers(
                          event.target.value === ''
                            ? null
                            : +event.target.value,
                        );
                        setDirty(true);
                      }}
                      isInvalid={!isNumberOfWorkersValid}
                    />
                  </Col>
                </Form.Group>
              </Form>

              <Row>
                <Col sm={{ span: 1, offset: 11 }}>
                  <Button
                    className="mt-3"
                    variant="success"
                    disabled={
                      dirty === false ||
                      loading.find((x) => x.key === LoadingParts.SettingsLoad)
                        ?.value === true ||
                      loading.find((x) => x.key === LoadingParts.SettingsSave)
                        ?.value === true ||
                      !isNumberOfWorkersValid
                    }
                    onClick={() => handleSave()}
                  >
                    {t('Settings.SaveButton', 'Save')}
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
