import { useState } from 'react';
import { Button, Col, Form, Row, Table, Stack } from 'react-bootstrap';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import {
  addWotTournamentAccountBattleAsync,
  deleteWotTournamentAccountBattleAsync,
  restoreWotTournamentAccountBattleAsync,
  selectWotTanks,
  selectWotTournament,
} from '../redux/wot-tournament-slice';
import { Trash3, PlusSquare } from 'react-bootstrap-icons';
import { useAppDispatch } from '../../../../../redux/store';
import {
  LoadingParts,
  selectLoadingState,
} from '../../../loading/loadingSlice';
import {
  IWotTournamentAccountBattleDto,
  IWotTournamentAccountUpsertBattleDto,
} from '../../../../../services/api/wot-tournaments-api.service';
import { selectWotTournamentAccount } from '../redux/wot-tournament-account-slice';
import { WotAccountSelect } from '../components/wot-account-select';
import { IWotApiTank } from '../../../../../services/wot/wot-api-interface';
import { WotTournamentBattlesTabModalDelete } from './components/wot-tournament-battles-tab-modal-delete';

export function WotTournamentBattlesTab() {
  const dispatch = useAppDispatch();

  const loading = useSelector(selectLoadingState);
  const tournament = useSelector(selectWotTournament);
  const account = useSelector(selectWotTournamentAccount);
  const apiTanks = useSelector(selectWotTanks);

  const [sorting, setSorting] = useState<{
    column:
      | 'date'
      | 'tank'
      | 'scores'
      | 'battles'
      | 'wins'
      | 'frags'
      | 'damage'
      | 'xp'
      | 'state'
      | 'source';
    desc: boolean;
  }>({
    column: 'date',
    desc: true,
  });

  const [deletingBattleId, setDeletingBattleId] = useState<string>();
  const [silentDelete, setSilentDelete] = useState<boolean>(false);
  const [forceDelete, setForceDelete] = useState<boolean>(false);

  const [adding, setAdding] =
    useState<
      Partial<
        Pick<
          IWotTournamentAccountUpsertBattleDto,
          | 'snapshotDate'
          | 'tankId'
          | 'numberOfBattles'
          | 'wins'
          | 'frags'
          | 'damage'
          | 'xp'
        >
      >
    >();

  let tanks: Array<IWotApiTank> = [];
  if (tournament?.rules != null && apiTanks != null) {
    tanks = apiTanks
      ?.filter((tank) => {
        if (
          tournament.rules.tankTiers != null &&
          (tournament.rules.tankTiers as any)[
            tank.tier < 10 ? `0${tank.tier}` : `${tank.tier}`
          ] === false
        ) {
          return false;
        }

        if (
          tournament.rules.tankTypes != null &&
          tournament.rules.tankTypes[tank.type] === false
        ) {
          return false;
        }

        if (
          tournament.rules.onlyRegularTanks != null &&
          tournament.rules.onlyRegularTanks === true &&
          tank.is_premium === true
        ) {
          return false;
        }

        if (
          tournament.rules.tanks != null &&
          tournament.rules.tanks.length > 0 &&
          tournament.rules.tanks.includes(tank.tank_id) === false
        ) {
          return false;
        }

        return true;
      })
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  const deletedBattles = (account?.data.deletedBattles ?? []).map((b) => ({
    ...b,
    calculated: false,
    deleted: true,
    merged: false,
    manual: false,
  }));

  const mergedBattles = (account?.data.mergedBattles ?? [])
    .map((b) => ({
      ...b,
      calculated: false,
      deleted: false,
      merged: true,
      manual: false,
    }))
    .filter((b) => deletedBattles.every((d) => d.id !== b.id));

  const battles = (account?.data.battles ?? [])
    .map((b) => ({
      ...b,
      calculated: true,
      deleted: false,
      merged: false,
      manual: false,
    }))
    .filter((b) => deletedBattles.every((d) => d.id !== b.id));

  const manualBattles = (account?.data.manualBattles ?? [])
    .map((b) => ({
      ...b,
      calculated: false,
      deleted: false,
      merged: false,
      manual: true,
    }))
    .filter((b) => battles.every((d) => d.id !== b.id));

  const allBattles = [
    ...battles,
    ...deletedBattles,
    ...mergedBattles,
    ...manualBattles,
  ];

  const sortedBattles = allBattles.sort((a, b) => {
    switch (sorting.column) {
      case 'date':
        return sorting.desc
          ? b.snapshotDate.localeCompare(a.snapshotDate)
          : a.snapshotDate.localeCompare(b.snapshotDate);
      case 'state':
        const stateA =
          a.calculated === true
            ? 'calculated'
            : a.deleted === true
              ? 'deleted'
              : a.manual === true
                ? 'manual'
                : a.merged === true
                  ? 'merged'
                  : '';

        const stateB =
          b.calculated === true
            ? 'calculated'
            : b.deleted === true
              ? 'deleted'
              : b.manual === true
                ? 'manual'
                : b.merged === true
                  ? 'merged'
                  : '';

        return sorting.desc
          ? stateB.localeCompare(stateA)
          : stateA.localeCompare(stateB);
      case 'source':
        return sorting.desc
          ? (b.source ?? '').localeCompare(a.source ?? '')
          : (a.source ?? '').localeCompare(b.source ?? '');
      case 'tank':
        return sorting.desc
          ? b.tankName.localeCompare(a.tankName)
          : a.tankName.localeCompare(b.tankName);
      case 'scores':
        return sorting.desc
          ? (b.scores ?? 0) - (a.scores ?? 0)
          : (a.scores ?? 0) - (b.scores ?? 0);
      case 'battles':
        return sorting.desc
          ? (b.numberOfBattles ?? 0) - (a.numberOfBattles ?? 0)
          : (a.numberOfBattles ?? 0) - (b.numberOfBattles ?? 0);
      case 'wins':
        return sorting.desc
          ? (b.wins ?? 0) - (a.wins ?? 0)
          : (a.wins ?? 0) - (b.wins ?? 0);
      case 'frags':
        return sorting.desc
          ? (b.frags ?? 0) - (a.frags ?? 0)
          : (a.frags ?? 0) - (b.frags ?? 0);
      case 'damage':
        return sorting.desc
          ? (b.damage ?? 0) - (a.damage ?? 0)
          : (a.damage ?? 0) - (b.damage ?? 0);
      case 'xp':
        return sorting.desc
          ? (b.xp ?? 0) - (a.xp ?? 0)
          : (a.xp ?? 0) - (b.xp ?? 0);
      default:
        return sorting.desc
          ? b.snapshotDate.localeCompare(a.snapshotDate)
          : a.snapshotDate.localeCompare(b.snapshotDate);
    }
  });

  const restoreBattle = (battle: IWotTournamentAccountBattleDto) => {
    if (tournament != null && account != null && battle != null) {
      dispatch(
        restoreWotTournamentAccountBattleAsync(
          tournament.id,
          account.accountId,
          battle.id,
        ),
      );
    }
  };

  return (
    <>
      <Row>
        <Col>
          <div className="filter-container mb-2">
            <WotAccountSelect disabled={adding != null} />
            <Button
              variant="primary"
              className="mb-3"
              onClick={() => setAdding({})}
              disabled={tournament == null || account == null || adding != null}
            >
              {t('WotTournamentBattlesTab.ButtonAdd', 'Add battle')}
            </Button>
          </div>
        </Col>
      </Row>

      <WotTournamentBattlesTabModalDelete
        battle={allBattles.find((b) => b.id === deletingBattleId)}
        silently={silentDelete}
        onSilentlyChange={() => setSilentDelete(!silentDelete)}
        force={forceDelete}
        onForceChange={() => setForceDelete(!forceDelete)}
        onCancelClick={() => {
          setDeletingBattleId(undefined);
          setSilentDelete(false);
          setForceDelete(false);
        }}
        onOkClick={() => {
          if (
            tournament != null &&
            account != null &&
            deletingBattleId != null
          ) {
            const deletingBattle = allBattles.find(
              (b) => b.id === deletingBattleId,
            );

            if (deletingBattle != null) {
              dispatch(
                deleteWotTournamentAccountBattleAsync(
                  tournament.id,
                  account.accountId,
                  deletingBattleId,
                  deletingBattle.merged === true ||
                    deletingBattle.manual === true ||
                    silentDelete,
                  forceDelete,
                ),
              );
            }
          }

          setDeletingBattleId(undefined);
          setSilentDelete(false);
          setForceDelete(false);
        }}
      />

      <Table bordered hover className="mb-5" responsive>
        <thead>
          <tr>
            <th></th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({ column: 'date', desc: !sorting.desc })
                }
              >
                {t('WotTournamentBattlesTab.ColumnDate', 'Date')}
              </Button>
            </th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({ column: 'state', desc: !sorting.desc })
                }
              >
                {t('WotTournamentBattlesTab.ColumnState', 'State')}
              </Button>
            </th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({ column: 'source', desc: !sorting.desc })
                }
              >
                {t('WotTournamentBattlesTab.ColumnSource', 'Source')}
              </Button>
            </th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({ column: 'tank', desc: !sorting.desc })
                }
              >
                {t('WotTournamentBattlesTab.ColumnTank', 'Tank')}
              </Button>
            </th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({ column: 'battles', desc: !sorting.desc })
                }
              >
                {t('WotTournamentBattlesTab.ColumnBattles', 'Battles')}
              </Button>
            </th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({ column: 'scores', desc: !sorting.desc })
                }
              >
                {t('WotTournamentBattlesTab.ColumnScores', 'Scores')}
              </Button>
            </th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({ column: 'wins', desc: !sorting.desc })
                }
              >
                {t('WotTournamentBattlesTab.ColumnWins', 'Wins')}
              </Button>
            </th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({ column: 'frags', desc: !sorting.desc })
                }
              >
                {t('WotTournamentBattlesTab.ColumnFrags', 'Frags')}
              </Button>
            </th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({
                    column: 'damage',
                    desc: !sorting.desc,
                  })
                }
              >
                {t('WotTournamentBattlesTab.ColumnDamage', 'Damage')}
              </Button>
            </th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({
                    column: 'xp',
                    desc: !sorting.desc,
                  })
                }
              >
                {t('WotTournamentBattlesTab.ColumnXp', 'XP')}
              </Button>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {adding && (
            <tr key="new">
              <td></td>
              <td>
                <input
                  className={`date-time-input ${
                    adding.snapshotDate == null ? 'date-time-input-invalid' : ''
                  }`}
                  type="datetime-local"
                  onChange={(e) =>
                    setAdding({
                      ...adding,
                      snapshotDate: new Date(
                        `${e.target.value}:00.000+03:00`,
                      ).toISOString(),
                    })
                  }
                  value={
                    adding.snapshotDate == null
                      ? ''
                      : new Date(
                          new Date(adding.snapshotDate).getTime() -
                            -180 * 60000,
                        )
                          .toISOString()
                          .slice(0, 16)
                  }
                />
              </td>
              <td>manual</td>
              <td>manual</td>
              <td>
                <Form.Select
                  onChange={(event) =>
                    setAdding({
                      ...adding,
                      tankId: +event.target.value,
                    })
                  }
                  value={adding.tankId}
                  isInvalid={adding.tankId == null}
                >
                  {adding.tankId == null && (
                    <option value={undefined}>
                      {t('WotTournamentBattlesTab.SelectTank', 'Select tank')}
                    </option>
                  )}
                  {tanks.map((tank) => (
                    <option key={tank.tank_id} value={tank.tank_id}>
                      {tank.name}
                    </option>
                  ))}
                </Form.Select>
              </td>
              <td>
                <Form.Control
                  type="number"
                  min={0}
                  value={adding.numberOfBattles ?? ''}
                  onChange={(event) =>
                    setAdding({
                      ...adding,
                      numberOfBattles:
                        event.target.value === ''
                          ? undefined
                          : +event.target.value,
                    })
                  }
                  isInvalid={adding.numberOfBattles == null}
                />
              </td>
              <td>-</td>
              <td>
                <Form.Control
                  type="number"
                  min={0}
                  value={adding.wins ?? ''}
                  onChange={(event) =>
                    setAdding({
                      ...adding,
                      wins:
                        event.target.value === ''
                          ? undefined
                          : +event.target.value,
                    })
                  }
                  isInvalid={adding.wins == null}
                />
              </td>
              <td>
                <Form.Control
                  type="number"
                  min={0}
                  value={adding.frags ?? ''}
                  onChange={(event) =>
                    setAdding({
                      ...adding,
                      frags:
                        event.target.value === ''
                          ? undefined
                          : +event.target.value,
                    })
                  }
                  isInvalid={adding.frags == null}
                />
              </td>
              <td>
                <Form.Control
                  type="number"
                  min={0}
                  value={adding.damage ?? ''}
                  onChange={(event) =>
                    setAdding({
                      ...adding,
                      damage:
                        event.target.value === ''
                          ? undefined
                          : +event.target.value,
                    })
                  }
                  isInvalid={adding.damage == null}
                />
              </td>
              <td>
                <Form.Control
                  type="number"
                  min={0}
                  value={adding.xp ?? ''}
                  onChange={(event) =>
                    setAdding({
                      ...adding,
                      xp:
                        event.target.value === ''
                          ? undefined
                          : +event.target.value,
                    })
                  }
                  isInvalid={adding.xp == null}
                />
              </td>
              <td>
                <Stack direction="horizontal">
                  <Button
                    disabled={
                      tournament == null ||
                      account == null ||
                      adding == null ||
                      loading.find(
                        (x) =>
                          x.key ===
                          LoadingParts.WotTournamentAccountBattlesSave,
                      )?.value === true ||
                      adding.numberOfBattles == null ||
                      adding.wins == null ||
                      adding.frags == null ||
                      adding.damage == null ||
                      adding.xp == null ||
                      adding.tankId == null
                    }
                    onClick={() => {
                      dispatch(
                        addWotTournamentAccountBattleAsync(
                          tournament?.id ?? '',
                          account?.accountId ?? '',
                          adding as IWotTournamentAccountUpsertBattleDto,
                        ),
                      );
                      setAdding(undefined);
                    }}
                    variant="success"
                  >
                    {t('WotTournamentAccountsTab.ButtonSave', 'Save')}
                  </Button>

                  <Button
                    className="ms-3"
                    onClick={() => setAdding(undefined)}
                    variant="outline-danger"
                  >
                    {t('WotTournamentAccountsTab.ButtonCancel', 'Cancel')}
                  </Button>
                </Stack>
              </td>
            </tr>
          )}

          {sortedBattles.length > 0 &&
            sortedBattles.map((row, i) => (
              <tr
                key={row.id}
                className={
                  row.calculated === true
                    ? ''
                    : row.deleted
                      ? 'table-danger'
                      : row.manual
                        ? 'table-info'
                        : row.merged
                          ? 'table-warning'
                          : ''
                }
              >
                <td>{i + 1}</td>
                <td>{row.snapshotDate}</td>
                <td>
                  {row.calculated === true
                    ? 'calculated'
                    : row.deleted === true
                      ? 'deleted'
                      : row.manual === true
                        ? 'manual'
                        : row.merged === true
                          ? 'merged'
                          : ''}
                </td>
                <td>{row.source}</td>
                <td>
                  {row.tankName === ''
                    ? apiTanks?.find((t) => t.tank_id === row.tankId)?.name
                    : row.tankName}
                </td>
                <td>{row.numberOfBattles}</td>
                <td>{row.scores}</td>
                <td>{row.wins}</td>
                <td>{row.frags}</td>
                <td>{row.damage}</td>
                <td>{row.xp}</td>
                <td>
                  {row.deleted && (
                    <Button
                      variant="link"
                      disabled={
                        loading.find(
                          (x) =>
                            x.key ===
                            LoadingParts.WotTournamentAccountBattlesSave,
                        )?.value === true
                      }
                      onClick={() => restoreBattle(row)}
                    >
                      <PlusSquare size={20} color="green" />
                    </Button>
                  )}

                  {!row.deleted && (
                    <Button
                      variant="link"
                      disabled={
                        loading.find(
                          (x) =>
                            x.key ===
                            LoadingParts.WotTournamentAccountBattlesSave,
                        )?.value === true
                      }
                      onClick={() => setDeletingBattleId(row.id)}
                    >
                      <Trash3 size={20} color="red" />
                    </Button>
                  )}
                </td>
              </tr>
            ))}

          {sortedBattles.length === 0 && (
            <tr>
              <td colSpan={14} className="text-center">
                {t(
                  'WotTournamentBattlesTab.NoTableRows',
                  'There are no records matching the selected filters.',
                )}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}
