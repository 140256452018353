import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../../redux/store';
import { Dropdown, Form } from 'react-bootstrap';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  selectWotTournamentAccount,
  getWotTournamentAccountAsync,
} from '../redux/wot-tournament-account-slice';
import { selectWotTournament } from '../redux/wot-tournament-slice';

export function WotAccountSelect(props: { disabled: boolean }) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();

  const tournament = useSelector(selectWotTournament);
  const account = useSelector(selectWotTournamentAccount);

  const [accountFilter, setAccountFilter] = useState<string>('');

  const filteredAccounts = (tournament?.accounts ?? [])
    .filter(
      (row) =>
        row.name.toLowerCase().includes(accountFilter.toLowerCase()) ||
        `${row.wotId}`.includes(accountFilter),
    )
    .sort((a, b) => a.name.localeCompare(b.name))
    .slice(0, 10);

  useEffect(() => {
    const accountId = searchParams.get('accountId');
    if (
      tournament?.id != null &&
      accountId != null &&
      accountId !== '' &&
      accountId !== account?.accountId
    ) {
      dispatch(getWotTournamentAccountAsync(tournament.id, accountId, false));
    }
  }, [account?.accountId, dispatch, searchParams, tournament?.id]);

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="outline-secondary"
        disabled={props.disabled ?? false}
      >
        {account != null
          ? `${account.data.name} (${account.data.server} ${account.data.wotId})`
          : t('WotAccountSelect.SelectAccount', 'Select account')}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <div className="pe-1 ps-1">
          <Form.Control
            autoFocus
            placeholder={t(
              'WotAccountSelect.FilterPlaceholder',
              'Type to filter...',
            )}
            onChange={(e) => setAccountFilter(e.target.value)}
            value={accountFilter}
          />
        </div>
        <Dropdown.Divider />
        {filteredAccounts.map((row) => (
          <Dropdown.Item
            key={row.accountId}
            onClick={() => {
              setSearchParams({ accountId: row.accountId });
              window.location.hash = location.hash;
            }}
          >
            {row.name}
          </Dropdown.Item>
        ))}

        {filteredAccounts.length === 0 && (
          <Dropdown.Item disabled>
            {t('WotAccountSelect.NoAccounts', 'No accounts')}
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
