import { useEffect, useState } from 'react';
import { Accordion, Button, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  createWotTournamentAsync,
  selectWotTournament,
  updateWotTournamentAsync,
} from '../redux/wot-tournament-slice';
import {
  LoadingParts,
  selectLoadingState,
} from '../../../loading/loadingSlice';
import { useAppDispatch } from '../../../../../redux/store';
import { WotTournamentStatus } from '../../../../../services/api/interfaces/common-api-interfase';
import {
  IWotTournamentDto,
  IWotTournamentRulesDto,
} from '../../../../../services/api/wot-tournaments-api.service';
import { WotTournamentRules } from './components/wot-tournament-rules';
import { WotTournamentMultipliers } from './components/wot-tournament-multipliers';
import { WotTournamentFilters } from './components/wot-tournament-filters';

const WotTournamentTeamSize = 2;

export function WotTournamentGeneralTab() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const tournament = useSelector(selectWotTournament);
  const loading = useSelector(selectLoadingState);

  // general
  const [name, setName] = useState<IWotTournamentDto['name']>('');
  const [status, setStatus] = useState<IWotTournamentDto['status']>('Private');
  const [startAt, setStartAt] = useState<IWotTournamentDto['startAt']>(
    new Date().toISOString(),
  );
  const [endAt, setEndAt] = useState<IWotTournamentDto['endAt']>(
    new Date(Date.now() + 1000 * 60 * 60 * 24 * 7).toISOString(),
  );

  // rules tab
  const [mode, setMode] =
    useState<IWotTournamentRulesDto['mode']>('individual');
  const [mvpBattleMode, setMvpBattleMode] =
    useState<IWotTournamentRulesDto['mvpBattleMode']>('scores');
  const [battlesRemovalsLimit, setBattlesRemovalsLimit] = useState<
    IWotTournamentRulesDto['battlesRemovalsLimit'] | null
  >(0);
  const [accountsRemovalsLimit, setAccountsRemovalsLimit] = useState<
    IWotTournamentRulesDto['accountsRemovalsLimit'] | null
  >(0);

  // multipliers tab
  const [battleMultiplier, setBattleMultiplier] = useState<
    IWotTournamentRulesDto['battleMultiplier'] | null
  >(0);
  const [winMultiplier, setWinMultiplier] = useState<
    IWotTournamentRulesDto['winMultiplier'] | null
  >(0);
  const [fragMultiplier, setFragMultiplier] = useState<
    IWotTournamentRulesDto['fragMultiplier'] | null
  >(0);
  const [damageMultiplier, setDamageMultiplier] = useState<
    IWotTournamentRulesDto['damageMultiplier'] | null
  >(0);
  const [xpMultiplier, setXpMultiplier] = useState<
    IWotTournamentRulesDto['xpMultiplier'] | null
  >(0);

  // filters
  const [battlesLimitMode, setBattlesLimitMode] =
    useState<IWotTournamentRulesDto['battlesLimitMode']>('unlimited');
  const [battlesLimit, setBattlesLimit] =
    useState<IWotTournamentRulesDto['battlesLimit']>(null);
  const [battlesOfLimit, setBattlesOfLimit] =
    useState<IWotTournamentRulesDto['battlesOfLimit']>(null);
  const [acceptMergedBattles, setAcceptMergedBattles] =
    useState<IWotTournamentRulesDto['acceptMergedBattles']>(false);
  const [battleHoursUtc, setBattleHoursUtc] = useState<
    IWotTournamentRulesDto['battleHoursUtc']
  >({
    '00': true,
    '01': true,
    '02': true,
    '03': true,
    '04': true,
    '05': true,
    '06': true,
    '07': true,
    '08': true,
    '09': true,
    '10': true,
    '11': true,
    '12': true,
    '13': true,
    '14': true,
    '15': true,
    '16': true,
    '17': true,
    '18': true,
    '19': true,
    '20': true,
    '21': true,
    '22': true,
    '23': true,
  });
  const [tankTiers, setTankTiers] = useState<
    IWotTournamentRulesDto['tankTiers']
  >({
    '01': true,
    '02': true,
    '03': true,
    '04': true,
    '05': true,
    '06': true,
    '07': true,
    '08': true,
    '09': true,
    '10': true,
  });
  const [tankTypes, setTankTypes] = useState<
    IWotTournamentRulesDto['tankTypes']
  >({
    'AT-SPG': true,
    SPG: true,
    heavyTank: true,
    lightTank: true,
    mediumTank: true,
  });
  const [onlyRegularTanks, setOnlyRegularTanks] =
    useState<IWotTournamentRulesDto['onlyRegularTanks']>(true);
  const [tanks, setTanks] = useState<IWotTournamentRulesDto['tanks']>([]);

  // general
  const isNameValid = name != null && name.length > 0;
  const isStartAtValid =
    startAt != null && startAt.length > 0 && startAt <= (endAt ?? '');
  const isEndAtValid =
    endAt != null && endAt.length > 0 && endAt >= (startAt ?? '');

  // rules
  const isBattlesLimitValid =
    battlesLimitMode === 'unlimited' ||
    (battlesLimit != null && battlesLimit > 0);
  const isBattlesOfLimitValid =
    battlesLimitMode !== 'best' ||
    battlesOfLimit == null ||
    (battlesOfLimit > 0 &&
      (battlesLimit == null || battlesLimit <= battlesOfLimit));
  const isBattlesRemovalsLimitValid =
    battlesRemovalsLimit == null || battlesRemovalsLimit >= 0;
  const isAccountsRemovalsLimitValid =
    accountsRemovalsLimit == null || accountsRemovalsLimit >= 0;

  // multipliers
  const isBattleMultiplierValid =
    battleMultiplier == null || battleMultiplier >= 0;
  const isWinMultiplierValid = winMultiplier == null || winMultiplier >= 0;
  const isFragMultiplierValid = fragMultiplier == null || fragMultiplier >= 0;
  const isDamageMultiplierValid =
    damageMultiplier == null || damageMultiplier >= 0;
  const isXpMultiplierValid = xpMultiplier == null || xpMultiplier >= 0;

  // filters
  const isTankTiersValid = Object.values(tankTiers).some((x) => x === true);
  const isTankTypesValid = Object.values(tankTypes).some((x) => x === true);
  const isBattleHoursUtcValid = Object.values(battleHoursUtc).some(
    (x) => x === true,
  );

  useEffect(() => {
    if (tournament == null) {
      return;
    }

    setName(tournament.name);
    setStatus(tournament.status);
    setStartAt(tournament.startAt);
    setEndAt(tournament.endAt);
    setBattlesLimit(tournament.rules.battlesLimit);
    setBattlesOfLimit(tournament.rules.battlesOfLimit);
    setBattlesLimitMode(tournament.rules.battlesLimitMode);
    setBattleMultiplier(tournament.rules.battleMultiplier);
    setWinMultiplier(tournament.rules.winMultiplier);
    setFragMultiplier(tournament.rules.fragMultiplier);
    setDamageMultiplier(tournament.rules.damageMultiplier);
    setXpMultiplier(tournament.rules.xpMultiplier);
    setTankTiers(tournament.rules.tankTiers);
    setTankTypes(tournament.rules.tankTypes);
    setMode(tournament.rules.mode);
    setMvpBattleMode(tournament.rules.mvpBattleMode);
    setOnlyRegularTanks(tournament.rules.onlyRegularTanks);
    setTanks(tournament.rules.tanks);
    setBattlesRemovalsLimit(tournament.rules.battlesRemovalsLimit);
    setAccountsRemovalsLimit(tournament.rules.accountsRemovalsLimit);
    setBattleHoursUtc(tournament.rules.battleHoursUtc);
    setAcceptMergedBattles(tournament.rules.acceptMergedBattles);
  }, [tournament]);

  const handleSave = () => {
    const updatedRules: IWotTournamentRulesDto = {
      mode,
      mvpBattleMode,
      teamSize: WotTournamentTeamSize,
      battlesRemovalsLimit: battlesRemovalsLimit ?? 0,
      accountsRemovalsLimit: accountsRemovalsLimit ?? 0,

      battleMultiplier: battleMultiplier ?? 0,
      winMultiplier: winMultiplier ?? 0,
      fragMultiplier: fragMultiplier ?? 0,
      damageMultiplier: damageMultiplier ?? 0,
      xpMultiplier: xpMultiplier ?? 0,

      battlesLimitMode,
      battlesLimit:
        battlesLimitMode !== 'unlimited' ? (battlesLimit ?? null) : null,
      battlesOfLimit:
        battlesLimitMode === 'best' ? (battlesOfLimit ?? null) : null,

      tankTiers,
      tankTypes,
      tanks,
      onlyRegularTanks,
      battleHoursUtc,
      acceptMergedBattles,
    };

    if (tournament?.id == null) {
      dispatch(
        createWotTournamentAsync(
          name,
          new Date(startAt as string),
          new Date(endAt as string),
          status,
          updatedRules,
        ),
      );
    } else {
      dispatch(
        updateWotTournamentAsync(
          tournament.id,
          name,
          new Date(startAt as string),
          new Date(endAt as string),
          status,
          updatedRules,
        ),
      );
    }
  };

  return (
    <>
      <Form className="p-2">
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2}>
            {t('WotTournament.Name', 'Name')}
          </Form.Label>

          <Col sm={10}>
            <Form.Control
              className="standard-input-width"
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
              isInvalid={!isNameValid}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2}>
            {t('WotTournament.Status', 'Status')}
          </Form.Label>

          <Col sm={10}>
            <Form.Select
              className="standard-input-width"
              onChange={(event) =>
                setStatus(event.target.value as WotTournamentStatus)
              }
              value={status}
            >
              <option value="Public">Public</option>
              <option value="Private">Private</option>
            </Form.Select>
            <Form.Text className="text-muted">
              {t(
                'WotTournament.StatusNote',
                'Private tournaments are not visible to other users.',
              )}
            </Form.Text>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2}>
            {t('WotTournament.StartDate', 'Start date')}
          </Form.Label>

          <Col sm={10}>
            <div>
              <input
                className="standard-input-width date-time-input ps-3 pe-3"
                type="datetime-local"
                onChange={(e) => {
                  setStartAt(
                    new Date(`${e.target.value}:00.000+03:00`).toISOString(),
                  );
                }}
                value={
                  startAt == null
                    ? undefined
                    : new Date(new Date(startAt).getTime() - -180 * 60000)
                        .toISOString()
                        .slice(0, 16)
                }
              />
            </div>

            <Form.Text className="text-muted">
              {t('WotTournament.DateNote', 'The date is in +03 timezone.')}
            </Form.Text>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2}>
            {t('WotTournament.EndDate', 'End date')}
          </Form.Label>

          <Col sm={10}>
            <div>
              <input
                className="standard-input-width date-time-input ps-3 pe-3"
                type="datetime-local"
                onChange={(e) => {
                  setEndAt(
                    new Date(`${e.target.value}:00.000+03:00`).toISOString(),
                  );
                }}
                value={
                  endAt == null
                    ? undefined
                    : new Date(new Date(endAt).getTime() - -180 * 60000)
                        .toISOString()
                        .slice(0, 16)
                }
              />
            </div>

            <Form.Text className="text-muted">
              {t('WotTournament.DateNote', 'The date is in +03 timezone.')}
            </Form.Text>
          </Col>
        </Form.Group>

        <Accordion alwaysOpen>
          <Accordion.Item eventKey="rules">
            <Accordion.Header>
              {t('WotTournament.RulesHeader', 'Rules')}
            </Accordion.Header>
            <Accordion.Body>
              <WotTournamentRules
                mode={mode}
                onModeChange={(m) => setMode(m)}
                mvpBattleMode={mvpBattleMode}
                onMvpBattleModeChange={(m) => setMvpBattleMode(m)}
                battlesRemovalsLimit={battlesRemovalsLimit}
                onBattlesRemovalsLimitChange={(b) => setBattlesRemovalsLimit(b)}
                isBattlesRemovalsLimitValid={isBattlesRemovalsLimitValid}
                accountsRemovalsLimit={accountsRemovalsLimit}
                onAccountsRemovalsLimitChange={(a) =>
                  setAccountsRemovalsLimit(a)
                }
                isAccountsRemovalsLimitValid={isAccountsRemovalsLimitValid}
              />
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="multipliers">
            <Accordion.Header>
              {t('WotTournament.MultipliersHeader', 'Multipliers')}
            </Accordion.Header>
            <Accordion.Body>
              <WotTournamentMultipliers
                battleMultiplier={battleMultiplier}
                onBattleMultiplierChange={(m) => setBattleMultiplier(m)}
                isBattleMultiplierValid={isBattleMultiplierValid}
                winMultiplier={winMultiplier}
                onWinMultiplierChange={(m) => setWinMultiplier(m)}
                isWinMultiplierValid={isWinMultiplierValid}
                fragMultiplier={fragMultiplier}
                onFragMultiplierChange={(m) => setFragMultiplier(m)}
                isFragMultiplierValid={isFragMultiplierValid}
                damageMultiplier={damageMultiplier}
                onDamageMultiplierChange={(m) => setDamageMultiplier(m)}
                isDamageMultiplierValid={isDamageMultiplierValid}
                xpMultiplier={xpMultiplier}
                onXpMultiplierChange={(m) => setXpMultiplier(m)}
                isXpMultiplierValid={isXpMultiplierValid}
              />
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="filters">
            <Accordion.Header>
              {t('WotTournament.FiltersHeader', 'Filters')}
            </Accordion.Header>
            <Accordion.Body>
              <WotTournamentFilters
                battlesLimitMode={battlesLimitMode}
                onBattlesLimitModeChange={(m) => {
                  setBattlesLimitMode(m);

                  if (m === 'best') {
                    setAcceptMergedBattles(false);
                  } else if (m === 'first') {
                    setBattlesOfLimit(null);
                  } else {
                    setBattlesLimit(null);
                    setBattlesOfLimit(null);
                  }
                }}
                battlesLimit={battlesLimit}
                onBattlesLimitChange={(b) => setBattlesLimit(b)}
                isBattlesLimitValid={isBattlesLimitValid}
                battlesOfLimit={battlesOfLimit}
                onBattlesOfLimitChange={(b) => setBattlesOfLimit(b)}
                isBattlesOfLimitValid={isBattlesOfLimitValid}
                acceptMergedBattles={acceptMergedBattles}
                onAcceptMergedBattlesChange={(a) => setAcceptMergedBattles(a)}
                battleHoursUtc={battleHoursUtc}
                onBattleHoursUtcChange={(b) => setBattleHoursUtc(b)}
                isBattleHoursUtcValid={isBattleHoursUtcValid}
                tankTiers={tankTiers}
                onTankTiersChange={(t) => {
                  setTankTiers(t);
                  setTanks([]);
                }}
                isTankTiersValid={isTankTiersValid}
                tankTypes={tankTypes}
                onTankTypesChange={(t) => {
                  setTankTypes(t);
                  setTanks([]);
                }}
                isTankTypesValid={isTankTypesValid}
                onlyRegularTanks={onlyRegularTanks}
                onOnlyRegularTanksChange={(o) => {
                  setOnlyRegularTanks(o);
                  setTanks([]);
                }}
                tanks={tanks}
                onTanksChange={(t) => setTanks(t)}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Form>

      <Row>
        <Col sm={{ span: 1, offset: 11 }}>
          <Button
            className="mt-3"
            variant="success"
            disabled={
              loading.find((x) => x.key === LoadingParts.WotTournaments)
                ?.value === true ||
              loading.find((x) => x.key === LoadingParts.WotTournamentsSave)
                ?.value === true ||
              !isNameValid ||
              !isStartAtValid ||
              !isEndAtValid ||
              !isBattleMultiplierValid ||
              !isWinMultiplierValid ||
              !isFragMultiplierValid ||
              !isDamageMultiplierValid ||
              !isXpMultiplierValid ||
              !isTankTiersValid ||
              !isTankTypesValid ||
              !isBattlesLimitValid ||
              !isBattlesOfLimitValid ||
              !isBattlesRemovalsLimitValid ||
              !isAccountsRemovalsLimitValid ||
              !isBattleHoursUtcValid
            }
            onClick={() => {
              handleSave();
            }}
          >
            {t('WotTournament.SaveButton', 'Save')}
          </Button>
        </Col>
      </Row>
    </>
  );
}
