import { Typeahead } from 'react-bootstrap-typeahead';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useAppDispatch } from '../../../../../../redux/store';
import { WotServer } from '../../../../../../services/api/interfaces/common-api-interfase';
import {
  LoadingParts,
  startLoading,
  stopLoading,
} from '../../../../loading/loadingSlice';
import { LogService } from '../../../../../../services/log.service';
import { IWotApiAccountSearch } from '../../../../../../services/wot/wot-api-interface';
import { WotApiService } from '../../../../../../services/wot/wot-api-service';

export function WotAccountSearch(props: {
  server: WotServer;
  onSelect: (selected: IWotApiAccountSearch) => void;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [results, setResults] = useState<IWotApiAccountSearch[]>([]);
  const [selected, setSelected] = useState<IWotApiAccountSearch>();

  const onSearch = async (query: string) => {
    setResults([]);
    setSelected(undefined);

    if (query.length > 2) {
      try {
        dispatch(startLoading(LoadingParts.WotSearchingAccount));
        const wotApiSvc = new WotApiService();

        const apiResults = await wotApiSvc.searchAccount(query, props.server);

        setResults(apiResults.data ?? []);
      } catch (e) {
        LogService.error(`Error while searching account`);
        LogService.error(e as Error);
      } finally {
        dispatch(stopLoading(LoadingParts.WotSearchingAccount));
      }
    }
  };

  return (
    <Typeahead
      id="search-wot-account"
      emptyLabel={t('WotAccountSearch.EmptyLabel', 'Account not found')}
      placeholder={
        selected?.nickname ??
        t('WotAccountSearch.Placeholder', 'Enter account name')
      }
      paginationText={t('WotAccountSearch.PaginationText', 'Show more')}
      maxResults={7}
      labelKey={(option) =>
        `${(option as IWotApiAccountSearch).nickname} (${
          (option as IWotApiAccountSearch).account_id
        })`
      }
      options={results}
      onChange={(selected) => {
        setSelected(selected[0] as IWotApiAccountSearch);
        props.onSelect(selected[0] as IWotApiAccountSearch);
      }}
      onInputChange={(text) => void onSearch(text)}
      selected={[]}
      defaultInputValue=""
    />
  );
}
