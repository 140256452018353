import { Button, Modal } from 'react-bootstrap';
import { t } from 'i18next';
import { IWotTournamentAccountDto } from '../../../../../../services/api/wot-tournaments-api.service';

export function WotTournamentBonusesTabModalDelete(props: {
  bonus:
    | Pick<IWotTournamentAccountDto, 'wotId' | 'name' | 'server'>
    | undefined;

  onCancelClick: () => void;
  onOkClick: () => void;
}) {
  return (
    <Modal show={props.bonus != null} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>
          {t(
            'WotTournamentBonusesTab.DeletingModalTitle',
            'Deleting a bonus or penalty',
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {t(
          'WotTournamentBonusesTab.DeletingModalBody',
          'You are about to delete a bonus or penalty for account {{name}} ({{id}}, {{server}}) from the tournament. This action could not be undone. Are you sure?',
          {
            name: props.bonus?.name,
            id: props.bonus?.wotId,
            server: props.bonus?.server,
          },
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.onCancelClick()}>
          {t('WotTournamentBonusesTab.DeletingCancelButton', 'Cancel')}
        </Button>
        <Button variant="danger" onClick={() => props.onOkClick()}>
          {t('WotTournamentBonusesTab.DeletingDeleteButton', 'Delete')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
