import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../redux/store';
import { AuthApiService } from '../../../services/api/auth-api.service';
import {
  UserDto,
  UserApiService,
} from '../../../services/api/user-api.service';
import { LogService } from '../../../services/log.service';
import {
  LoadingParts,
  startLoading,
  stopLoading,
} from '../../main/loading/loadingSlice';
import { showToast } from '../toast/toastSlice';

export interface UserState {
  me?: UserDto;
}

const InitialState: UserState = {
  me: undefined,
};

export const userSlice = createSlice({
  name: 'user',
  initialState: InitialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserDto | undefined>) => {
      state.me = action.payload;
    },
  },
});

export const { setUser } = userSlice.actions;

export const refreshUserAsync = (): AppThunk => async (dispatch) => {
  try {
    dispatch(startLoading(LoadingParts.User));

    const authSvc = new AuthApiService();
    const response = await authSvc.refresh();

    if (response.success !== true) {
      dispatch(
        showToast({
          title: 'Error',
          message: response.message ?? 'Unknown error occurred...',
          variant: 'danger',
        }),
      );
    } else {
      if (response.data != null) {
        localStorage.setItem('jwt', response.data);
        LogService.debug('JWT token refreshed');
        dispatch(getUserMeAsync());
      }
    }
  } catch (e) {
    LogService.error(`Error while refreshing user`);
    LogService.error(e as Error);
  } finally {
    dispatch(stopLoading(LoadingParts.User));
  }
};

export const getUserMeAsync = (): AppThunk => async (dispatch) => {
  try {
    dispatch(startLoading(LoadingParts.User));

    const svc = new UserApiService();
    const response = await svc.getMe();

    dispatch(setUser(response?.data ?? undefined));
  } catch (e) {
    LogService.error(`Error while loading user`);
    LogService.error(e as Error);
  } finally {
    dispatch(stopLoading(LoadingParts.User));
  }
};

export const selectUser = (state: RootState) => state.user.me;

export default userSlice.reducer;
