import { Button, Col, Form } from 'react-bootstrap';
import { Game } from '../../tournaments.interface';
import { useTranslation } from 'react-i18next';

export function TournamentsListHeader(props: {
  onlyPublic: boolean;
  onOnlyPublicChange: () => void;
  game: Game;
  onGameChange: (game: Game) => void;
  onNewTournamentClick: () => void;
}) {
  const { t } = useTranslation();

  return (
    <>
      <Col>
        <h1 className="ps-3">{t('Tournaments.Header', 'Tournaments')}</h1>
      </Col>

      <Col>
        <div className="filter-container">
          <div className="pt-2">
            <Form.Check
              type="checkbox"
              label={t('Tournaments.OnlyPublic', 'Only public')}
              checked={props.onlyPublic}
              onChange={() => props.onOnlyPublicChange()}
            />
          </div>

          <div>
            <Form.Select
              onChange={(event) =>
                props.onGameChange(event.target.value as Game)
              }
              value={props.game}
            >
              <option value="wot">WoT</option>
              <option value="wot_blitz">WoT Blitz</option>
            </Form.Select>
          </div>

          <div>
            <Button
              variant="primary"
              onClick={() => props.onNewTournamentClick()}
            >
              {t('Tournaments.AddTournament', 'Add tournament')}
            </Button>
          </div>
        </div>
      </Col>
    </>
  );
}
