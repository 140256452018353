import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../../../redux/store';
import {
  LoadingParts,
  startLoading,
  stopLoading,
} from '../../../loading/loadingSlice';
import { showToast } from '../../../toast/toastSlice';
import { LogService } from '../../../../../services/log.service';
import {
  IWotTournamentAccountFullDto,
  IWotTournamentWithAccountsDto,
  WotTournamentsApiService,
} from '../../../../../services/api/wot-tournaments-api.service';
import { WotServer } from '../../../../../services/api/interfaces/common-api-interfase';

export interface WotTournamentAccountState {
  item?: {
    data: IWotTournamentAccountFullDto;
    tournamentId: string;
    accountId: string;
  };
  publicItem?: {
    data: IWotTournamentWithAccountsDto;
  };
  firstPlacePublicItem?: {
    data: IWotTournamentWithAccountsDto;
  };
}

const InitialState: WotTournamentAccountState = {
  item: undefined,
  publicItem: undefined,
};

export const wotTournamentAccountSlice = createSlice({
  name: 'wotTournamentAccount',
  initialState: InitialState,
  reducers: {
    resetWotTournamentAccount: (state) => {
      state.item = undefined;
    },
    setWotTournamentAccount: (
      state,
      action: PayloadAction<{
        tournamentId: string;
        accountId: string;
        data: IWotTournamentAccountFullDto;
      }>,
    ) => {
      state.item = {
        data: action.payload.data,
        accountId: action.payload.accountId,
        tournamentId: action.payload.tournamentId,
      };
    },
    setWotTournamentAccountPublic: (
      state,
      action: PayloadAction<{
        data: IWotTournamentWithAccountsDto;
      }>,
    ) => {
      state.publicItem = {
        data: action.payload.data,
      };
    },
    setWotTournamentFirstPlaceAccountPublic: (
      state,
      action: PayloadAction<{
        data: IWotTournamentWithAccountsDto;
      }>,
    ) => {
      state.firstPlacePublicItem = {
        data: action.payload.data,
      };
    },
  },
});

export const {
  setWotTournamentAccount,
  setWotTournamentAccountPublic,
  resetWotTournamentAccount,
  setWotTournamentFirstPlaceAccountPublic,
} = wotTournamentAccountSlice.actions;

export const getWotTournamentAccountAsync =
  (tournamentId: string, accountId: string, cache: boolean): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.WotTournamentAccount));

      const svc = new WotTournamentsApiService();
      const response = await svc.getAccount(
        { tournamentId, accountId },
        { cache },
      );

      if (response.success !== true || response.data == null) {
        dispatch(
          showToast({
            title: 'Error',
            message: response.message ?? 'Unknown error occurred...',
            variant: 'danger',
          }),
        );
      } else {
        dispatch(
          setWotTournamentAccount({
            tournamentId,
            accountId,
            data: response.data,
          }),
        );
      }
    } catch (e) {
      LogService.error(`Error while loading WoT tournament account`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.WotTournamentAccount));
    }
  };

export const getWotTournamentAccountPublicAsync =
  (tournamentId: string, wotId: number, server?: WotServer): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.WotTournamentAccount));

      const svc = new WotTournamentsApiService();
      const response = await svc.getAccountPublic(
        { tournamentId },
        { wotId, server },
      );

      if (response.success !== true || response.data == null) {
        dispatch(
          showToast({
            title: 'Error',
            message: response.message ?? 'Unknown error occurred...',
            variant: 'danger',
          }),
        );
      } else {
        dispatch(
          setWotTournamentAccountPublic({
            data: response.data,
          }),
        );
      }
    } catch (e) {
      LogService.error(`Error while loading WoT tournament account public`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.WotTournamentAccount));
    }
  };

export const getWotTournamentFirstPlaceAccountPublicAsync =
  (tournamentId: string, wotId: number, server: WotServer): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.WotTournamentAccount));

      const svc = new WotTournamentsApiService();
      const response = await svc.getAccountPublic(
        { tournamentId },
        { wotId, server },
      );

      if (response.success !== true || response.data == null) {
        dispatch(
          showToast({
            title: 'Error',
            message: response.message ?? 'Unknown error occurred...',
            variant: 'danger',
          }),
        );
      } else {
        dispatch(
          setWotTournamentFirstPlaceAccountPublic({
            data: response.data,
          }),
        );
      }
    } catch (e) {
      LogService.error(
        `Error while loading WoT tournament first place account public`,
      );
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.WotTournamentAccount));
    }
  };

export const selectWotTournamentAccount = (state: RootState) =>
  state.wotTournamentAccount.item;

export const selectWotTournamentAccountPublic = (state: RootState) =>
  state.wotTournamentAccount.publicItem?.data;

export const selectWotTournamentFirstPlaceAccountPublic = (state: RootState) =>
  state.wotTournamentAccount.firstPlacePublicItem?.data;

export default wotTournamentAccountSlice.reducer;
