import { useState } from 'react';
import { Alert, Button, Col, Form, Row, Stack, Table } from 'react-bootstrap';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { Trash3 } from 'react-bootstrap-icons';
import { useAppDispatch } from '../../../../../redux/store';
import {
  LoadingParts,
  selectLoadingState,
} from '../../../loading/loadingSlice';
import DatePicker from 'react-datepicker';
import { IWotTournamentAccountBonusDto } from '../../../../../services/api/wot-tournaments-api.service';
import { WotAccountSelect } from '../components/wot-account-select';
import { selectWotTournamentAccount } from '../redux/wot-tournament-account-slice';
import {
  selectWotTournament,
  deleteWotTournamentAccountBonusAsync,
  addWotTournamentAccountBonusAsync,
} from '../redux/wot-tournament-slice';
import { WotTournamentBonusesTabModalDelete } from './components/wot-tournament-bonuses-tab-modal-delete';

export function WotTournamentBonusesTab() {
  const dispatch = useAppDispatch();

  const tournament = useSelector(selectWotTournament);
  const account = useSelector(selectWotTournamentAccount);
  const loading = useSelector(selectLoadingState);

  const [sorting, setSorting] = useState<{
    column: 'name' | 'server' | 'id' | 'bonusScores' | 'date' | 'comment';
    desc: boolean;
  }>({
    column: 'bonusScores',
    desc: true,
  });

  const [adding, setAdding] =
    useState<
      Partial<
        Pick<IWotTournamentAccountBonusDto, 'amount' | 'comment' | 'date'>
      >
    >();

  const [deletingBonusId, setDeletingBonusId] = useState<string>();

  const sortedBonuses = (account?.data?.bonuses ?? [])
    .map((bonus) => {
      const row = {
        accountId: account?.accountId ?? '',
        wotId: account?.data?.wotId ?? 0,
        name: account?.data?.name ?? '',
        server: account?.data?.server ?? 'NA',
        ...bonus,
      };

      return row;
    })
    .sort((a, b) => {
      switch (sorting.column) {
        case 'name':
          return sorting.desc
            ? b.name.localeCompare(a.name)
            : a.name.localeCompare(b.name);
        case 'server':
          return sorting.desc
            ? b.server.localeCompare(a.server)
            : a.server.localeCompare(b.server);
        case 'id':
          return sorting.desc ? b.wotId - a.wotId : a.wotId - b.wotId;
        case 'bonusScores':
          return sorting.desc
            ? (b.amount ?? 0) - (a.amount ?? 0)
            : (a.amount ?? 0) - (b.amount ?? 0);
        case 'date':
          return sorting.desc
            ? b.date.localeCompare(a.date)
            : a.date.localeCompare(b.date);
        case 'comment':
          return sorting.desc
            ? b.comment.localeCompare(a.comment)
            : a.comment.localeCompare(b.comment);
        default:
          return sorting.desc
            ? (b.amount ?? 0) - (a.amount ?? 0)
            : (a.amount ?? 0) - (b.amount ?? 0);
      }
    });

  return (
    <>
      <Row>
        <Col>
          {tournament == null && (
            <Alert variant="warning">
              {t(
                'WotTournamentBonusesTab.NoTournamentAlert',
                'Please select or save a tournament first',
              )}
            </Alert>
          )}
        </Col>

        <Col>
          <div className="filter-container mt-2">
            <WotAccountSelect disabled={adding != null} />

            <Button
              variant="primary"
              className="mb-3"
              onClick={() => setAdding({})}
              disabled={
                tournament == null || deletingBonusId != null || adding != null
              }
            >
              {t('WotTournamentBonusesTab.ButtonAdd', 'Add bonus or penalty')}
            </Button>
          </div>
        </Col>
      </Row>

      <WotTournamentBonusesTabModalDelete
        bonus={sortedBonuses.find((b) => b.id === deletingBonusId)}
        onCancelClick={() => setDeletingBonusId(undefined)}
        onOkClick={() => {
          const bonus = sortedBonuses.find((b) => b.id === deletingBonusId);

          if (bonus != null && tournament?.id != null) {
            dispatch(
              deleteWotTournamentAccountBonusAsync(
                tournament.id,
                bonus.accountId,
                bonus.id,
              ),
            );
          }

          setDeletingBonusId(undefined);
        }}
      />

      <Table bordered hover className="mb-5" responsive>
        <thead>
          <tr>
            <th></th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({ column: 'name', desc: !sorting.desc })
                }
              >
                {t('WotTournamentBonusesTab.ColumnName', 'Name')}
              </Button>
            </th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({ column: 'server', desc: !sorting.desc })
                }
              >
                {t('WotTournamentBonusesTab.ColumnServer', 'Server')}
              </Button>
            </th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({ column: 'id', desc: !sorting.desc })
                }
              >
                {t('WotTournamentBonusesTab.ColumnId', 'ID')}
              </Button>
            </th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({ column: 'bonusScores', desc: !sorting.desc })
                }
              >
                {t('WotTournamentBonusesTab.ColumnBonusScores', 'Bonus scores')}
              </Button>
            </th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({ column: 'date', desc: !sorting.desc })
                }
              >
                {t('WotTournamentBonusesTab.ColumnDate', 'Date')}
              </Button>
            </th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({
                    column: 'comment',
                    desc: !sorting.desc,
                  })
                }
              >
                {t('WotTournamentBonusesTab.ColumnComment', 'Comment')}
              </Button>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {adding != null && (
            <tr key="new">
              <td></td>
              <td>{account?.data?.name}</td>
              <td>{account?.data?.server}</td>
              <td>{account?.data?.wotId}</td>
              <td>
                <Form.Control
                  type="number"
                  value={adding.amount ?? ''}
                  onChange={(event) =>
                    setAdding({
                      ...adding,
                      amount:
                        event.target.value === ''
                          ? undefined
                          : +event.target.value,
                    })
                  }
                  isInvalid={adding.amount == null}
                />
              </td>
              <td>
                <DatePicker
                  showTimeSelect
                  selected={
                    adding.date == null ? undefined : new Date(adding.date)
                  }
                  onChange={(date) =>
                    setAdding({
                      ...adding,
                      date: date?.toISOString(),
                    })
                  }
                  dateFormat="MMMM d, yyyy HH:mm"
                  required={true}
                />
              </td>
              <td>
                <Form.Control
                  value={adding.comment ?? ''}
                  onChange={(event) =>
                    setAdding({ ...adding, comment: event.target.value })
                  }
                  isInvalid={adding.comment == null}
                />
              </td>
              <td>
                <Stack direction="horizontal">
                  <Button
                    disabled={
                      tournament == null ||
                      adding == null ||
                      adding.amount == null ||
                      adding.comment == null ||
                      adding.date == null ||
                      loading.find(
                        (x) =>
                          x.key === LoadingParts.WotTournamentAccountBonusSave,
                      )?.value === true
                    }
                    onClick={() => {
                      if (
                        tournament?.id != null &&
                        account?.accountId != null &&
                        adding?.amount != null &&
                        adding?.date != null &&
                        adding?.comment != null
                      ) {
                        dispatch(
                          addWotTournamentAccountBonusAsync(
                            tournament.id,
                            account.accountId,
                            adding.amount,
                            new Date(adding.date),
                            adding.comment,
                          ),
                        );
                      }
                      setAdding(undefined);
                    }}
                    variant="success"
                  >
                    {t('WotTournamentBonusesTab.ButtonSave', 'Save')}
                  </Button>

                  <Button
                    className="ms-3"
                    onClick={() => setAdding(undefined)}
                    variant="outline-danger"
                  >
                    {t('WotTournamentBonusesTab.ButtonCancel', 'Cancel')}
                  </Button>
                </Stack>
              </td>
            </tr>
          )}
          {sortedBonuses.length > 0 &&
            sortedBonuses.map((row, i) => (
              <tr key={row.id}>
                <td>{i + 1}</td>
                <td>{row.name}</td>
                <td>{row.server}</td>
                <td>{row.wotId}</td>
                <td>{row.amount ?? '-'}</td>
                <td>
                  <DatePicker
                    selected={row.date == null ? undefined : new Date(row.date)}
                    readOnly={true}
                    dateFormat="MMMM d, yyyy HH:mm"
                    onChange={() => {}}
                  />
                </td>
                <td>{row.comment ?? '-'}</td>
                <td>
                  <Button
                    variant="link"
                    disabled={
                      loading.find(
                        (x) =>
                          x.key === LoadingParts.WotTournamentAccountBonusSave,
                      )?.value === true || adding != null
                    }
                    onClick={() => setDeletingBonusId(row.id)}
                  >
                    <Trash3 size={20} color="red" />
                  </Button>
                </td>
              </tr>
            ))}

          {sortedBonuses.length === 0 && (
            <tr>
              <td colSpan={10} className="text-center">
                {t(
                  'WotTournamentBonusesTab.NoTableRows',
                  'There are no records matching the selected filters.',
                )}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}
