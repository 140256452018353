import './wot-tournament-widget-individual.scss';
import { useEffect, useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../redux/store';
import { useSelector } from 'react-redux';
import { OffcanvasPlacement } from 'react-bootstrap/esm/Offcanvas';
import { nameof } from '../../../../utils/nameof.util';
import { WidgetParams } from '../../widgets';
import { LogService } from '../../../../services/log.service';
import {
  getWotTournamentAccountPublicAsync,
  selectWotTournamentAccountPublic,
} from '../../../main/tournaments/item/redux/wot-tournament-account-slice';
import { WotServer } from '../../../../services/api/interfaces/common-api-interfase';

export function WotTournamentWidgetIndividual() {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const tournamentAccount = useSelector(selectWotTournamentAccountPublic);

  const { id: tournamentId } = useParams<{ id: string }>();
  let [searchParams] = useSearchParams();

  const [show, setShow] = useState(true);
  const [firstShow, setFirstShow] = useState(true);
  const [headerState, setHeaderState] = useState<1 | 2 | 3 | 4>(1);

  // parse search params
  const accountId: WidgetParams['accountId'] | undefined =
    searchParams.get(nameof<WidgetParams>('accountId')) == null ||
    searchParams.get(nameof<WidgetParams>('accountId')) === ''
      ? undefined
      : +searchParams.get(nameof<WidgetParams>('accountId'))!;

  const server: WidgetParams['server'] | undefined =
    searchParams.get(nameof<WidgetParams>('server')) == null ||
    searchParams.get(nameof<WidgetParams>('server')) === ''
      ? undefined
      : (searchParams.get(
          nameof<WidgetParams>('server'),
        ) as WidgetParams['server'])!;

  const keyValue: WidgetParams['keyValue'] =
    (searchParams.get(
      nameof<WidgetParams>('keyValue'),
    ) as WidgetParams['keyValue']) ?? 'scores';

  const overlay: WidgetParams['overlay'] =
    (searchParams.get(
      nameof<WidgetParams>('overlay'),
    ) as WidgetParams['overlay']) ?? 'right';

  const overlayDirection: OffcanvasPlacement =
    overlay === 'left' ? 'start' : overlay === 'right' ? 'end' : overlay;

  let hideTime: WidgetParams['hideTime'] =
    searchParams.get(nameof<WidgetParams>('hideTime')) == null
      ? 0
      : +searchParams.get(nameof<WidgetParams>('hideTime'))!;

  if (hideTime < 0) {
    hideTime = 0;
  } else if (hideTime > 30000) {
    hideTime = 30000;
  }

  let showTime: WidgetParams['showTime'] =
    searchParams.get(nameof<WidgetParams>('showTime')) == null
      ? 0
      : +searchParams.get(nameof<WidgetParams>('showTime'))!;

  if (showTime < 0) {
    showTime = 0;
  } else if (showTime > 30000) {
    showTime = 30000;
  }

  // time to finish
  let daysToFinish = 0;
  let hoursToFinish = 0;
  let minutesToFinish = 0;
  if (tournamentAccount != null) {
    const finishTime = new Date(tournamentAccount.endAt).getTime();
    const currentTime = Date.now();

    if (finishTime > currentTime) {
      const diff = finishTime - currentTime;
      daysToFinish = Math.floor(diff / (1000 * 60 * 60 * 24));
      hoursToFinish = Math.floor(
        (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      );
      minutesToFinish = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    }
  }

  // get account data
  useEffect(() => {
    if (
      tournamentId != null &&
      tournamentId !== '' &&
      tournamentId !== 'new' &&
      accountId != null
    ) {
      dispatch(
        getWotTournamentAccountPublicAsync(tournamentId, accountId, server),
      );

      const interval = setInterval(
        () =>
          dispatch(
            getWotTournamentAccountPublicAsync(
              tournamentId,
              accountId,
              server as WotServer,
            ),
          ),
        1000 * 30,
      );
      return () => clearInterval(interval);
    }
  }, [accountId, dispatch, server, tournamentId]);

  // hide widget by time
  useEffect(() => {
    LogService.debug(
      'WotTournamentWidgetIndividual - useEffect - hide widget by time',
    );

    if (hideTime === 0) {
      setShow(true);
      return;
    }

    if (showTime === 0) {
      setShow(false);
      return;
    }

    if (firstShow) {
      const timeout = setTimeout(() => {
        setShow(false);
        setFirstShow(false);
      }, showTime * 1000);

      return () => clearTimeout(timeout);
    } else {
      const interval = setInterval(
        () => {
          setShow(false);
        },
        (hideTime + showTime) * 1000,
      );

      return () => clearInterval(interval);
    }
  }, [firstShow, hideTime, showTime]);

  // show widget by time
  useEffect(() => {
    LogService.debug(
      'WotTournamentWidgetIndividual - useEffect - show widget by time',
    );

    if (hideTime === 0) {
      setShow(true);
      return;
    }

    if (showTime === 0) {
      setShow(false);
      return;
    }

    const interval = setInterval(
      () => {
        setShow(true);
      },
      (hideTime + showTime) * 1000,
    );

    return () => clearInterval(interval);
  }, [hideTime, showTime]);

  // switch header
  useEffect(() => {
    LogService.debug(
      'WotTournamentWidgetIndividual - useEffect - switch header',
    );

    const interval = setInterval(() => {
      const state = headerState === 4 ? 1 : headerState + 1;
      setHeaderState(state as 1 | 2 | 3 | 4);
    }, 30 * 1000);

    return () => clearInterval(interval);
  }, [headerState]);

  return (
    <Offcanvas
      id="wot-tournament-widget-individual-offcanvas"
      show={show}
      placement={overlayDirection}
      keyboard={false}
      backdrop={false}
    >
      <Offcanvas.Body>
        <div className={`wot-tournament-widget-individual-container`}>
          <p className="wot-tournament-widget-individual-tournament-name">
            {headerState % 2 === 0 && tournamentAccount?.name}

            {headerState % 2 !== 0 &&
              t(
                'WotTournamentWidgetIndividual.BattlesHeader',
                'Battles: {{done}}/{{total}}',
                {
                  done: tournamentAccount?.accounts[0]?.results.battles ?? 0,
                  total: tournamentAccount?.rules?.battlesLimit ?? '-',
                },
              )}
          </p>

          <p className="wot-tournament-widget-individual-tournament-status">
            {headerState === 1 &&
              daysToFinish > 0 &&
              t(
                'WotTournamentWidgetIndividual.StatusDaysHours',
                'To finish: {{days}}d {{hours}}h',
                {
                  days: daysToFinish < 10 ? `0${daysToFinish}` : daysToFinish,
                  hours:
                    hoursToFinish < 10 ? `0${hoursToFinish}` : hoursToFinish,
                },
              )}

            {headerState === 1 &&
              daysToFinish === 0 &&
              t(
                'WotTournamentWidgetIndividual.StatusHoursMinutes',
                'To finish: {{hours}}h {{minutes}}m',
                {
                  hours:
                    hoursToFinish < 10 ? `0${hoursToFinish}` : hoursToFinish,
                  minutes:
                    minutesToFinish < 10
                      ? `0${minutesToFinish}`
                      : minutesToFinish,
                },
              )}

            {headerState === 2 &&
              keyValue === 'scores' &&
              t(
                'WotTournamentWidgetIndividual.AvgDamageHeader',
                'Avg. damage: {{avgDamage}}',
                {
                  avgDamage: Math.round(
                    (tournamentAccount?.accounts[0]?.results?.damage ?? 0) /
                      (tournamentAccount?.accounts[0]?.results?.battles || 1),
                  ),
                },
              )}

            {headerState === 2 &&
              keyValue === 'avgDamage' &&
              t(
                'WotTournamentWidgetIndividual.ScoresHeader',
                'Scores: {{scores}}',
                {
                  scores:
                    tournamentAccount?.accounts[0]?.results?.totalScores ?? 0,
                },
              )}

            {headerState === 3 &&
              t(
                'WotTournamentWidgetIndividual.HeaderResets',
                'Resets: {{current}}/{{limit}}',
                {
                  current:
                    tournamentAccount?.accountsRemovals?.filter(
                      (x) =>
                        x.accountId ===
                        tournamentAccount?.accounts[0]?.accountId,
                    ).length ?? 0,
                  limit: tournamentAccount?.rules?.accountsRemovalsLimit ?? '-',
                },
              )}

            {headerState === 4 &&
              t(
                'WotTournamentWidgetIndividual.HeaderBattlesRemovals',
                'Rem. battles: {{current}}/{{limit}}',
                {
                  current:
                    tournamentAccount?.accounts[0]?.results.deletedBattles ?? 0,
                  limit: tournamentAccount?.rules?.battlesRemovalsLimit ?? '-',
                },
              )}
          </p>

          <p className="wot-tournament-widget-individual-tournament-place-label">
            {t('WotTournamentWidgetIndividual.PlaceLabel', 'Place')}
          </p>
          <p className="wot-tournament-widget-individual-tournament-place">
            {tournamentAccount?.accounts[0]?.results?.place ?? 0}
          </p>

          <p className="wot-tournament-widget-individual-tournament-scores-label">
            {keyValue === 'scores' &&
              t('WotTournamentWidgetIndividual.ScoresLabel', 'Scores')}

            {keyValue === 'avgDamage' &&
              t('WotTournamentWidgetIndividual.AvgDamageLabel', 'Avg damage')}
          </p>
          <p
            className={`wot-tournament-widget-individual-tournament-scores ${
              keyValue !== 'scores' ||
              (tournamentAccount?.accounts[0]?.results?.totalScores ?? 0) <=
                9999
                ? ''
                : (tournamentAccount?.accounts[0]?.results?.totalScores ?? 0) <=
                    999999
                  ? 'wot-tournament-widget-individual-tournament-scores-large'
                  : 'wot-tournament-widget-individual-tournament-scores-x-large'
            }`}
          >
            {keyValue === 'avgDamage' &&
              Math.round(
                (tournamentAccount?.accounts[0]?.results?.damage ?? 0) /
                  (tournamentAccount?.accounts[0]?.results?.battles || 1),
              )}
            {(keyValue === 'scores' &&
              tournamentAccount?.accounts[0]?.results?.totalScores) ??
              0}
          </p>

          <p className="wot-tournament-widget-individual-first-place-scores">
            {(keyValue === 'scores' &&
              (tournamentAccount?.accounts[0]?.battles ?? [])
                .filter((b) => b.numberOfBattles === 1)
                .sort((a, b) => b.scores - a.scores)[0]?.scores) ??
              '-'}
            {(keyValue === 'avgDamage' &&
              (tournamentAccount?.accounts[0]?.battles ?? [])
                .filter((b) => b.numberOfBattles === 1)
                .sort((a, b) => b.damage - a.damage)[0]?.damage) ??
              '-'}
          </p>

          <p className="wot-tournament-widget-individual-second-place-scores">
            {(keyValue === 'scores' &&
              (tournamentAccount?.accounts[0]?.battles ?? [])
                .filter((b) => b.numberOfBattles === 1)
                .sort((a, b) => b.scores - a.scores)[1]?.scores) ??
              '-'}
            {(keyValue === 'avgDamage' &&
              (tournamentAccount?.accounts[0]?.battles ?? [])
                .filter((b) => b.numberOfBattles === 1)
                .sort((a, b) => b.damage - a.damage)[1]?.damage) ??
              '-'}
          </p>

          <p className="wot-tournament-widget-individual-third-place-scores">
            {(keyValue === 'scores' &&
              (tournamentAccount?.accounts[0]?.battles ?? [])
                .filter((b) => b.numberOfBattles === 1)
                .sort((a, b) => b.scores - a.scores)[2]?.scores) ??
              '-'}
            {(keyValue === 'avgDamage' &&
              (tournamentAccount?.accounts[0]?.battles ?? [])
                .filter((b) => b.numberOfBattles === 1)
                .sort((a, b) => b.damage - a.damage)[2]?.damage) ??
              '-'}
          </p>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}
