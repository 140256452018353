import './wot-blitz-tournament-widget-individual.scss';
import { useEffect, useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../redux/store';
import { useSelector } from 'react-redux';
import {
  getWotBlitzTournamentPublicAsync,
  selectWotBlitzTournamentPublicItem,
} from '../../../main/tournaments/wot-blitz/wot-blitz-tournaments-slice';
import { OffcanvasPlacement } from 'react-bootstrap/esm/Offcanvas';
import { nameof } from '../../../../utils/nameof.util';
import { WidgetParams } from '../../widgets';

export function WotBlitzTournamentWidgetIndividual() {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const tournament = useSelector(selectWotBlitzTournamentPublicItem);

  const { id } = useParams<{ id: string }>();
  let [searchParams] = useSearchParams();

  const [overlayDirection, setOverlayDirection] =
    useState<OffcanvasPlacement>('end');

  const [show, setShow] = useState(true);
  const [firstShow, setFirstShow] = useState(true);

  const [status, setStatus] = useState<
    'pending' | 'active' | 'completed' | undefined
  >(undefined);

  const [results, setResults] = useState<{
    scores: number;
    place: number;
    firstPlaceScores: number;
    secondPlaceScores: number;
    thirdPlaceScores: number;
  }>({
    scores: 0,
    place: 0,
    firstPlaceScores: 0,
    secondPlaceScores: 0,
    thirdPlaceScores: 0,
  });

  // get overlay direction
  useEffect(() => {
    const overlay: WidgetParams['overlay'] =
      (searchParams.get(
        nameof<WidgetParams>('overlay'),
      ) as WidgetParams['overlay']) ?? 'right';

    switch (overlay) {
      case 'top':
        setOverlayDirection('top');
        break;
      case 'bottom':
        setOverlayDirection('bottom');
        break;
      case 'left':
        setOverlayDirection('start');
        break;
      case 'right':
        setOverlayDirection('end');
        break;
      default:
        setOverlayDirection('end');
    }
  }, [searchParams]);

  // get tournament by id
  useEffect(() => {
    if (id != null && id !== '' && id !== 'new') {
      dispatch(getWotBlitzTournamentPublicAsync(id));

      const interval = setInterval(
        () => dispatch(getWotBlitzTournamentPublicAsync(id)),
        1000 * 60,
      );
      return () => {
        clearInterval(interval);
      };
    }
  }, [dispatch, id, tournament?.id]);

  // detect tournament status
  useEffect(() => {
    if (
      tournament == null ||
      tournament.startAt == null ||
      tournament.endAt == null
    ) {
      setStatus(undefined);
      return;
    }

    const start = new Date(tournament.startAt);
    const end = new Date(tournament.endAt);
    const now = new Date();

    if (start.getTime() > now.getTime()) {
      setStatus('pending');
      return;
    }

    if (end.getTime() < now.getTime()) {
      setStatus('completed');
      return;
    }

    setStatus('active');
  }, [tournament]);

  // hide widget by time
  useEffect(() => {
    const showTime: WidgetParams['showTime'] = +(
      searchParams.get(nameof<WidgetParams>('showTime')) ?? 30
    );
    const hideTime: WidgetParams['hideTime'] = +(
      searchParams.get(nameof<WidgetParams>('hideTime')) ?? 300
    );

    if (firstShow) {
      const timeout = setTimeout(() => {
        setShow(false);
        setFirstShow(false);
      }, showTime * 1000);

      return () => clearTimeout(timeout);
    } else {
      const interval = setInterval(
        () => {
          setShow(false);
        },
        (hideTime + showTime) * 1000,
      );

      return () => clearInterval(interval);
    }
  }, [firstShow, searchParams]);

  // show widget by time
  useEffect(() => {
    const showTime: WidgetParams['showTime'] = +(
      searchParams.get(nameof<WidgetParams>('showTime')) ?? 30
    );
    const hideTime: WidgetParams['hideTime'] = +(
      searchParams.get(nameof<WidgetParams>('hideTime')) ?? 300
    );

    const interval = setInterval(
      () => {
        setShow(true);
      },
      (hideTime + showTime) * 1000,
    );

    return () => clearInterval(interval);
  }, [searchParams]);

  // set results
  useEffect(() => {
    if (tournament == null) {
      setResults({
        scores: 0,
        place: 0,
        firstPlaceScores: 0,
        secondPlaceScores: 0,
        thirdPlaceScores: 0,
      });
      return;
    }

    const accountIdValue = searchParams.get(nameof<WidgetParams>('accountId'));
    const accountId: WidgetParams['accountId'] | undefined =
      accountIdValue == null || accountIdValue === ''
        ? undefined
        : +accountIdValue;

    if (accountId == null) {
      setResults({
        scores: 0,
        place: 0,
        firstPlaceScores: 0,
        secondPlaceScores: 0,
        thirdPlaceScores: 0,
      });
      return;
    }

    if (tournament.rules.mode === 'individual') {
      const accounts = [...tournament.accounts].sort((a, b) => {
        const diff =
          (b.results?.totalScores ?? 0) - (a.results?.totalScores ?? 0);
        if (diff !== 0) return diff;

        return a.name.localeCompare(b.name);
      });

      const accountIndex = accounts.findIndex((a) => a.wotId === +accountId);

      setResults({
        scores: accounts[accountIndex]?.results?.totalScores ?? 0,
        place: accountIndex + 1,
        firstPlaceScores: accounts[0]?.results?.totalScores ?? 0,
        secondPlaceScores: accounts[1]?.results?.totalScores ?? 0,
        thirdPlaceScores: accounts[2]?.results?.totalScores ?? 0,
      });
    } else if (tournament.rules.mode === 'team') {
      const teamName = tournament.accounts.find(
        (a) => a.wotId === +accountId,
      )?.team;

      if (teamName == null || teamName === '') {
        setResults({
          scores: 0,
          place: 0,
          firstPlaceScores: 0,
          secondPlaceScores: 0,
          thirdPlaceScores: 0,
        });
        return;
      }

      const teamsMap = tournament.accounts
        .filter((a) => a.team != null && a.team !== '')
        .reduce<{ [key: string]: number }>((acc, cur) => {
          if (acc[cur.team as string] == null) {
            acc[cur.team as string] = 0;
          }

          acc[cur.team as string] += cur.results?.totalScores ?? 0;

          return acc;
        }, {});

      const teams = Object.keys(teamsMap).map((key) => {
        return {
          name: key,
          scores: teamsMap[key],
        };
      });

      teams.sort((a, b) => {
        const diff = b.scores - a.scores;
        if (diff !== 0) return diff;

        return a.name.localeCompare(b.name);
      });

      const teamIndex = teams.findIndex((t) => t.name === teamName);

      setResults({
        scores: teams[teamIndex]?.scores ?? 0,
        place: teamIndex + 1,
        firstPlaceScores: teams[0]?.scores ?? 0,
        secondPlaceScores: teams[1]?.scores ?? 0,
        thirdPlaceScores: teams[2]?.scores ?? 0,
      });
    }
  }, [searchParams, tournament]);

  return (
    <Offcanvas
      id="wot-blitz-tournament-widget-individual-offcanvas"
      show={show}
      placement={overlayDirection}
      keyboard={false}
      backdrop={false}
    >
      <Offcanvas.Body>
        <div className={`wot-blitz-tournament-widget-individual-container`}>
          <p className="wot-blitz-tournament-widget-individual-tournament-name">
            {tournament?.name}
          </p>

          {status === 'pending' && (
            <p className="wot-blitz-tournament-widget-individual-tournament-status-pending">
              {t('WotBlitzTournamentWidgetIndividual.StatusPending', 'Pending')}
            </p>
          )}
          {status === 'active' && (
            <p className="wot-blitz-tournament-widget-individual-tournament-status-active">
              {t('WotBlitzTournamentWidgetIndividual.StatusActive', 'Active')}
            </p>
          )}
          {status === 'completed' && (
            <p className="wot-blitz-tournament-widget-individual-tournament-status-completed">
              {t(
                'WotBlitzTournamentWidgetIndividual.StatusCompleted',
                'Completed',
              )}
            </p>
          )}

          <p className="wot-blitz-tournament-widget-individual-tournament-place-label">
            {t('WotBlitzTournamentWidgetIndividual.PlaceLabel', 'Place')}
          </p>
          <p className="wot-blitz-tournament-widget-individual-tournament-place">
            {results.place}
          </p>

          <p className="wot-blitz-tournament-widget-individual-tournament-scores-label">
            {t('WotBlitzTournamentWidgetIndividual.ScoresLabel', 'Scores')}
          </p>
          <p className="wot-blitz-tournament-widget-individual-tournament-scores">
            {results.scores}
          </p>

          <p className="wot-blitz-tournament-widget-individual-first-place-scores">
            {results.firstPlaceScores}
          </p>

          <p className="wot-blitz-tournament-widget-individual-second-place-scores">
            {results.secondPlaceScores}
          </p>

          <p className="wot-blitz-tournament-widget-individual-third-place-scores">
            {results.thirdPlaceScores}
          </p>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}
