import { useState } from 'react';
import { Alert, Button, Col, Form, Row, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../redux/store';
import { showToast } from '../../../toast/toastSlice';
import { WidgetParams } from '../../../../widgets/widgets';
import { nameof } from '../../../../../utils/nameof.util';
import { IWotBlitzTournamentDto } from '../../../../../services/api/wot-blitz-tournaments-api.service';

export function WotBlitzTournamentWidgetTab(props: {
  tournament?: IWotBlitzTournamentDto | null;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [widgetType, setWidgetType] = useState<WidgetParams['type']>('table');
  const [overlayDirection, setOverlayDirection] =
    useState<WidgetParams['overlay']>('right');
  const [language, setLanguage] = useState<WidgetParams['lang']>('ru');
  const [hiddingTime, setHiddingTime] = useState<WidgetParams['hideTime']>(300);
  const [showingTime, setShowingTime] = useState<WidgetParams['showTime']>(30);
  const [wotAccountId, setWotAccountId] = useState<
    WidgetParams['accountId'] | undefined
  >(props.tournament?.accounts[0]?.wotId);

  const getWidgetUrl = () => {
    const url = new URL(window.location.href);
    url.pathname = `/widgets/tournaments/wot_blitz/${props.tournament?.id}`;
    url.searchParams.set(nameof<WidgetParams>('type'), widgetType);
    url.searchParams.set(nameof<WidgetParams>('overlay'), overlayDirection);
    url.searchParams.set(nameof<WidgetParams>('lang'), language);
    url.searchParams.set(
      nameof<WidgetParams>('showTime'),
      showingTime.toString(),
    );
    url.searchParams.set(
      nameof<WidgetParams>('hideTime'),
      hiddingTime.toString(),
    );
    url.searchParams.set(
      nameof<WidgetParams>('accountId'),
      wotAccountId?.toString() ?? '',
    );
    return url.toString();
  };

  const getPublicWidgetSettingsUrl = () => {
    const url = new URL(window.location.href);
    url.pathname = `/widgets/tournaments/wot_blitz/${props.tournament?.id}/settings`;
    return url.toString();
  };

  const handleOpen = () => {
    window.open(getWidgetUrl(), '_blank');
  };
  const handleCopy = () => {
    void navigator.clipboard.writeText(getWidgetUrl());
    dispatch(
      showToast({
        title: 'Success',
        message: t(
          'WotBlitzTournamentWidgetTab.LinkCopiedToClipboard',
          'Link copied to clipboard',
        ),
        variant: 'success',
      }),
    );
  };

  return (
    <>
      {props.tournament == null && (
        <Alert variant="warning">
          {t(
            'WotBlitzTournamentWidgetTab.NoTournamentAlert',
            'Please select or save a tournament first',
          )}
        </Alert>
      )}

      {props.tournament?.accounts.length === 0 && (
        <Alert variant="warning">
          {t(
            'WotBlitzTournamentWidgetTab.NoTournamentAccountsAlert',
            'Please add accounts to the tournament first',
          )}
        </Alert>
      )}

      <Form className="p-2">
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2}>
            {t('WotBlitzTournamentWidgetTab.PublicSettings', 'Public settings')}
          </Form.Label>

          <Col sm={10}>
            <a
              href={getPublicWidgetSettingsUrl()}
              target="_blank"
              rel="noreferrer"
            >
              {getPublicWidgetSettingsUrl()}
            </a>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2}>
            {t('WotBlitzTournamentWidgetTab.Type', 'Type')}
          </Form.Label>

          <Col sm={10}>
            <Form.Select
              className="standard-input-width"
              onChange={(event) => setWidgetType(event.target.value as any)}
              value={widgetType}
            >
              <option value="table">Table</option>
              <option value="individual">Individual</option>
            </Form.Select>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2}>
            {t(
              'WotBlitzTournamentWidgetTab.OverlayDirection',
              'Overlay direction',
            )}
          </Form.Label>

          <Col sm={10}>
            <Form.Select
              className="standard-input-width"
              onChange={(event) =>
                setOverlayDirection(event.target.value as any)
              }
              value={overlayDirection}
            >
              <option value="top">Top</option>
              <option value="bottom">Bottom</option>
              <option value="right">Right</option>
              <option value="left">Left</option>
            </Form.Select>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2}>
            {t('WotBlitzTournamentWidgetTab.Language', 'Language')}
          </Form.Label>

          <Col sm={10}>
            <Form.Select
              className="standard-input-width"
              onChange={(event) => setLanguage(event.target.value as any)}
              value={language}
            >
              <option value="en">English</option>
              <option value="ru">Russian</option>
              <option value="uk">Ukrainian</option>
            </Form.Select>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2}>
            {t('WotBlitzTournamentWidgetTab.HiddingTime', 'Hidding time (sec)')}
          </Form.Label>

          <Col sm={10}>
            <Form.Control
              className="standard-input-width"
              type="number"
              min={0}
              value={hiddingTime}
              onChange={(event) => {
                setHiddingTime(+event.target.value);
              }}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2}>
            {t('WotBlitzTournamentWidgetTab.ShowingTime', 'Showing time (sec)')}
          </Form.Label>

          <Col sm={10}>
            <Form.Control
              className="standard-input-width"
              type="number"
              min={0}
              value={showingTime}
              onChange={(event) => {
                setShowingTime(+event.target.value);
              }}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2}>
            {t('WotBlitzTournamentWidgetTab.Account', 'Account')}
          </Form.Label>

          <Col sm={10}>
            <Form.Select
              className="standard-input-width"
              onChange={(event) => setWotAccountId(+event.target.value)}
              value={wotAccountId}
            >
              {props.tournament?.accounts.map((account) => (
                <option key={account.wotId} value={account.wotId}>
                  ({account.wotId}) {account.name}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Form.Group>
      </Form>

      <Row>
        <Col sm={{ span: 2, offset: 10 }}>
          <Stack direction="horizontal" gap={3} className="mt-3">
            <Button
              variant="primary"
              disabled={
                props.tournament == null ||
                props.tournament.accounts.length === 0
              }
              onClick={() => {
                handleOpen();
              }}
            >
              {t('WotBlitzTournamentWidgetTab.OpenButton', 'Open')}
            </Button>

            <Button
              variant="primary"
              disabled={
                props.tournament == null ||
                props.tournament.accounts.length === 0
              }
              onClick={() => {
                handleCopy();
              }}
            >
              {t('WotBlitzTournamentWidgetTab.CopyUrlButton', 'Copy URL')}
            </Button>
          </Stack>
        </Col>
      </Row>
    </>
  );
}
