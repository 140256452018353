import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IWotTournamentRulesDto } from '../../../../../../services/api/wot-tournaments-api.service';

export function WotTournamentMultipliers(props: {
  battleMultiplier: IWotTournamentRulesDto['battleMultiplier'] | null;
  onBattleMultiplierChange: (
    battleMultiplier: IWotTournamentRulesDto['battleMultiplier'] | null,
  ) => void;
  isBattleMultiplierValid: boolean;

  winMultiplier: IWotTournamentRulesDto['winMultiplier'] | null;
  onWinMultiplierChange: (
    winMultiplier: IWotTournamentRulesDto['winMultiplier'] | null,
  ) => void;
  isWinMultiplierValid: boolean;

  fragMultiplier: IWotTournamentRulesDto['fragMultiplier'] | null;
  onFragMultiplierChange: (
    fragMultiplier: IWotTournamentRulesDto['fragMultiplier'] | null,
  ) => void;
  isFragMultiplierValid: boolean;

  damageMultiplier: IWotTournamentRulesDto['damageMultiplier'] | null;
  onDamageMultiplierChange: (
    damageMultiplier: IWotTournamentRulesDto['damageMultiplier'] | null,
  ) => void;
  isDamageMultiplierValid: boolean;

  xpMultiplier: IWotTournamentRulesDto['xpMultiplier'] | null;
  onXpMultiplierChange: (
    xpMultiplier: IWotTournamentRulesDto['xpMultiplier'] | null,
  ) => void;
  isXpMultiplierValid: boolean;
}) {
  const { t } = useTranslation();

  return (
    <>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={2}>
          {t('WotTournament.BattleMultiplier', 'Battle multiplier')}
        </Form.Label>

        <Col sm={10}>
          <Form.Control
            className="standard-input-width"
            type="number"
            min={0}
            value={props.battleMultiplier ?? ''}
            onChange={(event) =>
              props.onBattleMultiplierChange(
                event.target.value === '' ? null : +event.target.value,
              )
            }
            isInvalid={!props.isBattleMultiplierValid}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={2}>
          {t('WotTournament.WinMultiplier', 'Win multiplier')}
        </Form.Label>

        <Col sm={10}>
          <Form.Control
            className="standard-input-width"
            type="number"
            min={0}
            value={props.winMultiplier ?? ''}
            onChange={(event) =>
              props.onWinMultiplierChange(
                event.target.value === '' ? null : +event.target.value,
              )
            }
            isInvalid={!props.isWinMultiplierValid}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={2}>
          {t('WotTournament.FragMultiplier', 'Frag multiplier')}
        </Form.Label>

        <Col sm={10}>
          <Form.Control
            className="standard-input-width"
            type="number"
            min={0}
            value={props.fragMultiplier ?? ''}
            onChange={(event) =>
              props.onFragMultiplierChange(
                event.target.value === '' ? null : +event.target.value,
              )
            }
            isInvalid={!props.isFragMultiplierValid}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={2}>
          {t('WotTournament.DamageMultiplier', 'Damage multiplier')}
        </Form.Label>

        <Col sm={10}>
          <Form.Control
            className="standard-input-width"
            type="number"
            min={0}
            value={props.damageMultiplier ?? ''}
            onChange={(event) =>
              props.onDamageMultiplierChange(
                event.target.value === '' ? null : +event.target.value,
              )
            }
            isInvalid={!props.isDamageMultiplierValid}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={2}>
          {t('WotTournament.XpMultiplier', 'XP multiplier')}
        </Form.Label>

        <Col sm={10}>
          <Form.Control
            className="standard-input-width"
            type="number"
            min={0}
            value={props.xpMultiplier ?? ''}
            onChange={(event) =>
              props.onXpMultiplierChange(
                event.target.value === '' ? null : +event.target.value,
              )
            }
            isInvalid={!props.isXpMultiplierValid}
          />
        </Col>
      </Form.Group>
    </>
  );
}
