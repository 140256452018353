import { Button, Modal } from 'react-bootstrap';
import { t } from 'i18next';
import { IWotTournamentTeamDto } from '../../../../../../services/api/wot-tournaments-api.service';

export function WotTournamentTeamsTabModalDelete(props: {
  team: Pick<IWotTournamentTeamDto, 'name'> | undefined;

  onCancelClick: () => void;
  onOkClick: () => void;
}) {
  return (
    <Modal show={props.team != null} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>
          {t('WotTournamentTeamsTab.DeletingModalTitle', 'Deleting a team')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {t(
          'WotTournamentTeamsTab.DeletingModalBody',
          'You are about to delete the team {{name}} from the tournament. This action could not be undone. Are you sure?',
          {
            name: props.team?.name,
          },
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.onCancelClick()}>
          {t('WotTournamentTeamsTab.DeletingCancelButton', 'Cancel')}
        </Button>
        <Button variant="danger" onClick={() => props.onOkClick()}>
          {t('WotTournamentTeamsTab.DeletingDeleteButton', 'Delete')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
