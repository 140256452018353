export const TankTiersValues = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
];
declare type TankTiersTuple = typeof TankTiersValues;
export declare type TankTiers = TankTiersTuple[number];
