export function toRoman(value: number): string {
  if (value < 1 || value > 3999) return 'Invalid Roman Number Value';
  const romanNumList = [
    'M',
    'CM',
    'D',
    'CD',
    'C',
    'XC',
    'L',
    'XL',
    'X',
    'IX',
    'V',
    'IV',
    'I',
  ];
  const numList = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];
  let romanized = '';
  for (let i = 0; i < numList.length; i++) {
    while (value >= numList[i]) {
      romanized += romanNumList[i];
      value -= numList[i];
    }
  }
  return romanized;
}
