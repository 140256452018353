import fetchApi from './fetch-api';
import { components, paths } from './interfaces/api-interfase';

export type WotBlitzTournamentShortDto =
  components['schemas']['WotBlitzTournamentShortDto'];
export type IWotBlitzTournamentDto =
  components['schemas']['WotBlitzTournamentDto'];
export type IWotBlitzTournamentRulesDto =
  components['schemas']['WotBlitzTournamentRulesDto'];
export type IWotBlitzTournamentAccountDto =
  components['schemas']['WotBlitzTournamentAccountDto'];
export type IWotBlitzTournamentAccountBonusDto =
  components['schemas']['WotBlitzTournamentAccountBonusDto'];

export class WotBlitzTournamentsApiService {
  async getAll(): Promise<
    paths['/api/wot-blitz/tournaments']['get']['responses']['200']['content']['application/json']
  > {
    const response = await fetchApi('wot-blitz/tournaments', {
      method: 'GET',
    });

    const body =
      (await response.json()) as paths['/api/wot-blitz/tournaments']['get']['responses']['200']['content']['application/json'];

    return body;
  }

  async getById(
    params: paths['/api/wot-blitz/tournaments/{tournamentId}']['get']['parameters']['path'],
  ): Promise<
    paths['/api/wot-blitz/tournaments/{tournamentId}']['get']['responses']['200']['content']['application/json']
  > {
    const response = await fetchApi(
      `wot-blitz/tournaments/${params.tournamentId}`,
      {
        method: 'GET',
      },
    );

    const body =
      (await response.json()) as paths['/api/wot-blitz/tournaments/{tournamentId}']['get']['responses']['200']['content']['application/json'];

    return body;
  }

  async getByIdPublic(
    params: paths['/api/wot-blitz/tournaments/{tournamentId}/public']['get']['parameters']['path'],
  ): Promise<
    paths['/api/wot-blitz/tournaments/{tournamentId}/public']['get']['responses']['200']['content']['application/json']
  > {
    const response = await fetchApi(
      `wot-blitz/tournaments/${params.tournamentId}/public`,
      {
        method: 'GET',
      },
    );

    const body =
      (await response.json()) as paths['/api/wot-blitz/tournaments/{tournamentId}/public']['get']['responses']['200']['content']['application/json'];

    return body;
  }

  async create(
    payload: paths['/api/wot-blitz/tournaments']['post']['requestBody']['content']['application/json'],
  ): Promise<
    paths['/api/wot-blitz/tournaments']['post']['responses']['200']['content']['application/json']
  > {
    const response = await fetchApi(`wot-blitz/tournaments`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body =
      (await response.json()) as paths['/api/wot-blitz/tournaments']['post']['responses']['200']['content']['application/json'];

    return body;
  }

  async update(
    params: paths['/api/wot-blitz/tournaments/{tournamentId}']['put']['parameters']['path'],
    payload: paths['/api/wot-blitz/tournaments/{tournamentId}']['put']['requestBody']['content']['application/json'],
  ): Promise<
    paths['/api/wot-blitz/tournaments/{tournamentId}']['put']['responses']['200']['content']['application/json']
  > {
    const response = await fetchApi(
      `wot-blitz/tournaments/${params.tournamentId}`,
      {
        method: 'PUT',
        body: JSON.stringify(payload),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );

    const body =
      (await response.json()) as paths['/api/wot-blitz/tournaments/{tournamentId}']['put']['responses']['200']['content']['application/json'];

    return body;
  }

  async addAccount(
    params: paths['/api/wot-blitz/tournaments/{tournamentId}/accounts']['post']['parameters']['path'],
    payload: paths['/api/wot-blitz/tournaments/{tournamentId}/accounts']['post']['requestBody']['content']['application/json'],
  ): Promise<
    paths['/api/wot-blitz/tournaments/{tournamentId}/accounts']['post']['responses']['200']['content']['application/json']
  > {
    const response = await fetchApi(
      `wot-blitz/tournaments/${params.tournamentId}/accounts`,
      {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );

    const body =
      (await response.json()) as paths['/api/wot-blitz/tournaments/{tournamentId}/accounts']['post']['responses']['200']['content']['application/json'];

    return body;
  }

  async updateAccount(
    params: paths['/api/wot-blitz/tournaments/{tournamentId}/accounts/{accountId}']['put']['parameters']['path'],
    payload: paths['/api/wot-blitz/tournaments/{tournamentId}/accounts/{accountId}']['put']['requestBody']['content']['application/json'],
  ): Promise<
    paths['/api/wot-blitz/tournaments/{tournamentId}/accounts/{accountId}']['put']['responses']['200']['content']['application/json']
  > {
    const response = await fetchApi(
      `wot-blitz/tournaments/${params.tournamentId}/accounts/${params.accountId}`,
      {
        method: 'PUT',
        body: JSON.stringify(payload),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );

    const body =
      (await response.json()) as paths['/api/wot-blitz/tournaments/{tournamentId}/accounts/{accountId}']['put']['responses']['200']['content']['application/json'];

    return body;
  }

  async deleteAccount(
    params: paths['/api/wot-blitz/tournaments/{tournamentId}/accounts/{accountId}']['delete']['parameters']['path'],
  ): Promise<
    paths['/api/wot-blitz/tournaments/{tournamentId}/accounts/{accountId}']['delete']['responses']['200']['content']['application/json']
  > {
    const response = await fetchApi(
      `wot-blitz/tournaments/${params.tournamentId}/accounts/${params.accountId}`,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );

    const body =
      (await response.json()) as paths['/api/wot-blitz/tournaments/{tournamentId}/accounts/{accountId}']['delete']['responses']['200']['content']['application/json'];

    return body;
  }

  async addAccountBonus(
    params: paths['/api/wot-blitz/tournaments/{tournamentId}/accounts/{accountId}/bonuses']['post']['parameters']['path'],
    payload: paths['/api/wot-blitz/tournaments/{tournamentId}/accounts/{accountId}/bonuses']['post']['requestBody']['content']['application/json'],
  ): Promise<
    paths['/api/wot-blitz/tournaments/{tournamentId}/accounts/{accountId}/bonuses']['post']['responses']['200']['content']['application/json']
  > {
    const response = await fetchApi(
      `wot-blitz/tournaments/${params.tournamentId}/accounts/${params.accountId}/bonuses`,
      {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );

    const body =
      (await response.json()) as paths['/api/wot-blitz/tournaments/{tournamentId}/accounts/{accountId}/bonuses']['post']['responses']['200']['content']['application/json'];

    return body;
  }

  async deleteAccountBonus(
    params: paths['/api/wot-blitz/tournaments/{tournamentId}/accounts/{accountId}/bonuses/{bonusId}']['delete']['parameters']['path'],
  ): Promise<
    paths['/api/wot-blitz/tournaments/{tournamentId}/accounts/{accountId}/bonuses/{bonusId}']['delete']['responses']['200']['content']['application/json']
  > {
    const response = await fetchApi(
      `wot-blitz/tournaments/${params.tournamentId}/accounts/${params.accountId}/bonuses/${params.bonusId}`,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );

    const body =
      (await response.json()) as paths['/api/wot-blitz/tournaments/{tournamentId}/accounts/{accountId}/bonuses/{bonusId}']['delete']['responses']['200']['content']['application/json'];

    return body;
  }
}
