import { Button, Modal } from 'react-bootstrap';
import { t } from 'i18next';
import { IWotTournamentAccountDto } from '../../../../../../services/api/wot-tournaments-api.service';

export function WotTournamentAccountsTabModalResetClear(props: {
  account:
    | Pick<IWotTournamentAccountDto, 'name' | 'wotId' | 'server'>
    | undefined;

  onCancelClick: () => void;
  onOkClick: () => void;
}) {
  return (
    <Modal show={props.account != null} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>
          {t(
            'WotTournamentAccountsTab.ClearingResetsModalTitle',
            'Clearing account resets',
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {t(
          'WotTournamentAccountsTab.ClearingResetdModalBody',
          'You are about to clear out all account resets for WoT account {{name}} ({{id}}, {{server}}). This action could not be undone. Are you sure?',
          {
            name: props.account?.name,
            id: props.account?.wotId,
            server: props.account?.server,
          },
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.onCancelClick()}>
          {t('WotTournamentAccountsTab.ClearingResetsCancelButton', 'Cancel')}
        </Button>
        <Button variant="danger" onClick={() => props.onOkClick()}>
          {t('WotTournamentAccountsTab.ClearingResetsDeleteButton', 'Clear')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
