import './wot-blitz-tournament-widget-table.scss';
import { useEffect, useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../redux/store';
import { useSelector } from 'react-redux';
import {
  getWotBlitzTournamentPublicAsync,
  selectWotBlitzTournamentPublicItem,
} from '../../../main/tournaments/wot-blitz/wot-blitz-tournaments-slice';
import React from 'react';
import { OffcanvasPlacement } from 'react-bootstrap/esm/Offcanvas';
import { WidgetParams } from '../../widgets';
import { nameof } from '../../../../utils/nameof.util';

export function WotBlitzTournamentWidgetTable() {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const tournament = useSelector(selectWotBlitzTournamentPublicItem);

  const { id } = useParams<{ id: string }>();
  let [searchParams] = useSearchParams();

  const [overlayDirection, setOverlayDirection] =
    useState<OffcanvasPlacement>('end');

  const [show, setShow] = useState(true);
  const [firstShow, setFirstShow] = useState(true);

  const [players, setPlayers] = useState<{ name: string; scores: number }[]>(
    [],
  );
  const [playerIndex, setPlayerIndex] = useState(0);
  const [place, setPlace] = useState<
    | '0'
    | '1'
    | '2'
    | '3'
    | '4'
    | '5'
    | '6'
    | '7'
    | '8'
    | 'center'
    | 'prelast'
    | 'last'
  >('0');

  // get overlay direction
  useEffect(() => {
    const overlay: WidgetParams['overlay'] =
      (searchParams.get(
        nameof<WidgetParams>('overlay'),
      ) as WidgetParams['overlay']) ?? 'right';

    switch (overlay) {
      case 'top':
        setOverlayDirection('top');
        break;
      case 'bottom':
        setOverlayDirection('bottom');
        break;
      case 'left':
        setOverlayDirection('start');
        break;
      case 'right':
        setOverlayDirection('end');
        break;
      default:
        setOverlayDirection('end');
    }
  }, [searchParams]);

  // get tournament by id
  useEffect(() => {
    if (id != null && id !== '' && id !== 'new') {
      dispatch(getWotBlitzTournamentPublicAsync(id));

      const interval = setInterval(
        () => dispatch(getWotBlitzTournamentPublicAsync(id)),
        1000 * 60,
      );
      return () => {
        clearInterval(interval);
      };
    }
  }, [dispatch, id, tournament?.id]);

  // hide widget by time
  useEffect(() => {
    const showTime: WidgetParams['showTime'] = +(
      searchParams.get(nameof<WidgetParams>('showTime')) ?? 30
    );
    const hideTime: WidgetParams['hideTime'] = +(
      searchParams.get(nameof<WidgetParams>('hideTime')) ?? 300
    );

    if (firstShow) {
      const timeout = setTimeout(() => {
        setShow(false);
        setFirstShow(false);
      }, showTime * 1000);

      return () => clearTimeout(timeout);
    } else {
      const interval = setInterval(
        () => {
          setShow(false);
        },
        (hideTime + showTime) * 1000,
      );

      return () => clearInterval(interval);
    }
  }, [firstShow, searchParams]);

  // show widget by time
  useEffect(() => {
    const showTime: WidgetParams['showTime'] = +(
      searchParams.get(nameof<WidgetParams>('showTime')) ?? 30
    );
    const hideTime: WidgetParams['hideTime'] = +(
      searchParams.get(nameof<WidgetParams>('hideTime')) ?? 300
    );

    const interval = setInterval(
      () => {
        setShow(true);
      },
      (hideTime + showTime) * 1000,
    );

    return () => clearInterval(interval);
  }, [searchParams]);

  // set players
  useEffect(() => {
    if (tournament == null) {
      setPlayers([]);
      setPlayerIndex(0);
      setPlace('0');
      return;
    }

    const accountIdValue = searchParams.get(nameof<WidgetParams>('accountId'));
    const accountId: WidgetParams['accountId'] | undefined =
      accountIdValue == null || accountIdValue === ''
        ? undefined
        : +accountIdValue;

    if (accountId == null) {
      setPlayers([]);
      setPlayerIndex(0);
      setPlace('0');
      return;
    }

    if (tournament.rules.mode === 'individual') {
      const accounts = [...tournament.accounts].sort((a, b) => {
        const diff =
          (b.results?.totalScores ?? 0) - (a.results?.totalScores ?? 0);
        if (diff !== 0) return diff;

        return a.name.localeCompare(b.name);
      });
      setPlayers(
        accounts.map((a) => ({
          name: a.name,
          scores: a.results?.totalScores ?? 0,
        })),
      );

      const currentAccountIndex = accounts.findIndex(
        (a) => a.wotId === +accountId,
      );
      if (currentAccountIndex >= 0) {
        setPlayerIndex(currentAccountIndex);

        if (currentAccountIndex <= 8) {
          setPlace(`${currentAccountIndex}` as any);
        } else if (currentAccountIndex === tournament.accounts.length - 1) {
          setPlace('last');
        } else if (currentAccountIndex === tournament.accounts.length - 2) {
          setPlace('prelast');
        } else setPlace('center');
      }
    } else if (tournament.rules.mode === 'team') {
      const teamName = tournament.accounts.find(
        (a) => a.wotId === +accountId,
      )?.team;

      if (teamName == null || teamName === '') {
        setPlayers([]);
        setPlayerIndex(0);
        setPlace('0');
        return;
      }

      const teamsMap = tournament.accounts
        .filter((a) => a.team != null && a.team !== '')
        .reduce<{ [key: string]: number }>((acc, cur) => {
          if (acc[cur.team as string] == null) {
            acc[cur.team as string] = 0;
          }

          acc[cur.team as string] += cur.results?.totalScores ?? 0;

          return acc;
        }, {});

      const teams = Object.keys(teamsMap).map((key) => {
        return {
          name: key,
          scores: teamsMap[key],
        };
      });

      teams.sort((a, b) => {
        const diff = b.scores - a.scores;
        if (diff !== 0) return diff;

        return a.name.localeCompare(b.name);
      });

      const teamIndex = teams.findIndex((t) => t.name === teamName);

      setPlayers(teams);
      setPlayerIndex(teamIndex);
      if (teamIndex <= 8) {
        setPlace(`${teamIndex}` as any);
      } else if (teamIndex === teams.length - 1) {
        setPlace('last');
      } else if (teamIndex === teams.length - 2) {
        setPlace('prelast');
      } else setPlace('center');
    }
  }, [searchParams, tournament]);

  return (
    <Offcanvas
      id="wot-blitz-tournament-widget-table-offcanvas"
      show={show}
      placement={overlayDirection}
      keyboard={false}
      backdrop={false}
    >
      <Offcanvas.Body>
        <div
          className={`wot-blitz-tournament-widget-table-container wot-blitz-tournament-widget-table-place-${place}`}
        >
          <p className="wot-blitz-tournament-widget-table-table-name">
            {tournament?.name}
          </p>

          <p className="wot-blitz-tournament-widget-table-total-players">
            {t(
              'WotBlitzTournamentWidgetTable.TotalPlayers',
              'Total players: {{totalPlayers}}',
              { totalPlayers: players.length },
            )}
          </p>

          {players.length > 0 && (
            <>
              <p className="wot-blitz-tournament-widget-table-player-scores-first-place">
                {players[0]?.scores ?? '-'}
              </p>

              <p className="wot-blitz-tournament-widget-table-player-scores-second-place">
                {players[1]?.scores ?? '-'}
              </p>

              <p className="wot-blitz-tournament-widget-table-player-scores-third-place">
                {players[2]?.scores ?? '-'}
              </p>

              {place !== 'last' &&
                place !== 'prelast' &&
                place !== 'center' && (
                  <>
                    {players.slice(0, 9).map((player, index) => (
                      <React.Fragment key={player.name}>
                        <p
                          className={`wot-blitz-tournament-widget-table-player-place-${index}`}
                        >
                          {index + 1}
                        </p>
                        <p
                          className={`wot-blitz-tournament-widget-table-player-name-${index}`}
                        >
                          {player.name}
                        </p>
                        <p
                          className={`wot-blitz-tournament-widget-table-player-scores-${index}`}
                        >
                          {player.scores ?? '-'}
                        </p>
                      </React.Fragment>
                    ))}
                  </>
                )}

              {place === 'last' && (
                <>
                  {players.slice(0, 6).map((player, index) => (
                    <React.Fragment key={player.name}>
                      <p
                        className={`wot-blitz-tournament-widget-table-player-place-${index}`}
                      >
                        {index + 1}
                      </p>
                      <p
                        className={`wot-blitz-tournament-widget-table-player-name-${index}`}
                      >
                        {player.name}
                      </p>
                      <p
                        className={`wot-blitz-tournament-widget-table-player-scores-${index}`}
                      >
                        {player.scores ?? '-'}
                      </p>
                    </React.Fragment>
                  ))}

                  <p
                    className={`wot-blitz-tournament-widget-table-player-place-prelast`}
                  >
                    {players.length - 1}
                  </p>
                  <p
                    className={`wot-blitz-tournament-widget-table-player-name-prelast`}
                  >
                    {players[players.length - 2]?.name}
                  </p>
                  <p
                    className={`wot-blitz-tournament-widget-table-player-scores-prelast`}
                  >
                    {players[players.length - 2]?.scores ?? '-'}
                  </p>

                  <p
                    className={`wot-blitz-tournament-widget-table-player-place-last`}
                  >
                    {players.length}
                  </p>
                  <p
                    className={`wot-blitz-tournament-widget-table-player-name-last`}
                  >
                    {players[players.length - 1]?.name}
                  </p>
                  <p
                    className={`wot-blitz-tournament-widget-table-player-scores-last`}
                  >
                    {players[players.length - 1]?.scores ?? '-'}
                  </p>
                </>
              )}

              {place === 'prelast' && (
                <>
                  {players.slice(0, 5).map((player, index) => (
                    <React.Fragment key={player.name}>
                      <p
                        className={`wot-blitz-tournament-widget-table-player-place-${index}`}
                      >
                        {index + 1}
                      </p>
                      <p
                        className={`wot-blitz-tournament-widget-table-player-name-${index}`}
                      >
                        {player.name}
                      </p>
                      <p
                        className={`wot-blitz-tournament-widget-table-player-scores-${index}`}
                      >
                        {player?.scores ?? '-'}
                      </p>
                    </React.Fragment>
                  ))}

                  <p
                    className={`wot-blitz-tournament-widget-table-player-place-preprelast`}
                  >
                    {players.length - 2}
                  </p>
                  <p
                    className={`wot-blitz-tournament-widget-table-player-name-preprelast`}
                  >
                    {players[players.length - 3]?.name}
                  </p>
                  <p
                    className={`wot-blitz-tournament-widget-table-player-scores-preprelast`}
                  >
                    {players[players.length - 3]?.scores ?? '-'}
                  </p>

                  <p
                    className={`wot-blitz-tournament-widget-table-player-place-prelast`}
                  >
                    {players.length - 1}
                  </p>
                  <p
                    className={`wot-blitz-tournament-widget-table-player-name-prelast`}
                  >
                    {players[players.length - 2]?.name}
                  </p>
                  <p
                    className={`wot-blitz-tournament-widget-table-player-scores-prelast`}
                  >
                    {players[players.length - 2]?.scores ?? '-'}
                  </p>

                  <p
                    className={`wot-blitz-tournament-widget-table-player-place-last`}
                  >
                    {players.length}
                  </p>
                  <p
                    className={`wot-blitz-tournament-widget-table-player-name-last`}
                  >
                    {players[players.length - 1]?.name}
                  </p>
                  <p
                    className={`wot-blitz-tournament-widget-table-player-scores-last`}
                  >
                    {players[players.length - 1]?.scores ?? '-'}
                  </p>
                </>
              )}

              {place === 'center' && (
                <>
                  {players.slice(0, 4).map((player, index) => (
                    <React.Fragment key={player.name}>
                      <p
                        className={`wot-blitz-tournament-widget-table-player-place-${index}`}
                      >
                        {index + 1}
                      </p>
                      <p
                        className={`wot-blitz-tournament-widget-table-player-name-${index}`}
                      >
                        {player?.name}
                      </p>
                      <p
                        className={`wot-blitz-tournament-widget-table-player-scores-${index}`}
                      >
                        {player?.scores ?? '-'}
                      </p>
                    </React.Fragment>
                  ))}

                  <p
                    className={`wot-blitz-tournament-widget-table-player-place-precenter`}
                  >
                    {playerIndex}
                  </p>
                  <p
                    className={`wot-blitz-tournament-widget-table-player-name-precenter`}
                  >
                    {players[playerIndex - 1]?.name}
                  </p>
                  <p
                    className={`wot-blitz-tournament-widget-table-player-scores-precenter`}
                  >
                    {players[playerIndex - 1]?.scores ?? '-'}
                  </p>

                  <p
                    className={`wot-blitz-tournament-widget-table-player-place-center`}
                  >
                    {playerIndex + 1}
                  </p>
                  <p
                    className={`wot-blitz-tournament-widget-table-player-name-center`}
                  >
                    {players[playerIndex]?.name}
                  </p>
                  <p
                    className={`wot-blitz-tournament-widget-table-player-scores-center`}
                  >
                    {players[playerIndex]?.scores ?? '-'}
                  </p>

                  <p
                    className={`wot-blitz-tournament-widget-table-player-place-postcenter`}
                  >
                    {playerIndex + 2}
                  </p>
                  <p
                    className={`wot-blitz-tournament-widget-table-player-name-postcenter`}
                  >
                    {players[playerIndex + 1]?.name}
                  </p>
                  <p
                    className={`wot-blitz-tournament-widget-table-player-scores-postcenter`}
                  >
                    {players[playerIndex + 1]?.scores ?? '-'}
                  </p>
                </>
              )}
            </>
          )}
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}
