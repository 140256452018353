import fetchApi from './fetch-api';
import { components, paths } from './interfaces/api-interfase';

export type SettingsDto = components['schemas']['SettingsDto'];

export class SettingsApiService {
  async get(): Promise<
    paths['/api/settings']['get']['responses']['200']['content']['application/json']
  > {
    const response = await fetchApi('settings', {
      method: 'GET',
    });

    const body =
      (await response.json()) as paths['/api/settings']['get']['responses']['200']['content']['application/json'];

    return body;
  }

  async update(
    payload: paths['/api/settings']['put']['requestBody']['content']['application/json'],
  ): Promise<
    paths['/api/settings']['put']['responses']['200']['content']['application/json']
  > {
    const response = await fetchApi(`settings`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body =
      (await response.json()) as paths['/api/settings']['put']['responses']['200']['content']['application/json'];

    return body;
  }
}
