import { Hours } from '../../utils/hours-utils';
import { TankTiers } from '../../utils/tank-tiers';
import { TankTypes } from '../../utils/tank-types';
import fetchApi from './fetch-api';
import { components, paths } from './interfaces/api-interfase';

export type WotTournamentShortDto =
  components['schemas']['WotTournamentShortDto'];
export type IWotTournamentDto = components['schemas']['WotTournamentDto'];
export type IWotTournamentRulesDto =
  components['schemas']['WotTournamentRulesDto'];
export type IWotTournamentRulesBattleHoursDto =
  components['schemas']['WotTournamentRulesBattleHoursDto'];
export type IWotTournamentRulesTankTiersDto =
  components['schemas']['WotTournamentRulesTankTiersDto'];
export type IWotTournamentRulesTankTypesDto =
  components['schemas']['WotTournamentRulesTankTypesDto'];
export type IWotTournamentAccountDto =
  components['schemas']['WotTournamentAccountDto'];
export type IWotTournamentAccountBattleDto =
  components['schemas']['WotTournamentAccountBattleDto'];
export type IWotTournamentAccountUpsertBattleDto =
  components['schemas']['WotTournamentAccountUpsertBattleDto'];
export type IWotTournamentAccountDeletedBattleDto =
  components['schemas']['WotTournamentAccountDeletedBattleDto'];
export type IWotTournamentAccountBonusDto =
  components['schemas']['WotTournamentAccountBonusDto'];
export type IWotTournamentAccountFullDto =
  components['schemas']['WotTournamentAccountFullDto'];
export type IWotTournamentWithAccountsDto =
  components['schemas']['WotTournamentWithAccountsDto'];
export type IWotTournamentTeamDto =
  components['schemas']['WotTournamentTeamDto'];

export class WotTournamentRulesBattleHoursDtoAdapter
  implements IWotTournamentRulesBattleHoursDto
{
  '00': boolean | null;
  '01': boolean | null;
  '02': boolean | null;
  '03': boolean | null;
  '04': boolean | null;
  '05': boolean | null;
  '06': boolean | null;
  '07': boolean | null;
  '08': boolean | null;
  '09': boolean | null;
  '10': boolean | null;
  '11': boolean | null;
  '12': boolean | null;
  '13': boolean | null;
  '14': boolean | null;
  '15': boolean | null;
  '16': boolean | null;
  '17': boolean | null;
  '18': boolean | null;
  '19': boolean | null;
  '20': boolean | null;
  '21': boolean | null;
  '22': boolean | null;
  '23': boolean | null;

  constructor(data: Record<Hours, boolean | null> | undefined) {
    // shift from +3 to UTC
    this['00'] = data?.['03'] ?? true;
    this['01'] = data?.['04'] ?? true;
    this['02'] = data?.['05'] ?? true;
    this['03'] = data?.['06'] ?? true;
    this['04'] = data?.['07'] ?? true;
    this['05'] = data?.['08'] ?? true;
    this['06'] = data?.['09'] ?? true;
    this['07'] = data?.['10'] ?? true;
    this['08'] = data?.['11'] ?? true;
    this['09'] = data?.['12'] ?? true;
    this['10'] = data?.['13'] ?? true;
    this['11'] = data?.['14'] ?? true;
    this['12'] = data?.['15'] ?? true;
    this['13'] = data?.['16'] ?? true;
    this['14'] = data?.['17'] ?? true;
    this['15'] = data?.['18'] ?? true;
    this['16'] = data?.['19'] ?? true;
    this['17'] = data?.['20'] ?? true;
    this['18'] = data?.['21'] ?? true;
    this['19'] = data?.['22'] ?? true;
    this['20'] = data?.['23'] ?? true;
    this['21'] = data?.['00'] ?? true;
    this['22'] = data?.['01'] ?? true;
    this['23'] = data?.['02'] ?? true;
  }

  public static convertToHours(
    dto: IWotTournamentRulesBattleHoursDto | null,
  ): Record<Hours, boolean | null> {
    // switch from UTC to +3
    return {
      '00': dto?.['21'] ?? true,
      '01': dto?.['22'] ?? true,
      '02': dto?.['23'] ?? true,
      '03': dto?.['00'] ?? true,
      '04': dto?.['01'] ?? true,
      '05': dto?.['02'] ?? true,
      '06': dto?.['03'] ?? true,
      '07': dto?.['04'] ?? true,
      '08': dto?.['05'] ?? true,
      '09': dto?.['06'] ?? true,
      '10': dto?.['07'] ?? true,
      '11': dto?.['08'] ?? true,
      '12': dto?.['09'] ?? true,
      '13': dto?.['10'] ?? true,
      '14': dto?.['11'] ?? true,
      '15': dto?.['12'] ?? true,
      '16': dto?.['13'] ?? true,
      '17': dto?.['14'] ?? true,
      '18': dto?.['15'] ?? true,
      '19': dto?.['16'] ?? true,
      '20': dto?.['17'] ?? true,
      '21': dto?.['18'] ?? true,
      '22': dto?.['19'] ?? true,
      '23': dto?.['20'] ?? true,
    };
  }
}

export class WotTournamentRulesTankTiersDtoAdapter
  implements IWotTournamentRulesTankTiersDto
{
  '01': boolean | null;
  '02': boolean | null;
  '03': boolean | null;
  '04': boolean | null;
  '05': boolean | null;
  '06': boolean | null;
  '07': boolean | null;
  '08': boolean | null;
  '09': boolean | null;
  '10': boolean | null;

  constructor(data: Record<TankTiers, boolean | null> | undefined) {
    this['01'] = data?.['01'] ?? true;
    this['02'] = data?.['02'] ?? true;
    this['03'] = data?.['03'] ?? true;
    this['04'] = data?.['04'] ?? true;
    this['05'] = data?.['05'] ?? true;
    this['06'] = data?.['06'] ?? true;
    this['07'] = data?.['07'] ?? true;
    this['08'] = data?.['08'] ?? true;
    this['09'] = data?.['09'] ?? true;
    this['10'] = data?.['10'] ?? true;
  }
}

export class WotTournamentRulesTankTypesDtoAdapter
  implements IWotTournamentRulesTankTypesDto
{
  'heavyTank': boolean | null;
  'mediumTank': boolean | null;
  'lightTank': boolean | null;
  'AT-SPG': boolean | null;
  'SPG': boolean | null;

  constructor(data: Record<TankTypes, boolean | null> | undefined) {
    this['heavyTank'] = data?.['heavyTank'] ?? true;
    this['mediumTank'] = data?.['mediumTank'] ?? true;
    this['lightTank'] = data?.['lightTank'] ?? true;
    this['AT-SPG'] = data?.['AT-SPG'] ?? true;
    this['SPG'] = data?.['SPG'] ?? true;
  }
}

export class WotTournamentsApiService {
  async getAll(): Promise<
    paths['/api/wot/tournaments']['get']['responses']['200']['content']['application/json']
  > {
    const response = await fetchApi('wot/tournaments', {
      method: 'GET',
    });

    const body =
      (await response.json()) as paths['/api/wot/tournaments']['get']['responses']['200']['content']['application/json'];

    return body;
  }

  async getById(
    params: paths['/api/wot/tournaments/{tournamentId}']['get']['parameters']['path'],
    query: paths['/api/wot/tournaments/{tournamentId}']['get']['parameters']['query'],
  ): Promise<
    paths['/api/wot/tournaments/{tournamentId}']['get']['responses']['200']['content']['application/json']
  > {
    let url = `wot/tournaments/${params.tournamentId}?`;
    if (query?.cache != null) {
      url += `cache=${query.cache}`;
    }

    const response = await fetchApi(url, {
      method: 'GET',
    });

    const body =
      (await response.json()) as paths['/api/wot/tournaments/{tournamentId}']['get']['responses']['200']['content']['application/json'];

    return body;
  }

  async getByIdPublic(
    params: paths['/api/wot/tournaments/{tournamentId}/public']['get']['parameters']['path'],
  ): Promise<
    paths['/api/wot/tournaments/{tournamentId}/public']['get']['responses']['200']['content']['application/json']
  > {
    const response = await fetchApi(
      `wot/tournaments/${params.tournamentId}/public`,
      {
        method: 'GET',
      },
    );

    const body =
      (await response.json()) as paths['/api/wot/tournaments/{tournamentId}/public']['get']['responses']['200']['content']['application/json'];

    return body;
  }

  async create(
    payload: paths['/api/wot/tournaments']['post']['requestBody']['content']['application/json'],
  ): Promise<
    paths['/api/wot/tournaments']['post']['responses']['200']['content']['application/json']
  > {
    const response = await fetchApi(`wot/tournaments`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body =
      (await response.json()) as paths['/api/wot/tournaments']['post']['responses']['200']['content']['application/json'];

    return body;
  }

  async update(
    params: paths['/api/wot/tournaments/{tournamentId}']['put']['parameters']['path'],
    payload: paths['/api/wot/tournaments/{tournamentId}']['put']['requestBody']['content']['application/json'],
  ): Promise<
    paths['/api/wot/tournaments/{tournamentId}']['put']['responses']['200']['content']['application/json']
  > {
    const response = await fetchApi(`wot/tournaments/${params.tournamentId}`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body =
      (await response.json()) as paths['/api/wot/tournaments/{tournamentId}']['put']['responses']['200']['content']['application/json'];

    return body;
  }

  async delete(
    params: paths['/api/wot/tournaments/{tournamentId}']['delete']['parameters']['path'],
  ): Promise<
    paths['/api/wot/tournaments/{tournamentId}']['delete']['responses']['200']['content']['application/json']
  > {
    const response = await fetchApi(`wot/tournaments/${params.tournamentId}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body =
      (await response.json()) as paths['/api/wot/tournaments/{tournamentId}']['delete']['responses']['200']['content']['application/json'];

    return body;
  }

  async getAccount(
    params: paths['/api/wot/tournaments/{tournamentId}/accounts/{accountId}']['get']['parameters']['path'],
    query: paths['/api/wot/tournaments/{tournamentId}/accounts/{accountId}']['get']['parameters']['query'],
  ): Promise<
    paths['/api/wot/tournaments/{tournamentId}/accounts/{accountId}']['get']['responses']['200']['content']['application/json']
  > {
    let url = `wot/tournaments/${params.tournamentId}/accounts/${params.accountId}?`;
    if (query?.cache != null) {
      url += `cache=${query.cache}`;
    }

    const response = await fetchApi(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body =
      (await response.json()) as paths['/api/wot/tournaments/{tournamentId}/accounts/{accountId}']['get']['responses']['200']['content']['application/json'];

    return body;
  }

  async getAccountPublic(
    params: paths['/api/wot/tournaments/{tournamentId}/accounts/public']['get']['parameters']['path'],
    query: paths['/api/wot/tournaments/{tournamentId}/accounts/public']['get']['parameters']['query'],
  ): Promise<
    paths['/api/wot/tournaments/{tournamentId}/accounts/public']['get']['responses']['200']['content']['application/json']
  > {
    let url = `wot/tournaments/${params.tournamentId}/accounts/public?`;
    if (query?.id) {
      url += `id=${query.id}&`;
    }
    if (query?.wotId) {
      url += `wotId=${query.wotId}&`;
    }
    if (query?.server) {
      url += `server=${query.server}&`;
    }
    if (query?.filterNoResults != null) {
      url += `filterNoResults=${query.filterNoResults}`;
    }

    const response = await fetchApi(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body =
      (await response.json()) as paths['/api/wot/tournaments/{tournamentId}/accounts/public']['get']['responses']['200']['content']['application/json'];

    return body;
  }

  async addAccount(
    params: paths['/api/wot/tournaments/{tournamentId}/accounts']['post']['parameters']['path'],
    payload: paths['/api/wot/tournaments/{tournamentId}/accounts']['post']['requestBody']['content']['application/json'],
  ): Promise<
    paths['/api/wot/tournaments/{tournamentId}/accounts']['post']['responses']['200']['content']['application/json']
  > {
    const response = await fetchApi(
      `wot/tournaments/${params.tournamentId}/accounts`,
      {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );

    const body =
      (await response.json()) as paths['/api/wot/tournaments/{tournamentId}/accounts']['post']['responses']['200']['content']['application/json'];

    return body;
  }

  async updateAccount(
    params: paths['/api/wot/tournaments/{tournamentId}/accounts/{accountId}']['put']['parameters']['path'],
    payload: paths['/api/wot/tournaments/{tournamentId}/accounts/{accountId}']['put']['requestBody']['content']['application/json'],
  ): Promise<
    paths['/api/wot/tournaments/{tournamentId}/accounts/{accountId}']['put']['responses']['200']['content']['application/json']
  > {
    const response = await fetchApi(
      `wot/tournaments/${params.tournamentId}/accounts/${params.accountId}`,
      {
        method: 'PUT',
        body: JSON.stringify(payload),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );

    const body =
      (await response.json()) as paths['/api/wot/tournaments/{tournamentId}/accounts/{accountId}']['put']['responses']['200']['content']['application/json'];

    return body;
  }

  async deleteAccount(
    params: paths['/api/wot/tournaments/{tournamentId}/accounts']['delete']['parameters']['path'],
    query: paths['/api/wot/tournaments/{tournamentId}/accounts']['delete']['parameters']['query'],
  ): Promise<
    paths['/api/wot/tournaments/{tournamentId}/accounts']['delete']['responses']['200']['content']['application/json']
  > {
    let url = `wot/tournaments/${params.tournamentId}/accounts?`;
    if (query?.id) {
      url += `id=${query?.id}&`;
    }
    if (query?.wotId) {
      url += `wotId=${query?.wotId}&`;
    }
    if (query?.server) {
      url += `server=${query?.server}&`;
    }
    if (query?.force) {
      url += `force=${query?.force}&`;
    }

    const response = await fetchApi(url, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body =
      (await response.json()) as paths['/api/wot/tournaments/{tournamentId}/accounts']['delete']['responses']['200']['content']['application/json'];

    return body;
  }

  async resetAccount(
    params: paths['/api/wot/tournaments/{tournamentId}/accounts/reset']['post']['parameters']['path'],
    query: paths['/api/wot/tournaments/{tournamentId}/accounts/reset']['post']['parameters']['query'],
  ): Promise<
    paths['/api/wot/tournaments/{tournamentId}/accounts/reset']['post']['responses']['200']['content']['application/json']
  > {
    let url = `wot/tournaments/${params.tournamentId}/accounts/reset?`;
    if (query?.id) {
      url += `id=${query?.id}&`;
    }
    if (query?.wotId) {
      url += `wotId=${query?.wotId}&`;
    }
    if (query?.server) {
      url += `server=${query?.server}&`;
    }
    if (query?.force) {
      url += `force=${query?.force}&`;
    }

    const response = await fetchApi(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body =
      (await response.json()) as paths['/api/wot/tournaments/{tournamentId}/accounts/reset']['post']['responses']['200']['content']['application/json'];

    return body;
  }

  async clearAccountResets(
    params: paths['/api/wot/tournaments/{tournamentId}/accounts/reset']['delete']['parameters']['path'],
    query: paths['/api/wot/tournaments/{tournamentId}/accounts/reset']['delete']['parameters']['query'],
  ): Promise<
    paths['/api/wot/tournaments/{tournamentId}/accounts/reset']['delete']['responses']['200']['content']['application/json']
  > {
    let url = `wot/tournaments/${params.tournamentId}/accounts/reset?`;
    if (query?.id) {
      url += `id=${query?.id}&`;
    }
    if (query?.wotId) {
      url += `wotId=${query?.wotId}&`;
    }
    if (query?.server) {
      url += `server=${query?.server}&`;
    }

    const response = await fetchApi(url, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body =
      (await response.json()) as paths['/api/wot/tournaments/{tournamentId}/accounts/reset']['delete']['responses']['200']['content']['application/json'];

    return body;
  }

  async addAccountBonus(
    params: paths['/api/wot/tournaments/{tournamentId}/accounts/{accountId}/bonuses']['post']['parameters']['path'],
    payload: paths['/api/wot/tournaments/{tournamentId}/accounts/{accountId}/bonuses']['post']['requestBody']['content']['application/json'],
  ): Promise<
    paths['/api/wot/tournaments/{tournamentId}/accounts/{accountId}/bonuses']['post']['responses']['200']['content']['application/json']
  > {
    const response = await fetchApi(
      `wot/tournaments/${params.tournamentId}/accounts/${params.accountId}/bonuses`,
      {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );

    const body =
      (await response.json()) as paths['/api/wot/tournaments/{tournamentId}/accounts/{accountId}/bonuses']['post']['responses']['200']['content']['application/json'];

    return body;
  }

  async deleteAccountBonus(
    params: paths['/api/wot/tournaments/{tournamentId}/accounts/{accountId}/bonuses/{bonusId}']['delete']['parameters']['path'],
  ): Promise<
    paths['/api/wot/tournaments/{tournamentId}/accounts/{accountId}/bonuses/{bonusId}']['delete']['responses']['200']['content']['application/json']
  > {
    const response = await fetchApi(
      `wot/tournaments/${params.tournamentId}/accounts/${params.accountId}/bonuses/${params.bonusId}`,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );

    const body =
      (await response.json()) as paths['/api/wot/tournaments/{tournamentId}/accounts/{accountId}/bonuses/{bonusId}']['delete']['responses']['200']['content']['application/json'];

    return body;
  }

  async deleteAccountBattle(
    params: paths['/api/wot/tournaments/{tournamentId}/accounts/{accountId}/battles/{battleId}']['delete']['parameters']['path'],
    query: paths['/api/wot/tournaments/{tournamentId}/accounts/{accountId}/battles/{battleId}']['delete']['parameters']['query'],
  ): Promise<
    paths['/api/wot/tournaments/{tournamentId}/accounts/{accountId}/battles/{battleId}']['delete']['responses']['200']['content']['application/json']
  > {
    const response = await fetchApi(
      `wot/tournaments/${params.tournamentId}/accounts/${
        params.accountId
      }/battles/${params.battleId}?silent=${query?.silent ?? false}&force=${
        query?.force ?? false
      }`,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );

    const body =
      (await response.json()) as paths['/api/wot/tournaments/{tournamentId}/accounts/{accountId}/battles/{battleId}']['delete']['responses']['200']['content']['application/json'];

    return body;
  }

  async restoreAccountBattle(
    params: paths['/api/wot/tournaments/{tournamentId}/accounts/{accountId}/battles/{battleId}']['put']['parameters']['path'],
  ): Promise<
    paths['/api/wot/tournaments/{tournamentId}/accounts/{accountId}/battles/{battleId}']['put']['responses']['200']['content']['application/json']
  > {
    const response = await fetchApi(
      `wot/tournaments/${params.tournamentId}/accounts/${params.accountId}/battles/${params.battleId}`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );

    const body =
      (await response.json()) as paths['/api/wot/tournaments/{tournamentId}/accounts/{accountId}/battles/{battleId}']['put']['responses']['200']['content']['application/json'];

    return body;
  }

  async addAccountBattle(
    params: paths['/api/wot/tournaments/{tournamentId}/accounts/{accountId}/battles']['post']['parameters']['path'],
    payload: paths['/api/wot/tournaments/{tournamentId}/accounts/{accountId}/battles']['post']['requestBody']['content']['application/json'],
  ): Promise<
    paths['/api/wot/tournaments/{tournamentId}/accounts/{accountId}/battles']['post']['responses']['200']['content']['application/json']
  > {
    const response = await fetchApi(
      `wot/tournaments/${params.tournamentId}/accounts/${params.accountId}/battles`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      },
    );

    const body =
      (await response.json()) as paths['/api/wot/tournaments/{tournamentId}/accounts/{accountId}/battles']['post']['responses']['200']['content']['application/json'];

    return body;
  }
}
