import { Alert, Button, Col, Form, InputGroup } from 'react-bootstrap';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { selectWotTournament } from '../../redux/wot-tournament-slice';

export function WotTournamentAccountsTabHeader(props: {
  pageSize: number;
  page: number;
  onPageChange: (page: number) => void;

  filter: string;
  onFilterChange: (filter: string) => void;

  disabledAccountAdding: boolean;
  onAccountAddClick: () => void;
}) {
  const tournament = useSelector(selectWotTournament);

  return (
    <>
      <Col>
        {tournament == null && (
          <Alert variant="warning">
            {t(
              'WotTournamentAccountsTab.NoTournamentAlert',
              'Please select or save a tournament first',
            )}
          </Alert>
        )}
      </Col>

      <Col>
        <div className="filter-container mt-2">
          <div>
            <Form.Control
              className="standard-input-width"
              value={props.filter ?? ''}
              onChange={(event) => props.onFilterChange(event.target.value)}
              placeholder={t(
                'WotTournamentAccountsTab.FilterName',
                'Filte by name',
              )}
            />
          </div>

          <Button
            variant="primary"
            className="mb-3"
            onClick={() => props.onAccountAddClick()}
            disabled={tournament == null || props.disabledAccountAdding}
          >
            {t('WotTournamentAccountsTab.ButtonAdd', 'Add account')}
          </Button>

          <div>
            <InputGroup>
              <Button
                variant="outline-secondary"
                disabled={props.page === 1}
                onClick={() => {
                  if (props.page > 1) {
                    props.onPageChange(props.page - 1);
                  }
                }}
              >
                {'<'}
              </Button>

              <Form.Control
                value={props.page}
                disabled
                className="table-page-input"
              />

              <Button
                variant="outline-secondary"
                disabled={
                  props.page * props.pageSize >=
                  (tournament?.accounts.length ?? 0)
                }
                onClick={() => {
                  if (
                    props.page * props.pageSize <
                    (tournament?.accounts.length ?? 0)
                  ) {
                    props.onPageChange(props.page + 1);
                  }
                }}
              >
                {'>'}
              </Button>
            </InputGroup>
          </div>
        </div>
      </Col>
    </>
  );
}
