import { useEffect, useState } from 'react';
import {
  Accordion,
  Alert,
  Button,
  Col,
  Form,
  Row,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  createWotBlitzTournamentAsync,
  selectWotBlitzTournamentItem,
  updateWotBlitzTournamentAsync,
} from '../wot-blitz-tournaments-slice';
import { WotBlitzTournamentStatus } from '../../../../../services/api/interfaces/common-api-interfase';
import DatePicker from 'react-datepicker';
import {
  LoadingParts,
  selectLoadingState,
} from '../../../loading/loadingSlice';
import { useAppDispatch } from '../../../../../redux/store';
import { toRoman } from '../../../../../utils/number.util';

const WotTournamentTeamSize = 2;

export function WotBlitzTournamentGeneralTab() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const tournament = useSelector(selectWotBlitzTournamentItem);
  const loading = useSelector(selectLoadingState);

  const [name, setName] = useState('');
  const [status, setStatus] = useState<WotBlitzTournamentStatus>('Private');
  const [startAt, setStartAt] = useState<string | undefined>(
    new Date().toISOString(),
  );
  const [endAt, setEndAt] = useState<string | undefined>(
    new Date(Date.now() + 1000 * 60 * 60 * 24 * 7).toISOString(),
  );

  const [bestBattlesEnabled, setBestBattlesEnabled] = useState<boolean>(true);
  const [bestBattlesCount, setBestBattlesCount] = useState<number | undefined>(
    10,
  );

  const [battleMultiplier, setBattleMultiplier] = useState<number | undefined>(
    0,
  );
  const [winMultiplier, setWinMultiplier] = useState<number | undefined>(2000);
  const [fragMultiplier, setFragMultiplier] = useState<number | undefined>(300);
  const [damageMultiplier, setDamageMultiplier] = useState<number | undefined>(
    1,
  );

  const [tanksTierFilter, setTanksTierFilter] = useState<
    Array<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10>
  >([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);

  const [tanksTypeFilter, setTanksTypeFilter] = useState<
    Array<'heavyTank' | 'mediumTank' | 'lightTank' | 'AT-SPG'>
  >(['heavyTank', 'mediumTank', 'lightTank', 'AT-SPG']);

  const [mode, setMode] = useState<'individual' | 'team'>('individual');
  const [onlyPlatoon, setOnlyPlatoon] = useState<boolean>(false);
  const [onlyRegularTanks, setOnlyRegularTanks] = useState<boolean>(true);

  useEffect(() => {
    if (tournament == null) {
      return;
    }

    setName(tournament.name);
    setStatus(tournament.status);
    setStartAt(tournament.startAt);
    setEndAt(tournament.endAt);
    setBestBattlesEnabled(tournament.rules.bestBattlesCount != null);
    if (tournament.rules.bestBattlesCount != null) {
      setBestBattlesCount(tournament.rules.bestBattlesCount);
    }
    setBattleMultiplier(tournament.rules.battleMultiplier);
    setWinMultiplier(tournament.rules.winMultiplier);
    setFragMultiplier(tournament.rules.fragMultiplier);
    setDamageMultiplier(tournament.rules.damageMultiplier);
    if (tournament.rules.tankTiers != null) {
      setTanksTierFilter(tournament.rules.tankTiers);
    }
    if (tournament.rules.tankTypes != null) {
      setTanksTypeFilter(tournament.rules.tankTypes);
    }
    setMode(tournament.rules.mode);
    setOnlyPlatoon(tournament.rules.onlyPlatoon ?? false);
    setOnlyRegularTanks(tournament.rules.onlyRegularTanks ?? false);
  }, [tournament]);

  const isNameValid = () => name != null && name.length > 0;
  const isStartAtValid = () =>
    startAt != null && startAt.length > 0 && startAt <= (endAt ?? '');
  const isEndAtValid = () =>
    endAt != null && endAt.length > 0 && endAt >= (startAt ?? '');
  const isBestBattlesCountValid = () =>
    bestBattlesEnabled === false ||
    (bestBattlesCount != null && bestBattlesCount > 0);
  const isBattleMultiplierValid = () =>
    battleMultiplier != null && battleMultiplier >= 0;
  const isWinMultiplierValid = () =>
    winMultiplier != null && winMultiplier >= 0;
  const isFragMultiplierValid = () =>
    fragMultiplier != null && fragMultiplier >= 0;
  const isDamageMultiplierValid = () =>
    damageMultiplier != null && damageMultiplier >= 0;
  const isTanksTierFilterValid = () => tanksTierFilter?.length > 0;
  const isTanksTypeFilterValid = () => tanksTypeFilter?.length > 0;

  const handleSave = () => {
    if (tournament?.id == null) {
      dispatch(
        createWotBlitzTournamentAsync(
          name,
          new Date(startAt as string),
          new Date(endAt as string),
          status,
          {
            battleMultiplier: battleMultiplier ?? 0,
            winMultiplier: winMultiplier ?? 0,
            fragMultiplier: fragMultiplier ?? 0,
            damageMultiplier: damageMultiplier ?? 0,
            bestBattlesCount: bestBattlesEnabled
              ? (bestBattlesCount ?? 0)
              : null,
            tankTiers: tanksTierFilter,
            tankTypes: tanksTypeFilter,
            mode,
            teamSize: tournament?.rules.teamSize ?? WotTournamentTeamSize,
            onlyPlatoon,
            onlyRegularTanks,
          },
        ),
      );
    } else {
      dispatch(
        updateWotBlitzTournamentAsync(
          tournament.id,
          name,
          new Date(startAt as string),
          new Date(endAt as string),
          status,
          {
            battleMultiplier: battleMultiplier ?? 0,
            winMultiplier: winMultiplier ?? 0,
            fragMultiplier: fragMultiplier ?? 0,
            damageMultiplier: damageMultiplier ?? 0,
            bestBattlesCount: bestBattlesEnabled
              ? (bestBattlesCount ?? 0)
              : null,
            tankTiers: tanksTierFilter,
            tankTypes: tanksTypeFilter,
            mode,
            teamSize: tournament?.rules.teamSize ?? WotTournamentTeamSize,
            onlyPlatoon,
            onlyRegularTanks,
          },
        ),
      );
    }
  };

  return (
    <>
      <Form className="p-2">
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2}>
            {t('WotBlitzTournament.Name', 'Name')}
          </Form.Label>

          <Col sm={10}>
            <Form.Control
              className="standard-input-width"
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
              isInvalid={!isNameValid()}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2}>
            {t('WotBlitzTournament.Status', 'Status')}
          </Form.Label>

          <Col sm={10}>
            <Form.Select
              className="standard-input-width"
              onChange={(event) =>
                setStatus(event.target.value as WotBlitzTournamentStatus)
              }
              value={status}
            >
              <option value="Public">Public</option>
              <option value="Private">Private</option>
            </Form.Select>
            <Form.Text className="text-muted">
              {t(
                'WotBlitzTournament.StatusNote',
                'Private tournaments are not visible to other users.',
              )}
            </Form.Text>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2}>
            {t('WotBlitzTournament.StartDate', 'Start date')}
          </Form.Label>

          <Col sm={10}>
            <div>
              <DatePicker
                className="standard-input-width"
                showTimeSelect
                selected={startAt == null ? undefined : new Date(startAt)}
                onChange={(date) => {
                  setStartAt(date?.toISOString());
                }}
                dateFormat="MMMM d, yyyy HH:mm"
              />
            </div>

            <Form.Text className="text-muted">
              {t(
                'WotBlitzTournament.DateNote',
                'The date is in your LOCAL timezone.',
              )}
            </Form.Text>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2}>
            {t('WotBlitzTournament.EndDate', 'End date')}
          </Form.Label>

          <Col sm={10}>
            <div>
              <DatePicker
                className="standard-input-width"
                showTimeSelect
                selected={endAt == null ? undefined : new Date(endAt)}
                onChange={(date) => {
                  setEndAt(date?.toISOString());
                }}
                dateFormat="MMMM d, yyyy HH:mm"
              />
            </div>

            <Form.Text className="text-muted">
              {t(
                'WotBlitzTournament.DateNote',
                'The date is in your LOCAL timezone.',
              )}
            </Form.Text>
          </Col>
        </Form.Group>

        <Accordion alwaysOpen>
          <Accordion.Item eventKey="rules">
            <Accordion.Header>
              {t('WotBlitzTournament.RulesHeader', 'Rules')}
            </Accordion.Header>
            <Accordion.Body>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  {t('WotBlitzTournament.Mode', 'Mode')}
                </Form.Label>

                <Col sm={10}>
                  <Form.Select
                    className="standard-input-width"
                    onChange={(event) => {
                      setMode(
                        event.target.value as unknown as 'individual' | 'team',
                      );
                      if (event.target.value === 'individual') {
                        setOnlyPlatoon(false);
                      }
                    }}
                    value={mode}
                  >
                    <option value="individual">Individual</option>
                    <option value="team">Team</option>
                  </Form.Select>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  {t('WotBlitzTournament.OnlyPlatoon', 'Only platoon')}
                </Form.Label>

                <Col sm={10}>
                  <Form.Check
                    type="switch"
                    label={
                      onlyPlatoon
                        ? t('WotBlitzTournament.OnlyPlatoonEnabled', 'Enabled')
                        : t(
                            'WotBlitzTournament.OnlyPlatoonDisabled',
                            'Disabled',
                          )
                    }
                    checked={onlyPlatoon}
                    onChange={(event) => {
                      setOnlyPlatoon(event.target.checked);
                    }}
                    disabled={mode === 'individual'}
                  />
                </Col>
              </Form.Group>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="multipliers">
            <Accordion.Header>
              {t('WotBlitzTournament.MultipliersHeader', 'Multipliers')}
            </Accordion.Header>
            <Accordion.Body>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  {t(
                    'WotBlitzTournament.BattleMultiplier',
                    'Battle multiplier',
                  )}
                </Form.Label>

                <Col sm={10}>
                  <Form.Control
                    className="standard-input-width"
                    type="number"
                    min={0}
                    value={battleMultiplier}
                    onChange={(event) => {
                      setBattleMultiplier(
                        event.target.value === ''
                          ? undefined
                          : +event.target.value,
                      );
                    }}
                    isInvalid={!isBattleMultiplierValid()}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  {t('WotBlitzTournament.WinMultiplier', 'Win multiplier')}
                </Form.Label>

                <Col sm={10}>
                  <Form.Control
                    className="standard-input-width"
                    type="number"
                    min={0}
                    value={winMultiplier}
                    onChange={(event) => {
                      setWinMultiplier(
                        event.target.value === ''
                          ? undefined
                          : +event.target.value,
                      );
                    }}
                    isInvalid={!isWinMultiplierValid()}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  {t('WotBlitzTournament.FragMultiplier', 'Frag multiplier')}
                </Form.Label>

                <Col sm={10}>
                  <Form.Control
                    className="standard-input-width"
                    type="number"
                    min={0}
                    value={fragMultiplier}
                    onChange={(event) => {
                      setFragMultiplier(
                        event.target.value === ''
                          ? undefined
                          : +event.target.value,
                      );
                    }}
                    isInvalid={!isFragMultiplierValid()}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  {t(
                    'WotBlitzTournament.DamageMultiplier',
                    'Damage multiplier',
                  )}
                </Form.Label>

                <Col sm={10}>
                  <Form.Control
                    className="standard-input-width"
                    type="number"
                    min={0}
                    value={damageMultiplier}
                    onChange={(event) => {
                      setDamageMultiplier(
                        event.target.value === ''
                          ? undefined
                          : +event.target.value,
                      );
                    }}
                    isInvalid={!isDamageMultiplierValid()}
                  />
                </Col>
              </Form.Group>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="filters">
            <Accordion.Header>
              {t('WotBlitzTournament.FiltersHeader', 'Filters')}
            </Accordion.Header>
            <Accordion.Body>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  {t('WotBlitzTournament.BestBattles', 'Best battles')}
                </Form.Label>

                <Col sm={10}>
                  <Stack direction="horizontal" gap={3}>
                    <Form.Check
                      type="switch"
                      label={
                        bestBattlesEnabled
                          ? t(
                              'WotBlitzTournament.BestBattlesEnabled',
                              'Enabled',
                            )
                          : t(
                              'WotBlitzTournament.BestBattlesDisabled',
                              'Disabled',
                            )
                      }
                      checked={bestBattlesEnabled}
                      onChange={(event) => {
                        setBestBattlesEnabled(event.target.checked);
                      }}
                    />
                    <Form.Control
                      className="standard-input-width"
                      type="number"
                      min={1}
                      value={bestBattlesEnabled ? bestBattlesCount : ''}
                      onChange={(event) => {
                        setBestBattlesCount(
                          event.target.value === ''
                            ? undefined
                            : +event.target.value,
                        );
                      }}
                      isInvalid={!isBestBattlesCountValid()}
                      disabled={!bestBattlesEnabled}
                    />
                  </Stack>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  {t('WotBlitzTournament.TanksTierFilter', 'Tanks tier')}
                </Form.Label>

                <Col sm={10}>
                  <ToggleButtonGroup
                    type="checkbox"
                    value={tanksTierFilter}
                    onChange={(value) => setTanksTierFilter(value)}
                  >
                    <ToggleButton
                      id="tbg-btn-tier-1"
                      value={1}
                      variant="outline-secondary"
                    >
                      <span>I</span>
                    </ToggleButton>
                    <ToggleButton
                      id="tbg-btn-tier-2"
                      value={2}
                      variant="outline-secondary"
                    >
                      <span>II</span>
                    </ToggleButton>
                    <ToggleButton
                      id="tbg-btn-tier-3"
                      value={3}
                      variant="outline-secondary"
                    >
                      <span>III</span>
                    </ToggleButton>
                    <ToggleButton
                      id="tbg-btn-tier-4"
                      value={4}
                      variant="outline-secondary"
                    >
                      <span>IV</span>
                    </ToggleButton>
                    <ToggleButton
                      id="tbg-btn-tier-5"
                      value={5}
                      variant="outline-secondary"
                    >
                      <span> V</span>
                    </ToggleButton>
                    <ToggleButton
                      id="tbg-btn-tier-6"
                      value={6}
                      variant="outline-secondary"
                    >
                      <span>VI</span>
                    </ToggleButton>
                    <ToggleButton
                      id="tbg-btn-tier-7"
                      value={7}
                      variant="outline-secondary"
                    >
                      <span>VII</span>
                    </ToggleButton>
                    <ToggleButton
                      id="tbg-btn-tier-8"
                      value={8}
                      variant="outline-secondary"
                    >
                      <span>VIII</span>
                    </ToggleButton>
                    <ToggleButton
                      id="tbg-btn-tier-9"
                      value={9}
                      variant="outline-secondary"
                    >
                      <span>IX</span>
                    </ToggleButton>
                    <ToggleButton
                      id="tbg-btn-tier-10"
                      value={10}
                      variant="outline-secondary"
                    >
                      <span>X</span>
                    </ToggleButton>
                  </ToggleButtonGroup>

                  <p>
                    {isTanksTierFilterValid() ? (
                      <Form.Text className="text-muted">
                        {t(
                          'WotBlitzTournament.SelectedTankTierNote',
                          'You have selected: {{tankTiers}} tanks tiers',
                          {
                            tankTiers: tanksTierFilter
                              .map((x) => toRoman(x))
                              .join(', '),
                          },
                        )}
                      </Form.Text>
                    ) : (
                      <Alert variant="danger" className="mt-2 pt-1 pb-1">
                        {t(
                          'WotBlitzTournament.TanksTierFilterAlert',
                          'You have disabled all tank tiers. At least one tier must be selected.',
                        )}
                      </Alert>
                    )}
                  </p>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  {t('WotBlitzTournament.TanksTypeFilter', 'Tanks type')}
                </Form.Label>

                <Col sm={10}>
                  <ToggleButtonGroup
                    type="checkbox"
                    value={tanksTypeFilter}
                    onChange={(value) => setTanksTypeFilter(value)}
                  >
                    <ToggleButton
                      id="tbg-btn-type-1"
                      value={'heavyTank'}
                      variant="outline-secondary"
                    >
                      <span>Heavy tank</span>
                    </ToggleButton>
                    <ToggleButton
                      id="tbg-btn-type-2"
                      value={'mediumTank'}
                      variant="outline-secondary"
                    >
                      <span>Medium tank</span>
                    </ToggleButton>
                    <ToggleButton
                      id="tbg-btn-type-3"
                      value={'lightTank'}
                      variant="outline-secondary"
                    >
                      <span>Light tank</span>
                    </ToggleButton>
                    <ToggleButton
                      id="tbg-btn-type-4"
                      value={'AT-SPG'}
                      variant="outline-secondary"
                    >
                      <span>AT-SPG</span>
                    </ToggleButton>
                  </ToggleButtonGroup>

                  <p>
                    {isTanksTypeFilterValid() ? (
                      <Form.Text className="text-muted">
                        {t(
                          'WotBlitzTournament.SelectedTankTypesNote',
                          'You have selected: {{tankTypes}}',
                          {
                            tankTypes: tanksTypeFilter
                              .map((x) => {
                                switch (x) {
                                  case 'heavyTank':
                                    return 'Heavy tank';
                                  case 'mediumTank':
                                    return 'Medium tank';
                                  case 'lightTank':
                                    return 'Light tank';
                                  case 'AT-SPG':
                                    return 'AT-SPG';
                                  default:
                                    return '';
                                }
                              })
                              .join(', '),
                          },
                        )}
                      </Form.Text>
                    ) : (
                      <Alert variant="danger" className="mt-2 pt-1 pb-1">
                        {t(
                          'WotBlitzTournament.TanksTypeFilterAlert',
                          'You have disabled all tank types. At least one type must be selected.',
                        )}
                      </Alert>
                    )}
                  </p>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  {t(
                    'WotBlitzTournament.OnlyRegularTanks',
                    'Only regular tanks',
                  )}
                </Form.Label>

                <Col sm={10}>
                  <Stack direction="horizontal" gap={3}>
                    <Form.Check
                      type="switch"
                      label={
                        onlyRegularTanks
                          ? t(
                              'WotBlitzTournament.OnlyRegularTanksEnabled',
                              'Enabled',
                            )
                          : t(
                              'WotBlitzTournament.OnlyRegularTanksDisabled',
                              'Disabled',
                            )
                      }
                      checked={onlyRegularTanks}
                      onChange={(event) => {
                        setOnlyRegularTanks(event.target.checked);
                      }}
                    />
                  </Stack>
                </Col>
              </Form.Group>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Form>

      <Row>
        <Col sm={{ span: 1, offset: 11 }}>
          <Button
            className="mt-3"
            variant="success"
            disabled={
              loading.find((x) => x.key === LoadingParts.WotBlitzTournaments)
                ?.value === true ||
              loading.find(
                (x) => x.key === LoadingParts.WotBlitzTournamentsSave,
              )?.value === true ||
              !isNameValid() ||
              !isStartAtValid() ||
              !isEndAtValid() ||
              !isBattleMultiplierValid() ||
              !isWinMultiplierValid() ||
              !isFragMultiplierValid() ||
              !isDamageMultiplierValid() ||
              !isTanksTierFilterValid() ||
              !isTanksTypeFilterValid() ||
              !isBestBattlesCountValid()
            }
            onClick={() => {
              handleSave();
            }}
          >
            {t('WotBlitzTournament.SaveButton', 'Save')}
          </Button>
        </Col>
      </Row>
    </>
  );
}
