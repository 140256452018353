import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../../redux/store';
import {
  WotBlitzServer,
  WotBlitzTournamentStatus,
} from '../../../../services/api/interfaces/common-api-interfase';
import {
  LoadingParts,
  startLoading,
  stopLoading,
} from '../../loading/loadingSlice';
import {
  IWotBlitzTournamentDto,
  IWotBlitzTournamentRulesDto,
  WotBlitzTournamentShortDto,
  WotBlitzTournamentsApiService,
} from '../../../../services/api/wot-blitz-tournaments-api.service';
import { showToast } from '../../toast/toastSlice';
import { LogService } from '../../../../services/log.service';

export interface WotBlitzTournamentsState {
  list: WotBlitzTournamentShortDto[];
  item?: IWotBlitzTournamentDto | null;
  publicItem?: IWotBlitzTournamentDto | null;
}

const InitialState: WotBlitzTournamentsState = {
  list: [],
  item: undefined,
  publicItem: undefined,
};

export const wotBlitzTournamentsSlice = createSlice({
  name: 'wotBlitzTournaments',
  initialState: InitialState,
  reducers: {
    setWotBlitzTournamentsList: (
      state,
      action: PayloadAction<WotBlitzTournamentShortDto[]>,
    ) => {
      state.list = action.payload;
    },
    setWotBlitzTournamentItem: (
      state,
      action: PayloadAction<IWotBlitzTournamentDto | undefined | null>,
    ) => {
      state.item = action.payload;
    },
    setWotBlitzTournamentPublicItem: (
      state,
      action: PayloadAction<IWotBlitzTournamentDto | undefined | null>,
    ) => {
      state.publicItem = action.payload;
    },
  },
});

export const {
  setWotBlitzTournamentsList,
  setWotBlitzTournamentItem,
  setWotBlitzTournamentPublicItem,
} = wotBlitzTournamentsSlice.actions;

export const getWotBlitzTournamentsAsync = (): AppThunk => async (dispatch) => {
  try {
    dispatch(startLoading(LoadingParts.WotBlitzTournaments));

    const svc = new WotBlitzTournamentsApiService();
    const response = await svc.getAll();

    if (response.success !== true) {
      dispatch(
        showToast({
          title: 'Error',
          message: response.message ?? 'Unknown error occurred...',
          variant: 'danger',
        }),
      );
    } else {
      dispatch(setWotBlitzTournamentsList(response.data ?? []));
    }
  } catch (e) {
    LogService.error(`Error while loading WoT Blitz tournaments list`);
    LogService.error(e as Error);
  } finally {
    dispatch(stopLoading(LoadingParts.WotBlitzTournaments));
  }
};

export const getWotBlitzTournamentAsync =
  (tournamentId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.WotBlitzTournaments));

      const svc = new WotBlitzTournamentsApiService();
      const response = await svc.getById({ tournamentId });

      if (response.success !== true) {
        dispatch(
          showToast({
            title: 'Error',
            message: response.message ?? 'Unknown error occurred...',
            variant: 'danger',
          }),
        );
      } else {
        dispatch(setWotBlitzTournamentItem(response.data));
      }
    } catch (e) {
      LogService.error(
        `Error while loading WoT Blitz tournament item with id ${tournamentId}`,
      );
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.WotBlitzTournaments));
    }
  };

export const getWotBlitzTournamentPublicAsync =
  (tournamentId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.WotBlitzTournaments));

      const svc = new WotBlitzTournamentsApiService();
      const response = await svc.getByIdPublic({ tournamentId });

      if (response.success === true) {
        dispatch(setWotBlitzTournamentPublicItem(response.data));
      }
    } catch (e) {
      LogService.error(
        `Error while loading WoT Blitz tournament item with id ${tournamentId}`,
      );
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.WotBlitzTournaments));
    }
  };

export const createWotBlitzTournamentAsync =
  (
    name: string,
    startAt: Date,
    endAt: Date,
    status: WotBlitzTournamentStatus,
    rules: IWotBlitzTournamentRulesDto,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.WotBlitzTournamentsSave));

      const svc = new WotBlitzTournamentsApiService();
      const response = await svc.create({
        name,
        startAt: startAt.toISOString(),
        endAt: endAt.toISOString(),
        status,
        rules,
      });

      if (response.success !== true) {
        dispatch(
          showToast({
            title: 'Error',
            message: response.message ?? 'Unknown error occurred...',
            variant: 'danger',
          }),
        );
      } else {
        dispatch(setWotBlitzTournamentItem(response.data ?? undefined));
        dispatch(
          showToast({
            title: 'Success',
            message: 'Tournament created successfully',
            variant: 'success',
          }),
        );
      }
    } catch (e) {
      LogService.error(`Error while creating WoT Blitz tournament item`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.WotBlitzTournamentsSave));
    }
  };

export const updateWotBlitzTournamentAsync =
  (
    tournamentId: string,
    name: string,
    startAt: Date,
    endAt: Date,
    status: WotBlitzTournamentStatus,
    rules: IWotBlitzTournamentRulesDto,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.WotBlitzTournamentsSave));

      const svc = new WotBlitzTournamentsApiService();
      const response = await svc.update(
        { tournamentId },
        {
          name,
          startAt: startAt.toISOString(),
          endAt: endAt.toISOString(),
          status,
          rules,
        },
      );

      if (response.success !== true) {
        dispatch(
          showToast({
            title: 'Error',
            message: response.message ?? 'Unknown error occurred...',
            variant: 'danger',
          }),
        );
      } else {
        dispatch(setWotBlitzTournamentItem(response.data));
        dispatch(
          showToast({
            title: 'Success',
            message: 'Tournament updated successfully',
            variant: 'success',
          }),
        );
      }
    } catch (e) {
      LogService.error(`Error while updating WoT Blitz tournament item`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.WotBlitzTournamentsSave));
    }
  };

export const addWotBlitzTournamentAccountAsync =
  (tournamentId: string, wotId: number, server: WotBlitzServer): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.WotBlitzTournamentAccountSave));

      const svc = new WotBlitzTournamentsApiService();
      const response = await svc.addAccount(
        { tournamentId },
        { wotId, server },
      );

      if (response.success !== true) {
        dispatch(
          showToast({
            title: 'Error',
            message: response.message ?? 'Unknown error occurred...',
            variant: 'danger',
          }),
        );
      } else {
        dispatch(getWotBlitzTournamentAsync(tournamentId));
        dispatch(
          showToast({
            title: 'Success',
            message: 'Tournament account has been added successfully',
            variant: 'success',
          }),
        );
      }
    } catch (e) {
      LogService.error(`Error while adding WoT Blitz tournament account`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.WotBlitzTournamentAccountSave));
    }
  };

export const updateWotBlitzTournamentAccountAsync =
  (tournamentId: string, accountId: string, team: string | null): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.WotBlitzTournamentAccountSave));

      const svc = new WotBlitzTournamentsApiService();
      const response = await svc.updateAccount(
        { tournamentId, accountId },
        { team },
      );

      if (response.success !== true) {
        dispatch(
          showToast({
            title: 'Error',
            message: response.message ?? 'Unknown error occurred...',
            variant: 'danger',
          }),
        );
      } else {
        dispatch(getWotBlitzTournamentAsync(tournamentId));
        dispatch(
          showToast({
            title: 'Success',
            message: 'Tournament account has been updated successfully',
            variant: 'success',
          }),
        );
      }
    } catch (e) {
      LogService.error(`Error while updating WoT Blitz tournament account`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.WotBlitzTournamentAccountSave));
    }
  };

export const deleteWotBlitzTournamentAccountAsync =
  (tournamentId: string, accountId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.WotBlitzTournamentAccountSave));

      const svc = new WotBlitzTournamentsApiService();
      const response = await svc.deleteAccount({ tournamentId, accountId });

      if (response.success !== true) {
        dispatch(
          showToast({
            title: 'Error',
            message: response.message ?? 'Unknown error occurred...',
            variant: 'danger',
          }),
        );
      } else {
        dispatch(getWotBlitzTournamentAsync(tournamentId));
        dispatch(
          showToast({
            title: 'Success',
            message: 'Tournament account has been deleted successfully',
            variant: 'success',
          }),
        );
      }
    } catch (e) {
      LogService.error(`Error while deleting WoT Blitz tournament account`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.WotBlitzTournamentAccountSave));
    }
  };

export const addWotBlitzTournamentAccountBonusAsync =
  (
    tournamentId: string,
    accountId: string,
    amount: number,
    date: Date,
    comment: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.WotBlitzTournamentAccountBonusSave));

      const svc = new WotBlitzTournamentsApiService();
      const response = await svc.addAccountBonus(
        { tournamentId, accountId },
        { amount, date: date.toISOString(), comment },
      );

      if (response.success !== true) {
        dispatch(
          showToast({
            title: 'Error',
            message: response.message ?? 'Unknown error occurred...',
            variant: 'danger',
          }),
        );
      } else {
        dispatch(getWotBlitzTournamentAsync(tournamentId));
        dispatch(
          showToast({
            title: 'Success',
            message: 'Tournament account bonus has been added successfully',
            variant: 'success',
          }),
        );
      }
    } catch (e) {
      LogService.error(`Error while adding WoT Blitz tournament account bonus`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.WotBlitzTournamentAccountBonusSave));
    }
  };

export const deleteWotBlitzTournamentAccountBonusAsync =
  (tournamentId: string, accountId: string, bonusId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.WotBlitzTournamentAccountBonusSave));

      const svc = new WotBlitzTournamentsApiService();
      const response = await svc.deleteAccountBonus({
        tournamentId,
        accountId,
        bonusId,
      });

      if (response.success !== true) {
        dispatch(
          showToast({
            title: 'Error',
            message: response.message ?? 'Unknown error occurred...',
            variant: 'danger',
          }),
        );
      } else {
        dispatch(getWotBlitzTournamentAsync(tournamentId));
        dispatch(
          showToast({
            title: 'Success',
            message: 'Tournament account bonus has been deleted successfully',
            variant: 'success',
          }),
        );
      }
    } catch (e) {
      LogService.error(
        `Error while deleting WoT Blitz tournament account bonus`,
      );
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.WotBlitzTournamentAccountBonusSave));
    }
  };

export const selectWotBlitzTournamentsList = (state: RootState) =>
  state.wotBlitzTournaments.list;

export const selectWotBlitzTournamentItem = (state: RootState) =>
  state.wotBlitzTournaments.item;

export const selectWotBlitzTournamentPublicItem = (state: RootState) =>
  state.wotBlitzTournaments.publicItem;

export default wotBlitzTournamentsSlice.reducer;
