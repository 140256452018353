export const VERSION = process.env.REACT_APP_VERSION;
export const API_URL = process.env.REACT_APP_API_URL;

// WG/Lesta
export const WG_WOT_BLITZ_APPLICATION_ID =
  process.env.REACT_APP_WG_WOT_BLITZ_APPLICATION_ID;
export const LESTA_WOT_BLITZ_APPLICATION_ID =
  process.env.REACT_APP_LESTA_WOT_BLITZ_APPLICATION_ID;
export const WG_WOT_APPLICATION_ID =
  process.env.REACT_APP_WG_WOT_APPLICATION_ID;
export const LESTA_WOT_APPLICATION_ID =
  process.env.REACT_APP_LESTA_WOT_APPLICATION_ID;
export const TROVO_CLIENT_ID = process.env.REACT_APP_TROVO_CLIENT_ID;
