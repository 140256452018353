import './App.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import { Main } from './features/main/Main';
import { useTranslation } from 'react-i18next';
import { Widgets } from './features/widgets/widgets';

function App() {
  const [searchParams] = useSearchParams();
  const { i18n } = useTranslation();

  // switch language if provided
  const lang = searchParams.get('lang');
  if (
    lang != null &&
    (lang.toLowerCase() === 'ru' ||
      lang.toLowerCase() === 'en' ||
      lang.toLowerCase() === 'uk') &&
    lang !== i18n.language
  ) {
    void i18n.changeLanguage(lang.toLowerCase());
    document.documentElement.lang = lang.toLowerCase();
  }

  return (
    <Routes>
      <Route path="widgets/*" element={<Widgets />} />
      <Route path="*" element={<Main />} />
    </Routes>
  );
}

export default App;
