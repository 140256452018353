import {
  Action,
  configureStore,
  ThunkAction,
  combineReducers,
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import loadingReducer from '../features/main/loading/loadingSlice';
import userReducer from '../features/main/user/userSlice';
import toastReducer from '../features/main/toast/toastSlice';
import wotBlitzTournamentsReducer from '../features/main/tournaments/wot-blitz/wot-blitz-tournaments-slice';
import wotTournamentReducer from '../features/main/tournaments/item/redux/wot-tournament-slice';
import wotTournamentsListReducer from '../features/main/tournaments/list/redux/wot-tournaments-list-slice';
import wotTournamentAccountReducer from '../features/main/tournaments/item/redux/wot-tournament-account-slice';
import settingsReducer from '../features/main/settings/settings.slice';
import widgetReducer from '../features/widgets/wot/redux/widget-slice';

const store = configureStore({
  reducer: combineReducers({
    loading: loadingReducer,
    toast: toastReducer,
    user: userReducer,
    settings: settingsReducer,
    wotBlitzTournaments: wotBlitzTournamentsReducer,
    wotTournament: wotTournamentReducer,
    wotTournamentsList: wotTournamentsListReducer,
    wotTournamentAccount: wotTournamentAccountReducer,
    widget: widgetReducer,
  }),
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
