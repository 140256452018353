import { Stack, Button, Card, Form, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { AuthApiService } from '../../../services/api/auth-api.service';
import { getUserMeAsync, selectUser } from '../user/userSlice';
import { LogService } from '../../../services/log.service';
import { useNavigate } from 'react-router-dom';
import { showToast } from '../toast/toastSlice';
import { useAppDispatch } from '../../../redux/store';

export function Login() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  useEffect(() => {
    if (user != null) {
      navigate('/');
    }
  }, [navigate, user]);

  const handleLocalLogin = async () => {
    const svc = new AuthApiService();
    const response = await svc.loginLocal({ email, password });

    if (response.success !== true) {
      dispatch(
        showToast({
          title: 'Error',
          message: response.message ?? 'Unknown error occurred...',
          variant: 'danger',
        }),
      );
    } else {
      if (response.data != null) {
        localStorage.setItem('jwt', response.data);
        LogService.debug('Authenticated');
        dispatch(getUserMeAsync());
      }
    }
  };

  return (
    <Row>
      <Col>
        <Stack gap={2} className="col-md-5 mx-auto">
          <Card>
            <Card.Body>
              <Card.Title>
                {t('Login.EmailTitle', 'Log in with email')}
              </Card.Title>

              <Form>
                <Form.Control
                  type="email"
                  placeholder={t('Login.EmailPlaceholder', 'Enter email')}
                  className="mb-3"
                  autoComplete="email"
                  required
                  isInvalid={!email}
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />

                <Form.Control
                  type="password"
                  placeholder={t('Login.PasswordPlaceholder', 'Password')}
                  className="mb-3"
                  autoComplete="current-password"
                  required
                  isInvalid={!password}
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                />

                <Button
                  variant="primary"
                  disabled={!email || !password}
                  onClick={() => void handleLocalLogin()}
                >
                  {t('Login.LoginButton', 'Log in')}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Stack>
      </Col>
    </Row>
  );
}
