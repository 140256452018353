import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../redux/store';

export enum LoadingParts {
  User,
  WotBlitzTournaments,
  WotBlitzTournamentsSave,
  WotBlitzTournamentAccountSave,
  WotBlitzTournamentAccountBonusSave,
  WotBlitzSearchingAccount,
  WotBlitzTanks,
  WotTournaments,
  WotTournamentAccount,
  WotTournamentsSave,
  WotTournamentAccountSave,
  WotTournamentAccountBonusSave,
  WotTournamentAccountBattlesSave,
  WotSearchingAccount,
  WotTanks,
  TrovoSearchingUser,
  SettingsLoad,
  SettingsSave,
}

export interface LoadingState {
  parts: Array<{ key: LoadingParts; value: boolean }>;
}

const InitialState: LoadingState = {
  parts: [],
};

export const loadingSlice = createSlice({
  name: 'loading',
  initialState: InitialState,
  reducers: {
    startLoading: (state, action: PayloadAction<LoadingParts>) => {
      const part = state.parts.find((p) => p.key === action.payload);
      if (part != null) {
        part.value = true;
      } else {
        state.parts.push({ key: action.payload, value: true });
      }
    },
    stopLoading: (state, action: PayloadAction<LoadingParts>) => {
      const part = state.parts.find((p) => p.key === action.payload);
      if (part != null) {
        part.value = false;
      } else {
        state.parts.push({ key: action.payload, value: false });
      }
    },
  },
});

export const { startLoading, stopLoading } = loadingSlice.actions;

export const selectLoadingState = (state: RootState) => state.loading.parts;

export default loadingSlice.reducer;
