import { Route, Routes, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserMeAsync, refreshUserAsync, selectUser } from './user/userSlice';
import { Login } from './login/Login';
import { Col, Container, Row, Toast, ToastContainer } from 'react-bootstrap';
import { Tournaments } from './tournaments/tournaments';
import { hideToast, selectToast } from './toast/toastSlice';
import { useAppDispatch } from '../../redux/store';
import { Registration } from './login/Registration';
import { useEffect } from 'react';
import { Settings } from './settings/settings';
import { Header } from './header/Header';

export function Main() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useSelector(selectUser);
  const toast = useSelector(selectToast);

  // load a logged user
  useEffect(() => {
    dispatch(getUserMeAsync());
    const interval = setInterval(
      () => {
        dispatch(refreshUserAsync());
      },
      1000 * 60 * 20,
    );
    return () => clearInterval(interval);
  }, [dispatch]);

  // redirect to tournaments from root
  useEffect(() => {
    if (
      window.location.pathname === '/' ||
      window.location.pathname === '/tournaments'
    ) {
      navigate(`/tournaments/wot`);
    }
  }, [navigate]);

  return (
    <>
      <ToastContainer className="p-3 position-fixed" position="top-end">
        <Toast
          show={toast.show}
          onClose={() => dispatch(hideToast())}
          delay={5000}
          autohide
          bg={toast.variant}
        >
          <Toast.Header>
            <strong className="me-auto">{toast.title}</strong>
          </Toast.Header>
          <Toast.Body>{toast.message}</Toast.Body>
        </Toast>
      </ToastContainer>

      <Container fluid className="min-vh-100">
        <Row className="mb-3">
          <Col>
            <Header />
          </Col>
        </Row>
        <Row className="min-vh-60 mb-5">
          <Col className="main-container">
            <Container fluid>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/registration" element={<Registration />} />
                {user == null && <Route path="*" element={<Login />} />}

                {user != null && (
                  <>
                    <Route path="settings/*" element={<Settings />} />
                    <Route
                      path="tournaments/:game/*"
                      element={<Tournaments />}
                    />
                  </>
                )}
              </Routes>
            </Container>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col>{/* <Footer /> */}</Col>
        </Row>
      </Container>
    </>
  );
}
