import fetchApi from './fetch-api';
import { paths, components } from './interfaces/api-interfase';

export type UserDto = components['schemas']['UserDto'];

export class UserApiService {
  async getMe(): Promise<
    | paths['/api/users/me']['get']['responses']['200']['content']['application/json']
    | null
  > {
    const response = await fetchApi('users/me', {
      method: 'GET',
    });

    if (response.status === 401) {
      return null;
    }

    const body =
      (await response.json()) as paths['/api/users/me']['get']['responses']['200']['content']['application/json'];
    return body;
  }
}
