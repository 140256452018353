import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../../../redux/store';
import {
  LoadingParts,
  startLoading,
  stopLoading,
} from '../../../loading/loadingSlice';
import { showToast } from '../../../toast/toastSlice';
import { LogService } from '../../../../../services/log.service';
import {
  WotTournamentsApiService,
  WotTournamentShortDto,
} from '../../../../../services/api/wot-tournaments-api.service';

export interface WotTournamentsListState {
  list: WotTournamentShortDto[];
}

const InitialState: WotTournamentsListState = {
  list: [],
};

export const wotTournamentsListSlice = createSlice({
  name: 'wotTournamentsList',
  initialState: InitialState,
  reducers: {
    setWotTournamentsList: (
      state,
      action: PayloadAction<WotTournamentShortDto[]>,
    ) => {
      state.list = action.payload;
    },
  },
});

export const { setWotTournamentsList } = wotTournamentsListSlice.actions;

export const getWotTournamentsAsync = (): AppThunk => async (dispatch) => {
  try {
    dispatch(startLoading(LoadingParts.WotTournaments));

    const svc = new WotTournamentsApiService();
    const response = await svc.getAll();

    if (response.success !== true) {
      dispatch(
        showToast({
          title: 'Error',
          message: response.message ?? 'Unknown error occurred...',
          variant: 'danger',
        }),
      );
    } else {
      dispatch(setWotTournamentsList(response.data ?? []));
    }
  } catch (e) {
    LogService.error(`Error while loading WoT tournaments list`);
    LogService.error(e as Error);
  } finally {
    dispatch(stopLoading(LoadingParts.WotTournaments));
  }
};

export const selectWotTournamentsList = (state: RootState) =>
  state.wotTournamentsList.list;

export default wotTournamentsListSlice.reducer;
