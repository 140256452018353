import { Typeahead } from 'react-bootstrap-typeahead';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useAppDispatch } from '../../../../../redux/store';
import { WotBlitzServer } from '../../../../../services/api/interfaces/common-api-interfase';
import {
  LoadingParts,
  startLoading,
  stopLoading,
} from '../../../loading/loadingSlice';
import { WotBlitzApiService } from '../services/wot-blitz-api-service';
import { IWotBlitzApiAccountSearch } from '../services/wot-blitz-api-interface';
import { LogService } from '../../../../../services/log.service';

export function WotBlitzAccountSearch(props: {
  server: WotBlitzServer;
  onSelect: (selected: IWotBlitzApiAccountSearch) => void;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [results, setResults] = useState<IWotBlitzApiAccountSearch[]>([]);
  const [selected, setSelected] = useState<IWotBlitzApiAccountSearch>();

  const onSearch = async (query: string) => {
    setResults([]);
    setSelected(undefined);

    if (query.length > 2) {
      try {
        dispatch(startLoading(LoadingParts.WotBlitzSearchingAccount));
        const wotBlitzApiSvc = new WotBlitzApiService();

        const apiResults = await wotBlitzApiSvc.searchAccount(
          query,
          props.server,
        );

        setResults(apiResults.data ?? []);
      } catch (e) {
        LogService.error(`Error while searching account`);
        LogService.error(e as Error);
      } finally {
        dispatch(stopLoading(LoadingParts.WotBlitzSearchingAccount));
      }
    }
  };

  return (
    <Typeahead
      id="search-wot-blitz-account"
      className={`wot-blitz-account-search`}
      emptyLabel={t('WotBlitzAccountSearch.EmptyLabel', 'Account not found')}
      placeholder={
        selected?.nickname ??
        t('WotBlitzAccountSearch.Placeholder', 'Enter account name')
      }
      paginationText={t('WotBlitzAccountSearch.PaginationText', 'Show more')}
      maxResults={7}
      labelKey={(option) =>
        `${(option as IWotBlitzApiAccountSearch).nickname} (${
          (option as IWotBlitzApiAccountSearch).account_id
        })`
      }
      options={results}
      onChange={(selected) => {
        setSelected(selected[0] as IWotBlitzApiAccountSearch);
        props.onSelect(selected[0] as IWotBlitzApiAccountSearch);
      }}
      onInputChange={(text) => void onSearch(text)}
      selected={[]}
      defaultInputValue=""
    />
  );
}
