import { useSelector } from 'react-redux';
import {
  deleteWotTournamentAsync,
  setWotTournamentItem,
} from '../item/redux/wot-tournament-slice';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../redux/store';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { TournamentsListHeader } from './components/tournaments-list-header';
import { formatDate } from '../../../../utils/date-utils';
import { LoadingParts, selectLoadingState } from '../../loading/loadingSlice';
import { Trash3 } from 'react-bootstrap-icons';
import { Game } from '../tournaments.interface';
import { setWotBlitzTournamentItem } from '../wot-blitz/wot-blitz-tournaments-slice';
import {
  selectWotTournamentsList,
  getWotTournamentsAsync,
} from './redux/wot-tournaments-list-slice';
import { TournamentsListModalDelete } from './components/tournaments-list-modal-delete';

export function WotTournamentsList() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { game } = useParams<{ game: string }>();
  const navigate = useNavigate();

  const loading = useSelector(selectLoadingState);
  const wotTournaments = useSelector(selectWotTournamentsList);

  const [onlyPublic, setOnlyPublic] = useState<boolean>(false);

  const [sorting, setSorting] = useState<{
    column:
      | 'name'
      | 'startDate'
      | 'endDate'
      | 'status'
      | 'calculatedAt'
      | 'id'
      | 'calc';
    desc: boolean;
  }>({
    column: 'name',
    desc: true,
  });

  const [deletingId, setDeletingId] = useState<string>();

  const sortedTournaments = wotTournaments
    .filter((tournament) => {
      if (onlyPublic) {
        return tournament.status === 'Public';
      }
      return true;
    })
    .sort((a, b) => {
      switch (sorting.column) {
        case 'name':
          return sorting.desc
            ? b.name.localeCompare(a.name)
            : a.name.localeCompare(b.name);
        case 'id':
          return sorting.desc
            ? b.id.localeCompare(a.id)
            : a.id.localeCompare(b.id);
        case 'startDate':
          return sorting.desc
            ? b.startAt.localeCompare(a.startAt)
            : a.startAt.localeCompare(b.startAt);
        case 'endDate':
          return sorting.desc
            ? b.endAt.localeCompare(a.endAt)
            : a.endAt.localeCompare(b.endAt);
        case 'calculatedAt':
          return sorting.desc
            ? (b.calculatedAt ?? '').localeCompare(a.calculatedAt ?? '')
            : (a.calculatedAt ?? '').localeCompare(b.calculatedAt ?? '');
        case 'status':
          return sorting.desc
            ? b.status.localeCompare(a.status)
            : a.status.localeCompare(b.status);
        case 'calc':
          return sorting.desc
            ? b.endAt.localeCompare(a.endAt)
            : a.endAt.localeCompare(b.endAt);
        default:
          return sorting.desc
            ? b.name.localeCompare(a.name)
            : a.name.localeCompare(b.name);
      }
    });

  useEffect(() => {
    dispatch(getWotTournamentsAsync());
  }, [dispatch]);

  const handleGameChange = (game: Game) => {
    navigate(`/tournaments/${game}`);
  };

  const handleNewTournament = () => {
    dispatch(setWotBlitzTournamentItem(null));
    dispatch(setWotTournamentItem(null));
    navigate(`/tournaments/${game}/new`);
  };

  return (
    <>
      <Row>
        <TournamentsListHeader
          onlyPublic={onlyPublic}
          onOnlyPublicChange={() => setOnlyPublic(!onlyPublic)}
          game={game as Game}
          onGameChange={(game: Game) => handleGameChange(game)}
          onNewTournamentClick={() => handleNewTournament()}
        />
      </Row>

      <Row className="mt-3">
        <Col>
          <TournamentsListModalDelete
            deleting={wotTournaments?.find((t) => t.id === deletingId)}
            onCancelClick={() => setDeletingId(undefined)}
            onDeleteClick={() => {
              if (deletingId != null) {
                dispatch(deleteWotTournamentAsync(deletingId));
              }

              setDeletingId(undefined);
            }}
          />

          <Table bordered hover className="mb-5" responsive>
            <thead>
              <tr>
                <th></th>
                <th>
                  <Button
                    variant="link"
                    onClick={() =>
                      setSorting({ column: 'name', desc: !sorting.desc })
                    }
                  >
                    {t('WotTournamentsList.ColumnName', 'Name')}
                  </Button>
                </th>
                <th>
                  <Button
                    variant="link"
                    onClick={() =>
                      setSorting({ column: 'id', desc: !sorting.desc })
                    }
                  >
                    {t('WotTournamentsList.ColumnId', 'ID')}
                  </Button>
                </th>
                <th>
                  <Button
                    variant="link"
                    onClick={() =>
                      setSorting({ column: 'startDate', desc: !sorting.desc })
                    }
                  >
                    {t('WotTournamentsList.ColumnStartDate', 'Start')}
                  </Button>
                </th>
                <th>
                  <Button
                    variant="link"
                    onClick={() =>
                      setSorting({ column: 'endDate', desc: !sorting.desc })
                    }
                  >
                    {t('WotTournamentsList.ColumnEndDate', 'End')}
                  </Button>
                </th>
                <th>
                  <Button
                    variant="link"
                    onClick={() =>
                      setSorting({ column: 'calc', desc: !sorting.desc })
                    }
                  >
                    {t('WotTournamentsList.ColumnCalc', 'Calculation')}
                  </Button>
                </th>
                <th>
                  <Button
                    variant="link"
                    onClick={() =>
                      setSorting({ column: 'status', desc: !sorting.desc })
                    }
                  >
                    {t('WotTournamentsList.ColumnStatus', 'Status')}
                  </Button>
                </th>
                <th>
                  <Button
                    variant="link"
                    onClick={() =>
                      setSorting({
                        column: 'calculatedAt',
                        desc: !sorting.desc,
                      })
                    }
                  >
                    {t('WotTournamentsList.ColumnCalculatedAt', 'Calculated')}
                  </Button>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {sortedTournaments.length > 0 &&
                sortedTournaments.map((tournament, i) => (
                  <tr key={tournament.id}>
                    <td style={{ whiteSpace: 'nowrap' }}>{i + 1}</td>
                    <td style={{ whiteSpace: 'nowrap' }}>
                      <Link to={`/tournaments/wot/${tournament.id}`}>
                        {tournament.name}
                      </Link>
                    </td>
                    <td style={{ whiteSpace: 'nowrap' }}>
                      <Link to={`/tournaments/wot/${tournament.id}`}>
                        {tournament.id}
                      </Link>
                    </td>
                    <td style={{ whiteSpace: 'nowrap' }}>
                      {tournament.startAt == null
                        ? undefined
                        : formatDate(new Date(tournament.startAt))}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' }}>
                      {tournament.endAt == null
                        ? undefined
                        : formatDate(new Date(tournament.endAt))}
                    </td>
                    <td
                      style={{ whiteSpace: 'nowrap' }}
                      className={
                        new Date().toISOString() > tournament.endAt
                          ? 'bg-warning'
                          : 'bg-success'
                      }
                    >
                      {new Date().toISOString() > tournament.endAt
                        ? 'Completed'
                        : 'Active'}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' }}>
                      {tournament.status}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' }}>
                      {tournament.calculatedAt == null
                        ? '-'
                        : formatDate(new Date(tournament.calculatedAt))}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' }}>
                      <Button
                        variant="link"
                        disabled={
                          loading.find(
                            (x) =>
                              x.key ===
                              LoadingParts.WotTournamentAccountBattlesSave,
                          )?.value === true
                        }
                        onClick={() => setDeletingId(tournament.id)}
                      >
                        <Trash3 size={20} color="red" />
                      </Button>
                    </td>
                  </tr>
                ))}

              {sortedTournaments.length === 0 && (
                <tr>
                  <td colSpan={9} className="text-center">
                    {t(
                      'WotTournamentsList.NoTableRows',
                      'There are no records matching the selected filters.',
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
}
