import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../../../redux/store';
import {
  WotServer,
  WotTournamentStatus,
} from '../../../../../services/api/interfaces/common-api-interfase';
import {
  LoadingParts,
  startLoading,
  stopLoading,
} from '../../../loading/loadingSlice';
import { showToast } from '../../../toast/toastSlice';
import { LogService } from '../../../../../services/log.service';
import {
  IWotTournamentAccountUpsertBattleDto,
  IWotTournamentDto,
  IWotTournamentRulesDto,
  WotTournamentsApiService,
} from '../../../../../services/api/wot-tournaments-api.service';
import { getWotTournamentAccountAsync } from './wot-tournament-account-slice';
import { IWotApiTank } from '../../../../../services/wot/wot-api-interface';
import { WotApiService } from '../../../../../services/wot/wot-api-service';
import { getWotTournamentsAsync } from '../../list/redux/wot-tournaments-list-slice';

export interface WotTournamentState {
  item?: IWotTournamentDto | null;
  tanks?: IWotApiTank[];
}

const InitialState: WotTournamentState = {
  item: undefined,
  tanks: undefined,
};

export const wotTournamentSlice = createSlice({
  name: 'wotTournament',
  initialState: InitialState,
  reducers: {
    setWotTournamentItem: (
      state,
      action: PayloadAction<IWotTournamentDto | undefined | null>,
    ) => {
      state.item = action.payload;
    },
    setWotTournamentTanks: (
      state,
      action: PayloadAction<IWotApiTank[] | undefined>,
    ) => {
      state.tanks = action.payload;
    },
  },
});

export const { setWotTournamentItem, setWotTournamentTanks } =
  wotTournamentSlice.actions;

export const getWotTanksAsync = (): AppThunk => async (dispatch) => {
  try {
    dispatch(startLoading(LoadingParts.WotTanks));

    const svc = new WotApiService();

    const responseLesta = await svc.getTanks('RU');
    const responseWg = await svc.getTanks('EU');

    const tanks: IWotApiTank[] = [];

    // intersect tanks from both servers
    if (responseLesta.data != null && responseWg.data != null) {
      for (const tankId in responseWg.data) {
        if (responseLesta.data[tankId] != null) {
          tanks.push(responseWg.data[tankId]);
        }
      }
    }

    // sort tanks by name
    tanks.sort((a, b) => a.name.localeCompare(b.name));

    dispatch(setWotTournamentTanks(tanks));
  } catch (e) {
    LogService.error(`Error while loading WoT tanks list`);
    LogService.error(e as Error);
  } finally {
    dispatch(stopLoading(LoadingParts.WotTanks));
  }
};

export const getWotTournamentAsync =
  (tournamentId: string, cache: boolean): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.WotTournaments));

      const svc = new WotTournamentsApiService();
      const response = await svc.getById({ tournamentId }, { cache });

      if (response.success !== true) {
        dispatch(
          showToast({
            title: 'Error',
            message: response.message ?? 'Unknown error occurred...',
            variant: 'danger',
          }),
        );
      } else {
        dispatch(setWotTournamentItem(response.data));
      }
    } catch (e) {
      LogService.error(
        `Error while loading WoT tournament item with id ${tournamentId}`,
      );
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.WotTournaments));
    }
  };

export const createWotTournamentAsync =
  (
    name: string,
    startAt: Date,
    endAt: Date,
    status: WotTournamentStatus,
    rules: IWotTournamentRulesDto,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.WotTournamentsSave));

      const svc = new WotTournamentsApiService();
      const response = await svc.create({
        name,
        startAt: startAt.toISOString(),
        endAt: endAt.toISOString(),
        status,
        rules,
      });

      if (response.success !== true) {
        dispatch(
          showToast({
            title: 'Error',
            message: response.message ?? 'Unknown error occurred...',
            variant: 'danger',
          }),
        );
      } else {
        if (response.data?.id != null) {
          dispatch(getWotTournamentAsync(response.data.id, false));
        }
        dispatch(
          showToast({
            title: 'Success',
            message: 'Tournament created successfully',
            variant: 'success',
          }),
        );
      }
    } catch (e) {
      LogService.error(`Error while creating WoT tournament item`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.WotTournamentsSave));
    }
  };

export const updateWotTournamentAsync =
  (
    tournamentId: string,
    name: string,
    startAt: Date,
    endAt: Date,
    status: WotTournamentStatus,
    rules: IWotTournamentRulesDto,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.WotTournamentsSave));

      const svc = new WotTournamentsApiService();
      const response = await svc.update(
        { tournamentId },
        {
          name,
          startAt: startAt.toISOString(),
          endAt: endAt.toISOString(),
          status,
          rules,
        },
      );

      if (response.success !== true) {
        dispatch(
          showToast({
            title: 'Error',
            message: response.message ?? 'Unknown error occurred...',
            variant: 'danger',
          }),
        );
      } else {
        if (response.data?.id != null) {
          dispatch(getWotTournamentAsync(response.data.id, false));
        }
        dispatch(
          showToast({
            title: 'Success',
            message: 'Tournament updated successfully',
            variant: 'success',
          }),
        );
      }
    } catch (e) {
      LogService.error(`Error while updating WoT tournament item`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.WotTournamentsSave));
    }
  };

export const deleteWotTournamentAsync =
  (tournamentId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.WotTournamentsSave));

      const svc = new WotTournamentsApiService();
      const response = await svc.delete({ tournamentId });

      if (response.success !== true) {
        dispatch(
          showToast({
            title: 'Error',
            message: response.message ?? 'Unknown error occurred...',
            variant: 'danger',
          }),
        );
      } else {
        dispatch(getWotTournamentsAsync());
        dispatch(
          showToast({
            title: 'Success',
            message: 'Tournament delete successfully',
            variant: 'success',
          }),
        );
      }
    } catch (e) {
      LogService.error(`Error while deleting WoT tournament item`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.WotTournamentsSave));
    }
  };

export const addWotTournamentAccountAsync =
  (tournamentId: string, wotId: number, server: WotServer): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.WotTournamentAccountSave));

      const svc = new WotTournamentsApiService();
      const response = await svc.addAccount(
        { tournamentId },
        { wotId, server, metadata: null, nickname: null },
      );

      if (response.success !== true) {
        dispatch(
          showToast({
            title: 'Error',
            message: response.message ?? 'Unknown error occurred...',
            variant: 'danger',
          }),
        );
      } else {
        dispatch(getWotTournamentAsync(tournamentId, false));
        dispatch(
          showToast({
            title: 'Success',
            message: 'Tournament account has been added successfully',
            variant: 'success',
          }),
        );
      }
    } catch (e) {
      LogService.error(`Error while adding WoT tournament account`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.WotTournamentAccountSave));
    }
  };

export const updateWotTournamentAccountAsync =
  (tournamentId: string, accountId: string, team: string | null): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.WotTournamentAccountSave));

      const svc = new WotTournamentsApiService();
      const response = await svc.updateAccount(
        { tournamentId, accountId },
        { team, metadata: null, nickname: null },
      );

      if (response.success !== true) {
        dispatch(
          showToast({
            title: 'Error',
            message: response.message ?? 'Unknown error occurred...',
            variant: 'danger',
          }),
        );
      } else {
        dispatch(getWotTournamentAsync(tournamentId, false));
        dispatch(
          showToast({
            title: 'Success',
            message: 'Tournament account has been updated successfully',
            variant: 'success',
          }),
        );
      }
    } catch (e) {
      LogService.error(`Error while updating WoT tournament account`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.WotTournamentAccountSave));
    }
  };

export const deleteWotTournamentAccountAsync =
  (tournamentId: string, accountId: string, force: boolean = false): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.WotTournamentAccountSave));

      const svc = new WotTournamentsApiService();
      const response = await svc.deleteAccount(
        { tournamentId },
        { id: accountId, force },
      );

      if (response.success !== true) {
        dispatch(
          showToast({
            title: 'Error',
            message: response.message ?? 'Unknown error occurred...',
            variant: 'danger',
          }),
        );
      } else {
        dispatch(getWotTournamentAsync(tournamentId, false));
        dispatch(
          showToast({
            title: 'Success',
            message: 'Tournament account has been deleted successfully',
            variant: 'success',
          }),
        );
      }
    } catch (e) {
      LogService.error(`Error while deleting WoT tournament account`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.WotTournamentAccountSave));
    }
  };

export const resetWotTournamentAccountAsync =
  (tournamentId: string, accountId: string, force: boolean = false): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.WotTournamentAccountSave));

      const svc = new WotTournamentsApiService();
      const response = await svc.resetAccount(
        { tournamentId },
        { id: accountId, force },
      );

      if (response.success !== true) {
        dispatch(
          showToast({
            title: 'Error',
            message: response.message ?? 'Unknown error occurred...',
            variant: 'danger',
          }),
        );
      } else {
        dispatch(getWotTournamentAsync(tournamentId, false));
        dispatch(
          showToast({
            title: 'Success',
            message: 'Tournament account has been resetted successfully',
            variant: 'success',
          }),
        );
      }
    } catch (e) {
      LogService.error(`Error while resetting WoT tournament account`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.WotTournamentAccountSave));
    }
  };

export const clearWotTournamentAccountResetsAsync =
  (tournamentId: string, accountId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.WotTournamentAccountSave));

      const svc = new WotTournamentsApiService();
      const response = await svc.clearAccountResets(
        { tournamentId },
        { id: accountId },
      );

      if (response.success !== true) {
        dispatch(
          showToast({
            title: 'Error',
            message: response.message ?? 'Unknown error occurred...',
            variant: 'danger',
          }),
        );
      } else {
        dispatch(getWotTournamentAsync(tournamentId, false));
        dispatch(
          showToast({
            title: 'Success',
            message: 'Tournament account resets have been cleared successfully',
            variant: 'success',
          }),
        );
      }
    } catch (e) {
      LogService.error(`Error while clearing tournament account resets`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.WotTournamentAccountSave));
    }
  };

export const addWotTournamentAccountBonusAsync =
  (
    tournamentId: string,
    accountId: string,
    amount: number,
    date: Date,
    comment: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.WotTournamentAccountBonusSave));

      const svc = new WotTournamentsApiService();
      const response = await svc.addAccountBonus(
        { tournamentId, accountId },
        { amount, date: date.toISOString(), comment },
      );

      if (response.success !== true) {
        dispatch(
          showToast({
            title: 'Error',
            message: response.message ?? 'Unknown error occurred...',
            variant: 'danger',
          }),
        );
      } else {
        dispatch(getWotTournamentAsync(tournamentId, false));
        dispatch(getWotTournamentAccountAsync(tournamentId, accountId, false));
        dispatch(
          showToast({
            title: 'Success',
            message: 'Tournament account bonus has been added successfully',
            variant: 'success',
          }),
        );
      }
    } catch (e) {
      LogService.error(`Error while adding WoT tournament account bonus`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.WotTournamentAccountBonusSave));
    }
  };

export const deleteWotTournamentAccountBonusAsync =
  (tournamentId: string, accountId: string, bonusId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.WotTournamentAccountBonusSave));

      const svc = new WotTournamentsApiService();
      const response = await svc.deleteAccountBonus({
        tournamentId,
        accountId,
        bonusId,
      });

      if (response.success !== true) {
        dispatch(
          showToast({
            title: 'Error',
            message: response.message ?? 'Unknown error occurred...',
            variant: 'danger',
          }),
        );
      } else {
        dispatch(getWotTournamentAsync(tournamentId, false));
        dispatch(
          showToast({
            title: 'Success',
            message: 'Tournament account bonus has been deleted successfully',
            variant: 'success',
          }),
        );
      }
    } catch (e) {
      LogService.error(`Error while deleting WoT tournament account bonus`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.WotTournamentAccountBonusSave));
    }
  };

export const deleteWotTournamentAccountBattleAsync =
  (
    tournamentId: string,
    accountId: string,
    battleId: string,
    silent = false,
    force = false,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.WotTournamentAccountBattlesSave));

      const svc = new WotTournamentsApiService();
      const response = await svc.deleteAccountBattle(
        {
          tournamentId,
          accountId,
          battleId,
        },
        { silent, force },
      );

      if (response.success !== true) {
        dispatch(
          showToast({
            title: 'Error',
            message: response.message ?? 'Unknown error occurred...',
            variant: 'danger',
          }),
        );
      } else {
        dispatch(getWotTournamentAsync(tournamentId, false));
        dispatch(getWotTournamentAccountAsync(tournamentId, accountId, false));
        dispatch(
          showToast({
            title: 'Success',
            message: 'Tournament account battle has been deleted successfully',
            variant: 'success',
          }),
        );
      }
    } catch (e) {
      LogService.error(`Error while delting WoT tournament account battle`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.WotTournamentAccountBattlesSave));
    }
  };

export const restoreWotTournamentAccountBattleAsync =
  (tournamentId: string, accountId: string, battleId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.WotTournamentAccountBattlesSave));

      const svc = new WotTournamentsApiService();
      const response = await svc.restoreAccountBattle({
        tournamentId,
        accountId,
        battleId,
      });

      if (response.success !== true) {
        dispatch(
          showToast({
            title: 'Error',
            message: response.message ?? 'Unknown error occurred...',
            variant: 'danger',
          }),
        );
      } else {
        dispatch(getWotTournamentAsync(tournamentId, false));
        dispatch(getWotTournamentAccountAsync(tournamentId, accountId, false));
        dispatch(
          showToast({
            title: 'Success',
            message: 'Tournament account battle has been restored successfully',
            variant: 'success',
          }),
        );
      }
    } catch (e) {
      LogService.error(`Error while restoring WoT tournament account battle`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.WotTournamentAccountBattlesSave));
    }
  };

export const addWotTournamentAccountBattleAsync =
  (
    tournamentId: string,
    accountId: string,
    battle: IWotTournamentAccountUpsertBattleDto,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.WotTournamentAccountBattlesSave));

      const svc = new WotTournamentsApiService();
      const response = await svc.addAccountBattle(
        {
          tournamentId,
          accountId,
        },
        { ...battle },
      );

      if (response.success !== true) {
        dispatch(
          showToast({
            title: 'Error',
            message: response.message ?? 'Unknown error occurred...',
            variant: 'danger',
          }),
        );
      } else {
        dispatch(getWotTournamentAsync(tournamentId, false));
        dispatch(getWotTournamentAccountAsync(tournamentId, accountId, false));
        dispatch(
          showToast({
            title: 'Success',
            message: 'Tournament account battle has been added successfully',
            variant: 'success',
          }),
        );
      }
    } catch (e) {
      LogService.error(`Error while adding WoT tournament account battle`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.WotTournamentAccountBattlesSave));
    }
  };

export const selectWotTournament = (state: RootState) =>
  state.wotTournament.item;

export const selectWotTanks = (state: RootState) => state.wotTournament.tanks;

export default wotTournamentSlice.reducer;
