import { useState } from 'react';
import { Alert, Button, Col, Form, Row, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../redux/store';
import { showToast } from '../../../toast/toastSlice';
import { WidgetParams } from '../../../../widgets/widgets';
import { nameof } from '../../../../../utils/nameof.util';
import { IWotTournamentDto } from '../../../../../services/api/wot-tournaments-api.service';
import { WotServer } from '../../../../../services/api/interfaces/common-api-interfase';

export function WotTournamentWidgetTab(props: {
  tournament?: IWotTournamentDto | null;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [widgetType, setWidgetType] =
    useState<WidgetParams['type']>('individual');
  const [overlayDirection, setOverlayDirection] =
    useState<WidgetParams['overlay']>('right');
  const [language, setLanguage] = useState<WidgetParams['lang']>('ru');
  const [hiddingTime, setHiddingTime] = useState<WidgetParams['hideTime']>(1);
  const [showingTime, setShowingTime] =
    useState<WidgetParams['showTime']>(30000);
  const [wotAccountId, setWotAccountId] = useState<WidgetParams['accountId']>();
  const [server, setServer] = useState<WidgetParams['server']>();
  const [keyValue, setKeyValue] = useState<WidgetParams['keyValue']>('scores');

  const getWidgetUrl = () => {
    const url = new URL(window.location.href);
    url.pathname = `/widgets/tournaments/wot/${props.tournament?.id}`;
    url.searchParams.set(nameof<WidgetParams>('type'), widgetType);
    url.searchParams.set(nameof<WidgetParams>('overlay'), overlayDirection);
    url.searchParams.set(nameof<WidgetParams>('lang'), language);
    url.searchParams.set(
      nameof<WidgetParams>('showTime'),
      showingTime.toString(),
    );
    url.searchParams.set(
      nameof<WidgetParams>('hideTime'),
      hiddingTime.toString(),
    );
    url.searchParams.set(
      nameof<WidgetParams>('accountId'),
      wotAccountId?.toString() ?? '',
    );
    url.searchParams.set(nameof<WidgetParams>('server'), server ?? '');
    url.searchParams.set(nameof<WidgetParams>('keyValue'), keyValue);

    return url.toString();
  };

  const getPublicWidgetSettingsUrl = () => {
    const url = new URL(window.location.href);
    url.pathname = `/widgets/tournaments/wot/${props.tournament?.id}/settings`;
    return url.toString();
  };

  const handleOpen = () => {
    window.open(getWidgetUrl(), '_blank');
  };

  const handleCopy = () => {
    void navigator.clipboard.writeText(getWidgetUrl());
    dispatch(
      showToast({
        title: 'Success',
        message: t(
          'WotTournamentWidgetTab.LinkCopiedToClipboard',
          'Link copied to clipboard',
        ),
        variant: 'success',
      }),
    );
  };

  const isSelectedAccountValid = () => wotAccountId != null && server != null;

  return (
    <>
      {props.tournament == null && (
        <Alert variant="warning">
          {t(
            'WotTournamentWidgetTab.NoTournamentAlert',
            'Please select or save a tournament first',
          )}
        </Alert>
      )}

      {props.tournament?.accounts.length === 0 && (
        <Alert variant="warning">
          {t(
            'WotTournamentWidgetTab.NoTournamentAccountsAlert',
            'Please add accounts to the tournament first',
          )}
        </Alert>
      )}

      <Form className="p-2">
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2}>
            {t('WotTournamentWidgetTab.PublicSettings', 'Public settings')}
          </Form.Label>

          <Col sm={10}>
            <a
              href={getPublicWidgetSettingsUrl()}
              target="_blank"
              rel="noreferrer"
            >
              {getPublicWidgetSettingsUrl()}
            </a>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2}>
            {t('WotTournamentWidgetTab.Type', 'Type')}
          </Form.Label>

          <Col sm={10}>
            <Form.Select
              className="standard-input-width"
              onChange={(event) => setWidgetType(event.target.value as any)}
              value={widgetType}
            >
              <option value="table">Table</option>
              <option value="individual">Individual</option>
            </Form.Select>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2}>
            {t('WotTournamentWidgetTab.OverlayDirection', 'Overlay direction')}
          </Form.Label>

          <Col sm={10}>
            <Form.Select
              className="standard-input-width"
              onChange={(event) =>
                setOverlayDirection(event.target.value as any)
              }
              value={overlayDirection}
            >
              <option value="top">Top</option>
              <option value="bottom">Bottom</option>
              <option value="right">Right</option>
              <option value="left">Left</option>
            </Form.Select>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2}>
            {t('WotTournamentWidgetTab.Language', 'Language')}
          </Form.Label>

          <Col sm={10}>
            <Form.Select
              className="standard-input-width"
              onChange={(event) => setLanguage(event.target.value as any)}
              value={language}
            >
              <option value="en">English</option>
              <option value="ru">Russian</option>
              <option value="uk">Ukrainian</option>
            </Form.Select>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2}>
            {t('WotTournamentWidgetTab.HiddingTime', 'Hidding time (sec)')}
          </Form.Label>

          <Col sm={10}>
            <Form.Control
              className="standard-input-width"
              type="number"
              min={0}
              value={hiddingTime}
              onChange={(event) => setHiddingTime(+event.target.value)}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2}>
            {t('WotTournamentWidgetTab.ShowingTime', 'Showing time (sec)')}
          </Form.Label>

          <Col sm={10}>
            <Form.Control
              className="standard-input-width"
              type="number"
              min={0}
              value={showingTime}
              onChange={(event) => setShowingTime(+event.target.value)}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2}>
            {t('WotTournamentWidgetTab.KeyValue', 'Key value')}
          </Form.Label>

          <Col sm={10}>
            <Form.Select
              className="standard-input-width"
              onChange={(event) => setKeyValue(event.target.value as any)}
              value={keyValue}
            >
              <option value={'scores'}>
                {t('WotTournamentWidgetTab.Scores', 'Scores')}
              </option>
              <option value={'avgDamage'}>
                {t('WotTournamentWidgetTab.AvgDamage', 'Avg. damage')}
              </option>
            </Form.Select>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2}>
            {t('WotTournamentWidgetTab.Account', 'Account')}
          </Form.Label>

          <Col sm={10}>
            <Form.Select
              className="standard-input-width"
              onChange={(event) => {
                const [wotId, server] = event.target.value.split('-');
                setWotAccountId(+wotId);
                setServer(server as WotServer);
              }}
              value={
                wotAccountId == null || server == null
                  ? 'default'
                  : `${wotAccountId}-${server}`
              }
              isInvalid={!isSelectedAccountValid()}
            >
              {(wotAccountId == null || server == null) && (
                <option value={'default'}>
                  {t(
                    'WotTournamentWidgetTab.AccountSelect',
                    'Please select account',
                  )}
                </option>
              )}

              {[...(props.tournament?.accounts ?? [])]
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((account) => (
                  <option
                    key={`${account.wotId}-${account.server}`}
                    value={`${account.wotId}-${account.server}`}
                  >
                    ({account.wotId}) {account.name}
                  </option>
                ))}
            </Form.Select>
          </Col>
        </Form.Group>
      </Form>

      <Row>
        <Col sm={{ span: 2, offset: 10 }}>
          <Stack direction="horizontal" gap={3} className="mt-3">
            <Button
              variant="primary"
              disabled={
                props.tournament == null ||
                props.tournament.accounts.length === 0 ||
                isSelectedAccountValid() === false
              }
              onClick={() => handleOpen()}
            >
              {t('WotTournamentWidgetTab.OpenButton', 'Open')}
            </Button>

            <Button
              variant="primary"
              disabled={
                props.tournament == null ||
                props.tournament.accounts.length === 0 ||
                isSelectedAccountValid() === false
              }
              onClick={() => handleCopy()}
            >
              {t('WotTournamentWidgetTab.CopyUrlButton', 'Copy URL')}
            </Button>
          </Stack>
        </Col>
      </Row>
    </>
  );
}
