import fetch from 'cross-fetch';
import { WotServer } from '../api/interfaces/common-api-interfase';
import {
  IWotApiAccountSearch,
  IWotApiResponse,
  IWotApiTank,
} from './wot-api-interface';
import {
  LESTA_WOT_APPLICATION_ID,
  WG_WOT_APPLICATION_ID,
} from '../../configs/config';

export class WotApiService {
  public async searchAccount(
    search: string,
    server: WotServer,
  ): Promise<IWotApiResponse<IWotApiAccountSearch[]>> {
    const domain =
      server === 'RU'
        ? 'https://api.tanki.su'
        : `https://api.worldoftanks.${
            server === 'NA' ? 'com' : server.toLowerCase()
          }`;

    const url = `${domain}/wot/account/list/?application_id=${
      server === 'RU' ? LESTA_WOT_APPLICATION_ID : WG_WOT_APPLICATION_ID
    }&search=${search}`;

    const response = await fetch(url, {
      method: 'GET',
    });

    const result = (await response.json()) as IWotApiResponse<
      IWotApiAccountSearch[]
    >;

    return result;
  }

  public async getTanks(
    server: WotServer,
    tiers?: (1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10)[],
    types?: ('heavyTank' | 'mediumTank' | 'lightTank' | 'AT-SPG' | 'SPG')[],
  ): Promise<
    IWotApiResponse<{
      [key: number]: IWotApiTank;
    }>
  > {
    let domain =
      server === 'RU'
        ? 'https://api.tanki.su'
        : `https://api.worldoftanks.${
            server === 'NA' ? 'com' : server.toLowerCase()
          }`;

    let url = `${domain}/wot/encyclopedia/vehicles/?application_id=${
      server === 'RU' ? LESTA_WOT_APPLICATION_ID : WG_WOT_APPLICATION_ID
    }&fields=tank_id,name,tier,type,is_premium`;

    if (tiers != null && tiers.length > 0) {
      url += `&tier=${tiers.join()}`;
    }

    if (types != null && types.length > 0) {
      url += `&type=${types.join()}`;
    }

    const responseLesta = await fetch(url, {
      method: 'GET',
    });

    const result = (await responseLesta.json()) as IWotApiResponse<{
      [key: number]: IWotApiTank;
    }>;

    return result;
  }
}
