import { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Form,
  Modal,
  Row,
  Stack,
  Table,
} from 'react-bootstrap';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import {
  addWotBlitzTournamentAccountBonusAsync,
  deleteWotBlitzTournamentAccountBonusAsync,
  selectWotBlitzTournamentItem,
} from '../wot-blitz-tournaments-slice';
import { Trash3 } from 'react-bootstrap-icons';
import { useAppDispatch } from '../../../../../redux/store';
import {
  LoadingParts,
  selectLoadingState,
} from '../../../loading/loadingSlice';
import {
  IWotBlitzTournamentAccountBonusDto,
  IWotBlitzTournamentAccountDto,
} from '../../../../../services/api/wot-blitz-tournaments-api.service';
import DatePicker from 'react-datepicker';

export function WotBlitzTournamentBonusesTab() {
  type IRow = Pick<IWotBlitzTournamentAccountDto, 'wotId' | 'name' | 'server'> &
    IWotBlitzTournamentAccountBonusDto & {
      accountId: IWotBlitzTournamentAccountDto['id'];
    };

  const dispatch = useAppDispatch();

  const tournament = useSelector(selectWotBlitzTournamentItem);
  const loading = useSelector(selectLoadingState);

  const [sorting, setSorting] = useState<{
    column: 'name' | 'server' | 'id' | 'bonusScores' | 'date' | 'comment';
    desc: boolean;
  }>({
    column: 'bonusScores',
    desc: true,
  });

  const [sortedList, setSortedList] = useState<IRow[]>([]);

  const [adding, setAdding] = useState(false);
  const [addingId, setAddingId] = useState<string>();
  const [addingAmount, setAddingAmount] = useState<number | undefined>(0);
  const [addingDate, setAddingDate] = useState<Date | null>(new Date());
  const [addingComment, setAddingComment] = useState<string>('');

  const [deleting, setDeleting] = useState<IRow>();

  useEffect(() => {
    const sortedList = [...(tournament?.accounts ?? [])]
      .flatMap((account) => {
        return account.bonuses?.map((bonus) => {
          return {
            accountId: account.id,
            wotId: account.wotId,
            name: account.name,
            server: account.server,
            ...bonus,
          };
        });
      })
      .sort((a, b) => {
        switch (sorting.column) {
          case 'name':
            return sorting.desc
              ? b.name.localeCompare(a.name)
              : a.name.localeCompare(b.name);
          case 'server':
            return sorting.desc
              ? b.server.localeCompare(a.server)
              : a.server.localeCompare(b.server);
          case 'id':
            return sorting.desc ? b.wotId - a.wotId : a.wotId - b.wotId;
          case 'bonusScores':
            return sorting.desc
              ? (b.amount ?? 0) - (a.amount ?? 0)
              : (a.amount ?? 0) - (b.amount ?? 0);
          case 'date':
            return sorting.desc
              ? b.date.localeCompare(a.date)
              : a.date.localeCompare(b.date);
          case 'comment':
            return sorting.desc
              ? b.comment.localeCompare(a.comment)
              : a.comment.localeCompare(b.comment);
          default:
            return sorting.desc
              ? (b.amount ?? 0) - (a.amount ?? 0)
              : (a.amount ?? 0) - (b.amount ?? 0);
        }
      });

    setSortedList(sortedList);
  }, [sorting.column, sorting.desc, tournament?.accounts]);

  return (
    <>
      {adding === false && (
        <Row>
          <Col>
            {tournament == null && (
              <Alert variant="warning">
                {t(
                  'WotBlitzTournamentBonusesTab.NoTournamentAlert',
                  'Please select or save a tournament first',
                )}
              </Alert>
            )}
          </Col>

          <Col>
            <div className="filter-container mt-2">
              <Button
                variant="primary"
                className="mb-3"
                onClick={() => setAdding(true)}
                disabled={tournament == null || deleting != null}
              >
                {t(
                  'WotBlitzTournamentBonusesTab.ButtonAdd',
                  'Add bonus or penalty',
                )}
              </Button>
            </div>
          </Col>
        </Row>
      )}

      <Modal show={deleting != null} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>
            {t(
              'WotBlitzTournamentBonusesTab.DeletingModalTitle',
              'Deleting a bonus or penalty',
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t(
            'WotBlitzTournamentBonusesTab.DeletingModalBody',
            'You are about to delete a bonus or penalty for account {{name}} ({{id}}, {{server}}) from the tournament. This action could not be undone. Are you sure?',
            {
              name: deleting?.name,
              id: deleting?.wotId,
              server: deleting?.server,
            },
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setDeleting(undefined);
            }}
          >
            {t('WotBlitzTournamentBonusesTab.DeletingCancelButton', 'Cancel')}
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              dispatch(
                deleteWotBlitzTournamentAccountBonusAsync(
                  tournament?.id as string,
                  deleting?.accountId as string,
                  deleting?.id as string,
                ),
              );

              setDeleting(undefined);
            }}
          >
            {t('WotBlitzTournamentBonusesTab.DeletingDeleteButton', 'Delete')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Table bordered hover className="mb-5" responsive>
        <thead>
          <tr>
            <th></th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({ column: 'name', desc: !sorting.desc })
                }
              >
                {t('WotBlitzTournamentBonusesTab.ColumnName', 'Name')}
              </Button>
            </th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({ column: 'server', desc: !sorting.desc })
                }
              >
                {t('WotBlitzTournamentBonusesTab.ColumnServer', 'Server')}
              </Button>
            </th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({ column: 'id', desc: !sorting.desc })
                }
              >
                {t('WotBlitzTournamentBonusesTab.ColumnId', 'ID')}
              </Button>
            </th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({ column: 'bonusScores', desc: !sorting.desc })
                }
              >
                {t(
                  'WotBlitzTournamentBonusesTab.ColumnBonusScores',
                  'Bonus scores',
                )}
              </Button>
            </th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({ column: 'date', desc: !sorting.desc })
                }
              >
                {t('WotBlitzTournamentBonusesTab.ColumnDate', 'Date')}
              </Button>
            </th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({
                    column: 'comment',
                    desc: !sorting.desc,
                  })
                }
              >
                {t('WotBlitzTournamentBonusesTab.ColumnComment', 'Comment')}
              </Button>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {adding && (
            <tr key="new">
              <td></td>
              <td>
                <Form.Select
                  onChange={(event) => setAddingId(event.target.value)}
                  value={addingId}
                  isInvalid={addingId == null || addingId.length === 0}
                >
                  {(addingId == null || addingId.length === 0) && (
                    <option value="">
                      {t(
                        'WotBlitzTournamentBonusesTab.SelectAccount',
                        'Select account',
                      )}
                    </option>
                  )}

                  {tournament?.accounts?.map((account) => (
                    <option key={account.id} value={account.id}>
                      {account.name}
                    </option>
                  ))}
                </Form.Select>
              </td>
              <td>
                {tournament?.accounts.find((a) => a.id === addingId)?.server ||
                  '-'}
              </td>
              <td>
                {tournament?.accounts.find((a) => a.id === addingId)?.wotId ||
                  '-'}
              </td>
              <td>
                <Form.Control
                  type="number"
                  value={addingAmount}
                  onChange={(event) => {
                    setAddingAmount(
                      event.target.value === ''
                        ? undefined
                        : +event.target.value,
                    );
                  }}
                  isInvalid={addingAmount == null}
                />
              </td>
              <td>
                <DatePicker
                  showTimeSelect
                  selected={addingDate}
                  onChange={(date) => {
                    setAddingDate(date);
                  }}
                  dateFormat="MMMM d, yyyy HH:mm"
                />
              </td>
              <td>
                <Form.Control
                  value={addingComment}
                  onChange={(event) => {
                    setAddingComment(event.target.value);
                  }}
                />
              </td>
              <td>
                <Stack direction="horizontal">
                  <Button
                    disabled={
                      tournament == null ||
                      addingId == null ||
                      addingId.length === 0 ||
                      addingAmount == null ||
                      loading.find(
                        (x) =>
                          x.key ===
                          LoadingParts.WotBlitzTournamentAccountBonusSave,
                      )?.value === true
                    }
                    onClick={() => {
                      dispatch(
                        addWotBlitzTournamentAccountBonusAsync(
                          tournament?.id as string,
                          addingId as string,
                          addingAmount as number,
                          addingDate as Date,
                          addingComment,
                        ),
                      );
                      setAdding(false);
                      setAddingId('');
                    }}
                    variant="success"
                  >
                    {t('WotBlitzTournamentBonusesTab.ButtonSave', 'Save')}
                  </Button>

                  <Button
                    className="ms-3"
                    onClick={() => {
                      setAdding(false);
                      setAddingId('');
                      setAddingAmount(0);
                      setAddingDate(new Date());
                      setAddingComment('');
                    }}
                    variant="outline-danger"
                  >
                    {t('WotBlitzTournamentBonusesTab.ButtonCancel', 'Cancel')}
                  </Button>
                </Stack>
              </td>
            </tr>
          )}
          {sortedList.length > 0 &&
            [...sortedList].map((row, i) => (
              <tr key={row.id}>
                <td>{i + 1}</td>
                <td>{row.name}</td>
                <td>{row.server}</td>
                <td>{row.wotId}</td>
                <td>{row.amount ?? '-'}</td>
                <td>
                  <DatePicker
                    selected={row.date == null ? undefined : new Date(row.date)}
                    readOnly={true}
                    dateFormat="MMMM d, yyyy HH:mm"
                    onChange={() => {}}
                  />
                </td>
                <td>{row.comment ?? '-'}</td>
                <td>
                  <Button
                    variant="link"
                    disabled={
                      loading.find(
                        (x) =>
                          x.key ===
                          LoadingParts.WotBlitzTournamentAccountBonusSave,
                      )?.value === true || adding === true
                    }
                    onClick={() => {
                      setDeleting(row);
                    }}
                  >
                    <Trash3 size={20} color="red" />
                  </Button>
                </td>
              </tr>
            ))}

          {sortedList.length === 0 && (
            <tr>
              <td colSpan={10} className="text-center">
                {t(
                  'WotBlitzTournamentBonusesTab.NoTableRows',
                  'There are no records matching the selected filters.',
                )}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}
