import { Card, Col, Container, Nav, Row } from 'react-bootstrap';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../redux/store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { WotTournamentWidgetTab } from '../../../main/tournaments/item/widgetTab/wot-tournament-widget-tab';
import {
  selectWidgetWotTournament,
  getWidgetWotTournamentAsync,
} from '../redux/widget-slice';

export function WotTournamentWidgetSettings() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();

  const tournament = useSelector(selectWidgetWotTournament);

  useEffect(() => {
    if (id != null && id !== '' && id !== 'new' && id !== tournament?.id) {
      dispatch(getWidgetWotTournamentAsync(id));
    }
  }, [dispatch, id, tournament?.id]);

  return (
    <Container fluid>
      <Row className="mt-5">
        <Col className="me-5 ms-5">
          <Card>
            <Card.Header>
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link active>
                    <h5>
                      {t(
                        'WotTournamentWidgetSettings.Header',
                        'Widget settings',
                      )}
                    </h5>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Header>

            <Card.Body>
              <WotTournamentWidgetTab tournament={tournament} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
