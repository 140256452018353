import { Button, Stack } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftCircle } from 'react-bootstrap-icons';

export function TournamentHeader(props: { name: string }) {
  const navigate = useNavigate();
  const { game } = useParams<{ game: string }>();

  return (
    <Stack direction="horizontal">
      <Button onClick={() => navigate(`/tournaments/${game}`)} variant="link">
        <ArrowLeftCircle size={35} />
      </Button>
      <h1 className="ps-3 label-no-wrap">{props.name}</h1>
    </Stack>
  );
}
