import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  SettingsApiService,
  SettingsDto,
} from '../../../services/api/settings-api.service';
import { AppThunk, RootState } from '../../../redux/store';
import {
  LoadingParts,
  startLoading,
  stopLoading,
} from '../loading/loadingSlice';
import { showToast } from '../toast/toastSlice';
import { LogService } from '../../../services/log.service';

export interface SettingsState {
  item?: SettingsDto;
}

const InitialState: SettingsState = {
  item: undefined,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: InitialState,
  reducers: {
    resetSettings: (state) => {
      state.item = undefined;
    },
    setSettings: (state, action: PayloadAction<SettingsDto>) => {
      state.item = action.payload;
    },
  },
});

export const { resetSettings, setSettings } = settingsSlice.actions;

export const getSettingsAsync = (): AppThunk => async (dispatch) => {
  try {
    dispatch(startLoading(LoadingParts.SettingsLoad));

    const svc = new SettingsApiService();
    const response = await svc.get();

    if (response.success !== true || response.data == null) {
      dispatch(
        showToast({
          title: 'Error',
          message: response.message ?? 'Unknown error occurred...',
          variant: 'danger',
        }),
      );
    } else {
      dispatch(setSettings(response.data));
    }
  } catch (e) {
    LogService.error(`Error while loading settings`);
    LogService.error(e as Error);
  } finally {
    dispatch(stopLoading(LoadingParts.SettingsLoad));
  }
};

export const updateSettingsAsync =
  (payload: SettingsDto): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading(LoadingParts.SettingsSave));

      const svc = new SettingsApiService();
      const response = await svc.update(payload);

      if (response.success !== true || response.data == null) {
        dispatch(
          showToast({
            title: 'Error',
            message: response.message ?? 'Unknown error occurred...',
            variant: 'danger',
          }),
        );
      } else {
        dispatch(dispatch(setSettings(response.data)));
        dispatch(
          showToast({
            title: 'Success',
            message: 'Settings saved successfully',
            variant: 'success',
          }),
        );
      }
    } catch (e) {
      LogService.error(`Error while saving settings`);
      LogService.error(e as Error);
    } finally {
      dispatch(stopLoading(LoadingParts.SettingsSave));
    }
  };

export const selectSettings = (state: RootState) => state.settings.item;

export default settingsSlice.reducer;
