import './wot-tournament.scss';
import { Card, Col, Nav, Row } from 'react-bootstrap';
import { TournamentHeader } from './components/tournament-header';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../redux/store';
import {
  getWotTournamentAsync,
  selectWotTournament,
} from './redux/wot-tournament-slice';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { WotTournamentGeneralTab } from './generalTab/wot-tournament-general-tab';
import { WotTournamentAccountsTab } from './accountsTab/wot-tournament-accounts-tab';
import { WotTournamentWidgetTab } from './widgetTab/wot-tournament-widget-tab';
import { WotTournamentBonusesTab } from './bonusesTab/wot-tournament-bonuses-tab';
import { WotTournamentTeamsTab } from './teamsTab/wot-tournament-teams-tab';
import { WotTournamentBattlesTab } from './battlesTab/wot-tournament-battles-tab';

export function WotTournament() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const tournament = useSelector(selectWotTournament);

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id != null && id !== '' && id !== 'new' && id !== tournament?.id) {
      dispatch(getWotTournamentAsync(id, false));
    }
  }, [dispatch, id, tournament?.id]);

  return (
    <>
      <Row>
        <Col>
          <TournamentHeader name={tournament?.name ?? 'New'} />
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <Card>
            <Card.Header>
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link
                    active={location.hash.startsWith('#general')}
                    onClick={() => (window.location.hash = 'general')}
                  >
                    <h5>{t('WotTournament.GeneralTab', 'General')}</h5>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    active={location.hash.startsWith('#accounts')}
                    onClick={() => (window.location.hash = 'accounts')}
                  >
                    <h5>{t('WotTournament.AccountsTab', 'Accounts')}</h5>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    active={location.hash.startsWith('#battles')}
                    onClick={() => (window.location.hash = 'battles')}
                  >
                    <h5>{t('WotTournament.BattlesTab', 'Battles')}</h5>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    active={location.hash.startsWith('#bonuses')}
                    onClick={() => (window.location.hash = 'bonuses')}
                  >
                    <h5>
                      {t('WotTournament.BonusesTab', 'Bonuses/Penalties')}
                    </h5>
                  </Nav.Link>
                </Nav.Item>
                {tournament?.rules.mode === 'team' && (
                  <Nav.Item>
                    <Nav.Link
                      active={location.hash.startsWith('#teams')}
                      onClick={() => (window.location.hash = 'teams')}
                    >
                      <h5>{t('WotTournament.TeamsTab', 'Teams')}</h5>
                    </Nav.Link>
                  </Nav.Item>
                )}
                <Nav.Item>
                  <Nav.Link
                    active={location.hash.startsWith('#widget')}
                    onClick={() => (window.location.hash = 'widget')}
                  >
                    <h5>{t('WotTournament.WidgetTab', 'Widget')}</h5>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Header>

            <Card.Body>
              {(location.hash.startsWith('#general') ||
                location.hash === '') && <WotTournamentGeneralTab />}

              {location.hash.startsWith('#accounts') && (
                <WotTournamentAccountsTab />
              )}

              {location.hash.startsWith('#battles') && (
                <WotTournamentBattlesTab />
              )}

              {location.hash.startsWith('#widget') && (
                <WotTournamentWidgetTab tournament={tournament} />
              )}

              {location.hash.startsWith('#bonuses') && (
                <WotTournamentBonusesTab />
              )}

              {location.hash.startsWith('#teams') && <WotTournamentTeamsTab />}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
