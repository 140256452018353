import { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Form,
  Modal,
  Row,
  Stack,
  Table,
} from 'react-bootstrap';
import { WotBlitzServer } from '../../../../../services/api/interfaces/common-api-interfase';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import {
  addWotBlitzTournamentAccountAsync,
  deleteWotBlitzTournamentAccountAsync,
  selectWotBlitzTournamentItem,
} from '../wot-blitz-tournaments-slice';
import { Trash3 } from 'react-bootstrap-icons';
import { useAppDispatch } from '../../../../../redux/store';
import {
  LoadingParts,
  selectLoadingState,
} from '../../../loading/loadingSlice';
import { WotBlitzAccountSearch } from './wot-blitz-account-search';
import { IWotBlitzTournamentAccountDto } from '../../../../../services/api/wot-blitz-tournaments-api.service';

export function WotBlitzTournamentAccountsTab() {
  const dispatch = useAppDispatch();

  const tournament = useSelector(selectWotBlitzTournamentItem);
  const loading = useSelector(selectLoadingState);

  const [sorting, setSorting] = useState<{
    column:
      | 'name'
      | 'server'
      | 'id'
      | 'totalScores'
      | 'bonusScores'
      | 'battleScores'
      | 'battles'
      | 'wins'
      | 'frags'
      | 'damage';
    desc: boolean;
  }>({
    column: 'totalScores',
    desc: true,
  });
  const [sortedList, setSortedList] = useState<IWotBlitzTournamentAccountDto[]>(
    tournament?.accounts ?? [],
  );

  const [adding, setAdding] = useState(false);
  const [addingServer, setAddingServer] = useState<WotBlitzServer>('EU');
  const [addingId, setAddingId] = useState<string>();

  const [deleting, setDeleting] = useState<IWotBlitzTournamentAccountDto>();

  useEffect(() => {
    setSortedList(
      [...(tournament?.accounts ?? [])].sort((a, b) => {
        switch (sorting.column) {
          case 'name':
            return sorting.desc
              ? b.name.localeCompare(a.name)
              : a.name.localeCompare(b.name);
          case 'server':
            return sorting.desc
              ? b.server.localeCompare(a.server)
              : a.server.localeCompare(b.server);
          case 'id':
            return sorting.desc ? b.wotId - a.wotId : a.wotId - b.wotId;
          case 'totalScores':
            return sorting.desc
              ? (b.results?.totalScores ?? 0) - (a.results?.totalScores ?? 0)
              : (a.results?.totalScores ?? 0) - (b.results?.totalScores ?? 0);
          case 'bonusScores':
            return sorting.desc
              ? (b.results?.bonusScores ?? 0) - (a.results?.bonusScores ?? 0)
              : (a.results?.bonusScores ?? 0) - (b.results?.bonusScores ?? 0);
          case 'battleScores':
            return sorting.desc
              ? (b.results?.battleScores ?? 0) - (a.results?.battleScores ?? 0)
              : (a.results?.battleScores ?? 0) - (b.results?.battleScores ?? 0);
          case 'battles':
            return sorting.desc
              ? (b.results?.battles ?? 0) - (a.results?.battles ?? 0)
              : (a.results?.battles ?? 0) - (b.results?.battles ?? 0);
          case 'wins':
            return sorting.desc
              ? (b.results?.wins ?? 0) - (a.results?.wins ?? 0)
              : (a.results?.wins ?? 0) - (b.results?.wins ?? 0);
          case 'frags':
            return sorting.desc
              ? (b.results?.frags ?? 0) - (a.results?.frags ?? 0)
              : (a.results?.frags ?? 0) - (b.results?.frags ?? 0);
          case 'damage':
            return sorting.desc
              ? (b.results?.damage ?? 0) - (a.results?.damage ?? 0)
              : (a.results?.damage ?? 0) - (b.results?.damage ?? 0);
          default:
            return sorting.desc
              ? (b.results?.totalScores ?? 0) - (a.results?.totalScores ?? 0)
              : (a.results?.totalScores ?? 0) - (b.results?.totalScores ?? 0);
        }
      }),
    );
  }, [sorting.column, sorting.desc, tournament?.accounts]);

  return (
    <>
      {adding === false && (
        <Row>
          <Col>
            {tournament == null && (
              <Alert variant="warning">
                {t(
                  'WotBlitzTournamentAccountsTab.NoTournamentAlert',
                  'Please select or save a tournament first',
                )}
              </Alert>
            )}
          </Col>

          <Col>
            <div className="filter-container mt-2">
              <Button
                variant="primary"
                className="mb-3"
                onClick={() => setAdding(true)}
                disabled={tournament == null || deleting != null}
              >
                {t('WotBlitzTournamentAccountsTab.ButtonAdd', 'Add account')}
              </Button>
            </div>
          </Col>
        </Row>
      )}

      <Modal show={deleting != null} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>
            {t(
              'WotBlitzTournamentAccountsTab.DeletingModalTitle',
              'Deleting account',
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t(
            'WotBlitzTournamentAccountsTab.DeletingModalBody',
            'You are about to delete WoT Blitz account {{name}} ({{id}}, {{server}}) from the tournament. This action could not be undone and the account will lose all scores earned in the tournament. Are you sure?',
            {
              name: deleting?.name,
              id: deleting?.id,
              server: deleting?.server,
            },
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setDeleting(undefined);
            }}
          >
            {t('WotBlitzTournamentAccountsTab.DeletingCancelButton', 'Cancel')}
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              dispatch(
                deleteWotBlitzTournamentAccountAsync(
                  tournament?.id as string,
                  deleting?.id as string,
                ),
              );
              setDeleting(undefined);
            }}
          >
            {t('WotBlitzTournamentAccountsTab.DeletingDeleteButton', 'Delete')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Table bordered hover className="mb-5" responsive>
        <thead>
          <tr>
            <th></th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({ column: 'name', desc: !sorting.desc })
                }
              >
                {t('WotBlitzTournamentAccountsTab.ColumnName', 'Name')}
              </Button>
            </th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({ column: 'server', desc: !sorting.desc })
                }
              >
                {t('WotBlitzTournamentAccountsTab.ColumnServer', 'Server')}
              </Button>
            </th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({ column: 'id', desc: !sorting.desc })
                }
              >
                {t('WotBlitzTournamentAccountsTab.ColumnId', 'ID')}
              </Button>
            </th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({ column: 'totalScores', desc: !sorting.desc })
                }
              >
                {t(
                  'WotBlitzTournamentAccountsTab.ColumnTotalScores',
                  'Total scores',
                )}
              </Button>
            </th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({ column: 'bonusScores', desc: !sorting.desc })
                }
              >
                {t(
                  'WotBlitzTournamentAccountsTab.ColumnBonusScores',
                  'Bonus scores',
                )}
              </Button>
            </th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({ column: 'battleScores', desc: !sorting.desc })
                }
              >
                {t(
                  'WotBlitzTournamentAccountsTab.ColumnBattleScores',
                  'Battle scores',
                )}
              </Button>
            </th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({
                    column: 'battles',
                    desc: !sorting.desc,
                  })
                }
              >
                {t('WotBlitzTournamentAccountsTab.ColumnBattles', 'Battles')}
              </Button>
            </th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({
                    column: 'wins',
                    desc: !sorting.desc,
                  })
                }
              >
                {t('WotBlitzTournamentAccountsTab.ColumnWins', 'Wins')}
              </Button>
            </th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({
                    column: 'frags',
                    desc: !sorting.desc,
                  })
                }
              >
                {t('WotBlitzTournamentAccountsTab.ColumnFrags', 'Frags')}
              </Button>
            </th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({
                    column: 'damage',
                    desc: !sorting.desc,
                  })
                }
              >
                {t('WotBlitzTournamentAccountsTab.ColumnDamage', 'Damage')}
              </Button>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {adding && (
            <tr key="new">
              <td></td>
              <td>
                <WotBlitzAccountSearch
                  server={addingServer}
                  onSelect={(selected) => {
                    setAddingId(selected.account_id.toString());
                  }}
                />
              </td>
              <td>
                <Form.Select
                  onChange={(event) =>
                    setAddingServer(event.target.value as WotBlitzServer)
                  }
                  value={addingServer}
                >
                  <option value="EU">EU</option>
                  <option value="RU">RU</option>
                  <option value="NA">NA</option>
                  <option value="ASIA">ASIA</option>
                </Form.Select>
              </td>
              <td>{addingId || '-'}</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>
                <Stack direction="horizontal">
                  <Button
                    disabled={
                      addingId == null ||
                      addingId.length === 0 ||
                      loading.find(
                        (x) =>
                          x.key === LoadingParts.WotBlitzTournamentAccountSave,
                      )?.value === true
                    }
                    onClick={() => {
                      dispatch(
                        addWotBlitzTournamentAccountAsync(
                          tournament?.id as string,
                          +(addingId as string),
                          addingServer,
                        ),
                      );
                      setAdding(false);
                      setAddingId('');
                      setAddingServer('EU');
                    }}
                    variant="success"
                  >
                    {t('WotBlitzTournamentAccountsTab.ButtonSave', 'Save')}
                  </Button>

                  <Button
                    className="ms-3"
                    onClick={() => {
                      setAdding(false);
                      setAddingId('');
                      setAddingServer('EU');
                    }}
                    variant="outline-danger"
                  >
                    {t('WotBlitzTournamentAccountsTab.ButtonCancel', 'Cancel')}
                  </Button>
                </Stack>
              </td>
            </tr>
          )}
          {sortedList.length > 0 &&
            [...sortedList].map((row, i) => (
              <tr key={row.wotId}>
                <td>{i + 1}</td>
                <td>{row.name}</td>
                <td>{row.server}</td>
                <td>{row.wotId}</td>
                <td>{row.results?.totalScores ?? '-'}</td>
                <td>{row.results?.bonusScores ?? '-'}</td>
                <td>{row.results?.battleScores ?? '-'}</td>
                <td>{row.results?.battles ?? '-'}</td>
                <td>{row.results?.wins ?? '-'}</td>
                <td>{row.results?.frags ?? '-'}</td>
                <td>{row.results?.damage ?? '-'}</td>
                <td>
                  <Button
                    variant="link"
                    disabled={
                      loading.find(
                        (x) =>
                          x.key === LoadingParts.WotBlitzTournamentAccountSave,
                      )?.value === true || adding === true
                    }
                    onClick={() => {
                      setDeleting(row);
                    }}
                  >
                    <Trash3 size={20} color="red" />
                  </Button>
                </td>
              </tr>
            ))}

          {sortedList.length === 0 && (
            <tr>
              <td colSpan={12} className="text-center">
                {t(
                  'WotBlitzTournamentAccountsTab.NoTableRows',
                  'There are no records matching the selected filters.',
                )}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}
