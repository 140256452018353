import { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Form,
  Modal,
  Row,
  Stack,
  Table,
} from 'react-bootstrap';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import {
  selectWotBlitzTournamentItem,
  updateWotBlitzTournamentAccountAsync,
} from '../wot-blitz-tournaments-slice';
import { Trash3 } from 'react-bootstrap-icons';
import { useAppDispatch } from '../../../../../redux/store';
import {
  LoadingParts,
  selectLoadingState,
} from '../../../loading/loadingSlice';

export function WotBlitzTournamentTeamsTab() {
  type IRow = {
    name: string;
    accounts: { id: string; name: string }[];
    totalScores: number;
  };

  const dispatch = useAppDispatch();

  const tournament = useSelector(selectWotBlitzTournamentItem);
  const loading = useSelector(selectLoadingState);

  const [sorting, setSorting] = useState<{
    column: 'name' | 'account1' | 'account2' | 'totalScores';
    desc: boolean;
  }>({
    column: 'name',
    desc: true,
  });

  const [sortedList, setSortedList] = useState<IRow[]>([]);

  const [adding, setAdding] = useState(false);
  const [addingName, setAddingName] = useState<string>();
  const [addingAccountIds, setAddingAccountIds] = useState<string[]>([]);

  const [deleting, setDeleting] = useState<IRow>();

  useEffect(() => {
    const sortedList = [...(tournament?.teams ?? [])].sort((a, b) => {
      switch (sorting.column) {
        case 'name':
          return sorting.desc
            ? b.name.localeCompare(a.name)
            : a.name.localeCompare(b.name);
        case 'account1':
          return sorting.desc
            ? b.accounts[0].name.localeCompare(a.accounts[0].name)
            : a.accounts[0].name.localeCompare(b.accounts[0].name);
        case 'account2':
          return sorting.desc
            ? (b.accounts[1]?.name ?? '').localeCompare(
                a.accounts[1]?.name ?? '',
              )
            : (a.accounts[1]?.name ?? '').localeCompare(
                b.accounts[1]?.name ?? '',
              );
        case 'totalScores':
          return sorting.desc
            ? (b.totalScores ?? 0) - (a.totalScores ?? 0)
            : (a.totalScores ?? 0) - (b.totalScores ?? 0);
        default:
          return sorting.desc
            ? b.name.localeCompare(a.name)
            : a.name.localeCompare(b.name);
      }
    });

    setSortedList(sortedList);
  }, [sorting.column, sorting.desc, tournament?.teams]);

  return (
    <>
      {adding === false && (
        <Row>
          <Col>
            {tournament == null && (
              <Alert variant="warning">
                {t(
                  'WotBlitzTournamentTeamsTab.NoTournamentAlert',
                  'Please select or save a tournament first',
                )}
              </Alert>
            )}
          </Col>

          <Col>
            <div className="filter-container mt-2">
              <Button
                variant="primary"
                className="mb-3"
                onClick={() => setAdding(true)}
                disabled={tournament == null || deleting != null}
              >
                {t('WotBlitzTournamentTeamsTab.ButtonAdd', 'Add team')}
              </Button>
            </div>
          </Col>
        </Row>
      )}

      <Modal show={deleting != null} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>
            {t(
              'WotBlitzTournamentTeamsTab.DeletingModalTitle',
              'Deleting a team',
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t(
            'WotBlitzTournamentTeamsTab.DeletingModalBody',
            'You are about to delete the team {{name}} from the tournament. This action could not be undone. Are you sure?',
            {
              name: deleting?.name,
            },
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setDeleting(undefined);
            }}
          >
            {t('WotBlitzTournamentTeamsTab.DeletingCancelButton', 'Cancel')}
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              if (tournament != null && deleting?.accounts[0] != null) {
                dispatch(
                  updateWotBlitzTournamentAccountAsync(
                    tournament.id,
                    deleting.accounts[0].id,
                    null,
                  ),
                );
              }

              if (tournament != null && deleting?.accounts[0] != null) {
                dispatch(
                  updateWotBlitzTournamentAccountAsync(
                    tournament.id,
                    deleting.accounts[1].id,
                    null,
                  ),
                );
              }

              setDeleting(undefined);
            }}
          >
            {t('WotBlitzTournamentTeamsTab.DeletingDeleteButton', 'Delete')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Table bordered hover className="mb-5" responsive>
        <thead>
          <tr>
            <th></th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({ column: 'name', desc: !sorting.desc })
                }
              >
                {t('WotBlitzTournamentTeamsTab.ColumnName', 'Name')}
              </Button>
            </th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({ column: 'account1', desc: !sorting.desc })
                }
              >
                {t('WotBlitzTournamentTeamsTab.ColumnAccount1', 'Account 1')}
              </Button>
            </th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({ column: 'account2', desc: !sorting.desc })
                }
              >
                {t('WotBlitzTournamentTeamsTab.ColumnAccount2', 'Account 2')}
              </Button>
            </th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({ column: 'totalScores', desc: !sorting.desc })
                }
              >
                {t(
                  'WotBlitzTournamentTeamsTab.ColumnTotalScores',
                  'Total scores',
                )}
              </Button>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {adding && (
            <tr key="new">
              <td></td>
              <td>
                <Form.Control
                  value={addingName}
                  onChange={(event) => {
                    setAddingName(event.target.value);
                  }}
                  isInvalid={
                    addingName == null ||
                    addingName.length === 0 ||
                    tournament?.accounts?.find((a) => a.team === addingName) !=
                      null
                  }
                />
              </td>
              <td>
                <Form.Select
                  onChange={(event) => {
                    const updated = [...addingAccountIds];
                    updated[0] = event.target.value;
                    setAddingAccountIds(updated);
                  }}
                  value={addingAccountIds[0]}
                  isInvalid={
                    addingAccountIds[0] == null ||
                    addingAccountIds[0].length === 0
                  }
                >
                  {(addingAccountIds[0] == null ||
                    addingAccountIds[0].length === 0) && (
                    <option value="">
                      {t(
                        'WotBlitzTournamentTeamsTab.SelectAccount',
                        'Select account',
                      )}
                    </option>
                  )}

                  {tournament?.accounts
                    ?.filter(
                      (account) =>
                        account.team == null || account.team.length === 0,
                    )
                    .filter((account) => account.id !== addingAccountIds[1])
                    .map((account) => (
                      <option key={account.id} value={account.id}>
                        {account.name}
                      </option>
                    ))}
                </Form.Select>
              </td>
              <td>
                <Form.Select
                  onChange={(event) => {
                    const updated = [...addingAccountIds];
                    updated[1] = event.target.value;
                    setAddingAccountIds(updated);
                  }}
                  value={addingAccountIds[1]}
                  isInvalid={
                    addingAccountIds[1] == null ||
                    addingAccountIds[1].length === 0
                  }
                >
                  {(addingAccountIds[1] == null ||
                    addingAccountIds[1].length === 0) && (
                    <option value="">
                      {t(
                        'WotBlitzTournamentTeamsTab.SelectAccount',
                        'Select account',
                      )}
                    </option>
                  )}

                  {tournament?.accounts
                    ?.filter(
                      (account) =>
                        account.team == null || account.team.length === 0,
                    )
                    .filter((account) => account.id !== addingAccountIds[0])
                    .map((account) => (
                      <option key={account.id} value={account.id}>
                        {account.name}
                      </option>
                    ))}
                </Form.Select>
              </td>
              <td>
                {(tournament?.accounts?.find(
                  (a) => a.id === addingAccountIds[0],
                )?.results.totalScores ?? 0) +
                  (tournament?.accounts?.find(
                    (a) => a.id === addingAccountIds[1],
                  )?.results.totalScores ?? 0)}
              </td>
              <td>
                <Stack direction="horizontal">
                  <Button
                    disabled={
                      tournament == null ||
                      addingName == null ||
                      addingName.length === 0 ||
                      addingAccountIds[0] == null ||
                      addingAccountIds[0].length === 0 ||
                      addingAccountIds[1] == null ||
                      addingAccountIds[1].length === 0 ||
                      tournament?.accounts?.find(
                        (a) => a.team === addingName,
                      ) != null ||
                      loading.find(
                        (x) =>
                          x.key === LoadingParts.WotBlitzTournamentAccountSave,
                      )?.value === true
                    }
                    onClick={() => {
                      if (
                        addingName != null &&
                        addingName.length > 0 &&
                        tournament != null &&
                        addingAccountIds[0] != null
                      ) {
                        dispatch(
                          updateWotBlitzTournamentAccountAsync(
                            tournament.id,
                            addingAccountIds[0],
                            addingName,
                          ),
                        );
                      }

                      if (
                        addingName != null &&
                        addingName.length > 0 &&
                        tournament != null &&
                        addingAccountIds[1] != null
                      ) {
                        dispatch(
                          updateWotBlitzTournamentAccountAsync(
                            tournament.id,
                            addingAccountIds[1],
                            addingName,
                          ),
                        );
                      }
                      setAdding(false);
                      setAddingName(undefined);
                      setAddingAccountIds([]);
                    }}
                    variant="success"
                  >
                    {t('WotBlitzTournamentTeamsTab.ButtonSave', 'Save')}
                  </Button>

                  <Button
                    className="ms-3"
                    onClick={() => {
                      setAdding(false);
                      setAddingName(undefined);
                      setAddingAccountIds([]);
                    }}
                    variant="outline-danger"
                  >
                    {t('WotBlitzTournamentTeamsTab.ButtonCancel', 'Cancel')}
                  </Button>
                </Stack>
              </td>
            </tr>
          )}
          {sortedList.length > 0 &&
            [...sortedList].map((row, i) => (
              <tr key={row.name}>
                <td>{i + 1}</td>
                <td>{row.name}</td>
                <td>{row.accounts[0]?.name}</td>
                <td>{row.accounts[1]?.name}</td>
                <td>{row.totalScores}</td>
                <td>
                  <Button
                    variant="link"
                    disabled={
                      loading.find(
                        (x) =>
                          x.key === LoadingParts.WotBlitzTournamentAccountSave,
                      )?.value === true || adding === true
                    }
                    onClick={() => {
                      setDeleting(row);
                    }}
                  >
                    <Trash3 size={20} color="red" />
                  </Button>
                </td>
              </tr>
            ))}

          {sortedList.length === 0 && (
            <tr>
              <td colSpan={10} className="text-center">
                {t(
                  'WotBlitzTournamentTeamsTab.NoTableRows',
                  'There are no records matching the selected filters.',
                )}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}
