import { Route, Routes, useSearchParams } from 'react-router-dom';
import { WotBlitzTournamentWidgetTable } from './wot-blitz/table/wot-blitz-tournament-widget-table';
import { WotBlitzTournamentWidgetIndividual } from './wot-blitz/individual/wot-blitz-tournament-widget-individual';
import { nameof } from '../../utils/nameof.util';
import { WotBlitzTournamentWidgetSettings } from './wot-blitz/settings/wot-blitz-tournament-widget-settings';
import { WotTournamentWidgetTable } from './wot/table/wot-tournament-widget-table';
import { WotTournamentWidgetIndividual } from './wot/individual/wot-tournament-widget-individual';
import { WotTournamentWidgetSettings } from './wot/settings/wot-tournament-widget-settings';
import { WotServer } from '../../services/api/interfaces/common-api-interfase';

export type WidgetParams = {
  type: 'table' | 'individual';
  accountId: number;
  lang: 'en' | 'ru' | 'uk';
  showTime: number;
  hideTime: number;
  overlay: 'top' | 'bottom' | 'left' | 'right';
  keyValue: 'avgDamage' | 'scores';
  server: WotServer;
};

export function Widgets() {
  let [searchParams] = useSearchParams();

  return (
    <Routes>
      {searchParams.get(nameof<WidgetParams>('type')) === 'table' && (
        <>
          <Route
            path="tournaments/wot_blitz/:id"
            element={<WotBlitzTournamentWidgetTable />}
          />
          <Route
            path="tournaments/wot/:id"
            element={<WotTournamentWidgetTable />}
          />
        </>
      )}

      {searchParams.get(nameof<WidgetParams>('type')) === 'individual' && (
        <>
          <Route
            path="tournaments/wot_blitz/:id"
            element={<WotBlitzTournamentWidgetIndividual />}
          />
          <Route
            path="tournaments/wot/:id"
            element={<WotTournamentWidgetIndividual />}
          />
        </>
      )}

      <Route
        path="tournaments/wot_blitz/:id/settings"
        element={<WotBlitzTournamentWidgetSettings />}
      />

      <Route
        path="tournaments/wot/:id/settings"
        element={<WotTournamentWidgetSettings />}
      />
    </Routes>
  );
}
