import './wot-tournament-widget-table.scss';
import { useEffect, useMemo, useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../redux/store';
import { useSelector } from 'react-redux';
import React from 'react';
import { OffcanvasPlacement } from 'react-bootstrap/esm/Offcanvas';
import { WidgetParams } from '../../widgets';
import { nameof } from '../../../../utils/nameof.util';
import { LogService } from '../../../../services/log.service';
import {
  getWotTournamentFirstPlaceAccountPublicAsync,
  selectWotTournamentFirstPlaceAccountPublic,
} from '../../../main/tournaments/item/redux/wot-tournament-account-slice';
import { WotServer } from '../../../../services/api/interfaces/common-api-interfase';
import {
  getWidgetWotTournamentAsync,
  selectWidgetWotTournament,
} from '../redux/widget-slice';

export function WotTournamentWidgetTable() {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const tournament = useSelector(selectWidgetWotTournament);
  const firstPlaceAccount = useSelector(
    selectWotTournamentFirstPlaceAccountPublic,
  );

  const { id: tournamentId } = useParams<{ id: string }>();
  let [searchParams] = useSearchParams();

  const [show, setShow] = useState(true);
  const [firstShow, setFirstShow] = useState(true);

  // parse search params
  const accountId: WidgetParams['accountId'] | undefined =
    searchParams.get(nameof<WidgetParams>('accountId')) == null ||
    searchParams.get(nameof<WidgetParams>('accountId')) === ''
      ? undefined
      : +searchParams.get(nameof<WidgetParams>('accountId'))!;

  const server: WidgetParams['server'] | undefined =
    searchParams.get(nameof<WidgetParams>('server')) == null ||
    searchParams.get(nameof<WidgetParams>('server')) === ''
      ? undefined
      : (searchParams.get(
          nameof<WidgetParams>('server'),
        ) as WidgetParams['server'])!;

  const overlay: WidgetParams['overlay'] =
    (searchParams.get(
      nameof<WidgetParams>('overlay'),
    ) as WidgetParams['overlay']) ?? 'right';

  const overlayDirection: OffcanvasPlacement =
    overlay === 'left' ? 'start' : overlay === 'right' ? 'end' : overlay;

  let hideTime: WidgetParams['hideTime'] =
    searchParams.get(nameof<WidgetParams>('hideTime')) == null
      ? 0
      : +searchParams.get(nameof<WidgetParams>('hideTime'))!;

  if (hideTime < 0) {
    hideTime = 0;
  } else if (hideTime > 30000) {
    hideTime = 30000;
  }

  let showTime: WidgetParams['showTime'] =
    searchParams.get(nameof<WidgetParams>('showTime')) == null
      ? 0
      : +searchParams.get(nameof<WidgetParams>('showTime'))!;

  if (showTime < 0) {
    showTime = 0;
  } else if (showTime > 30000) {
    showTime = 30000;
  }

  // account
  const tournamentAccount = tournament?.accounts.find(
    (a) =>
      a.wotId === accountId &&
      (server == null || a.server.toUpperCase() === server.toUpperCase()),
  );

  // players
  const chart = useMemo<{
    players: Array<{
      accountId: string | null;
      name: string;
      scores: number;
      wotId: number | null;
      server: WotServer | null;
    }>;
    playerIndex: number;
    place:
      | '0'
      | '1'
      | '2'
      | '3'
      | '4'
      | '5'
      | '6'
      | '7'
      | '8'
      | 'center'
      | 'prelast'
      | 'last';
  }>(() => {
    if (tournament != null && tournamentAccount != null) {
      if (tournament.rules.mode === 'individual') {
        const accounts = [...tournament.accounts].sort(
          (a, b) => (a.results?.place ?? 0) - (b.results?.place ?? 0),
        );

        const players = accounts.map((a) => ({
          accountId: a.accountId,
          name: a.name,
          scores: a.results?.totalScores ?? 0,
          wotId: a.wotId,
          server: a.server,
        }));

        const currentAccountIndex = accounts.findIndex(
          (a) => a.wotId === tournamentAccount.wotId,
        );

        let playerIndex: number = 0;
        let place:
          | '0'
          | '1'
          | '2'
          | '3'
          | '4'
          | '5'
          | '6'
          | '7'
          | '8'
          | 'center'
          | 'prelast'
          | 'last' = '0';
        if (currentAccountIndex >= 0) {
          playerIndex = currentAccountIndex;

          if (currentAccountIndex <= 8) {
            place = `${currentAccountIndex}` as any;
          } else if (currentAccountIndex === tournament.accounts.length - 1) {
            place = 'last';
          } else if (currentAccountIndex === tournament.accounts.length - 2) {
            place = 'prelast';
          } else {
            place = 'center';
          }
        }

        return { players, place, playerIndex };
      } else if (tournament.rules.mode === 'team') {
        LogService.error('Team mode tournaments are not supported');
      }
    }

    return { players: [], place: '0', playerIndex: 0 };
  }, [tournament, tournamentAccount]);

  // get tournament by id
  useEffect(() => {
    if (tournamentId != null && tournamentId !== '' && tournamentId !== 'new') {
      dispatch(getWidgetWotTournamentAsync(tournamentId));

      const interval = setInterval(
        () => dispatch(getWidgetWotTournamentAsync(tournamentId)),
        1000 * 30,
      );
      return () => clearInterval(interval);
    }
  }, [dispatch, tournamentId]);

  // get first place account
  useEffect(() => {
    const wotId = chart.players[0]?.wotId;
    const server = chart.players[0]?.server;

    if (tournament?.id != null && wotId != null && server != null) {
      dispatch(
        getWotTournamentFirstPlaceAccountPublicAsync(
          tournament.id,
          wotId,
          server,
        ),
      );

      const interval = setInterval(
        () =>
          dispatch(
            getWotTournamentFirstPlaceAccountPublicAsync(
              tournament.id,
              wotId,
              server,
            ),
          ),
        1000 * 30,
      );
      return () => clearInterval(interval);
    }
  }, [dispatch, chart.players, tournament?.id]);

  // hide widget by time
  useEffect(() => {
    LogService.debug(
      'WotTournamentWidgetTable - useEffect - hide widget by time',
    );

    if (hideTime === 0) {
      setShow(true);
      return;
    }

    if (showTime === 0) {
      setShow(false);
      return;
    }

    if (firstShow) {
      const timeout = setTimeout(() => {
        setShow(false);
        setFirstShow(false);
      }, showTime * 1000);

      return () => clearTimeout(timeout);
    } else {
      const interval = setInterval(
        () => {
          setShow(false);
        },
        (hideTime + showTime) * 1000,
      );

      return () => clearInterval(interval);
    }
  }, [firstShow, hideTime, showTime]);

  // show widget by time
  useEffect(() => {
    LogService.debug(
      'WotTournamentWidgetTable - useEffect - show widget by time',
    );

    if (hideTime === 0) {
      setShow(true);
      return;
    }

    if (showTime === 0) {
      setShow(false);
      return;
    }

    const interval = setInterval(
      () => {
        setShow(true);
      },
      (hideTime + showTime) * 1000,
    );

    return () => clearInterval(interval);
  }, [hideTime, showTime]);

  return (
    <Offcanvas
      id="wot-tournament-widget-table-offcanvas"
      show={show}
      placement={overlayDirection}
      keyboard={false}
      backdrop={false}
    >
      <Offcanvas.Body>
        <div
          className={`wot-tournament-widget-table-container wot-tournament-widget-table-place-${chart.place}`}
        >
          <p className="wot-tournament-widget-table-table-name">
            {tournament?.name}
          </p>

          <p className="wot-tournament-widget-table-total-players">
            {t(
              'WotTournamentWidgetTable.TotalPlayers',
              'Players: {{totalPlayers}}',
              { totalPlayers: chart.players.length },
            )}
          </p>

          {chart.players.length > 0 && (
            <>
              <p className="wot-tournament-widget-table-player-scores-first-place">
                {(firstPlaceAccount?.accounts[0]?.battles ?? [])
                  .filter((b) => b.numberOfBattles === 1)
                  .sort((a, b) => b.scores - a.scores)[0]?.scores ?? '-'}
              </p>

              <p className="wot-tournament-widget-table-player-scores-second-place">
                {(firstPlaceAccount?.accounts[0]?.battles ?? [])
                  .filter((b) => b.numberOfBattles === 1)
                  .sort((a, b) => b.scores - a.scores)[1]?.scores ?? '-'}
              </p>

              <p className="wot-tournament-widget-table-player-scores-third-place">
                {(firstPlaceAccount?.accounts[0]?.battles ?? [])
                  .filter((b) => b.numberOfBattles === 1)
                  .sort((a, b) => b.scores - a.scores)[2]?.scores ?? '-'}
              </p>

              {chart.place !== 'last' &&
                chart.place !== 'prelast' &&
                chart.place !== 'center' && (
                  <>
                    {chart.players.slice(0, 9).map((player, index) => (
                      <React.Fragment key={player.accountId}>
                        <p
                          className={`wot-tournament-widget-table-player-place-${index}`}
                        >
                          {index + 1}
                        </p>
                        <p
                          className={`wot-tournament-widget-table-player-name-${index}`}
                        >
                          {player.name}
                        </p>
                        <p
                          className={`wot-tournament-widget-table-player-scores-${index}`}
                        >
                          {player.scores ?? '-'}
                        </p>
                      </React.Fragment>
                    ))}
                  </>
                )}

              {chart.place === 'last' && (
                <>
                  {chart.players.slice(0, 6).map((player, index) => (
                    <React.Fragment key={player.accountId}>
                      <p
                        className={`wot-tournament-widget-table-player-place-${index}`}
                      >
                        {index + 1}
                      </p>
                      <p
                        className={`wot-tournament-widget-table-player-name-${index}`}
                      >
                        {player.name}
                      </p>
                      <p
                        className={`wot-tournament-widget-table-player-scores-${index}`}
                      >
                        {player.scores ?? '-'}
                      </p>
                    </React.Fragment>
                  ))}

                  <p
                    className={`wot-tournament-widget-table-player-place-prelast`}
                  >
                    {chart.players.length - 1}
                  </p>
                  <p
                    className={`wot-tournament-widget-table-player-name-prelast`}
                  >
                    {chart.players[chart.players.length - 2]?.name}
                  </p>
                  <p
                    className={`wot-tournament-widget-table-player-scores-prelast`}
                  >
                    {chart.players[chart.players.length - 2]?.scores ?? '-'}
                  </p>

                  <p
                    className={`wot-tournament-widget-table-player-place-last`}
                  >
                    {chart.players.length}
                  </p>
                  <p className={`wot-tournament-widget-table-player-name-last`}>
                    {chart.players[chart.players.length - 1]?.name}
                  </p>
                  <p
                    className={`wot-tournament-widget-table-player-scores-last`}
                  >
                    {chart.players[chart.players.length - 1]?.scores ?? '-'}
                  </p>
                </>
              )}

              {chart.place === 'prelast' && (
                <>
                  {chart.players.slice(0, 5).map((player, index) => (
                    <React.Fragment key={player.accountId}>
                      <p
                        className={`wot-tournament-widget-table-player-place-${index}`}
                      >
                        {index + 1}
                      </p>
                      <p
                        className={`wot-tournament-widget-table-player-name-${index}`}
                      >
                        {player.name}
                      </p>
                      <p
                        className={`wot-tournament-widget-table-player-scores-${index}`}
                      >
                        {player?.scores ?? '-'}
                      </p>
                    </React.Fragment>
                  ))}

                  <p
                    className={`wot-tournament-widget-table-player-place-preprelast`}
                  >
                    {chart.players.length - 2}
                  </p>
                  <p
                    className={`wot-tournament-widget-table-player-name-preprelast`}
                  >
                    {chart.players[chart.players.length - 3]?.name}
                  </p>
                  <p
                    className={`wot-tournament-widget-table-player-scores-preprelast`}
                  >
                    {chart.players[chart.players.length - 3]?.scores ?? '-'}
                  </p>

                  <p
                    className={`wot-tournament-widget-table-player-place-prelast`}
                  >
                    {chart.players.length - 1}
                  </p>
                  <p
                    className={`wot-tournament-widget-table-player-name-prelast`}
                  >
                    {chart.players[chart.players.length - 2]?.name}
                  </p>
                  <p
                    className={`wot-tournament-widget-table-player-scores-prelast`}
                  >
                    {chart.players[chart.players.length - 2]?.scores ?? '-'}
                  </p>

                  <p
                    className={`wot-tournament-widget-table-player-place-last`}
                  >
                    {chart.players.length}
                  </p>
                  <p className={`wot-tournament-widget-table-player-name-last`}>
                    {chart.players[chart.players.length - 1]?.name}
                  </p>
                  <p
                    className={`wot-tournament-widget-table-player-scores-last`}
                  >
                    {chart.players[chart.players.length - 1]?.scores ?? '-'}
                  </p>
                </>
              )}

              {chart.place === 'center' && (
                <>
                  {chart.players.slice(0, 4).map((player, index) => (
                    <React.Fragment key={player.accountId}>
                      <p
                        className={`wot-tournament-widget-table-player-place-${index}`}
                      >
                        {index + 1}
                      </p>
                      <p
                        className={`wot-tournament-widget-table-player-name-${index}`}
                      >
                        {player?.name}
                      </p>
                      <p
                        className={`wot-tournament-widget-table-player-scores-${index}`}
                      >
                        {player?.scores ?? '-'}
                      </p>
                    </React.Fragment>
                  ))}

                  <p
                    className={`wot-tournament-widget-table-player-place-precenter`}
                  >
                    {chart.playerIndex}
                  </p>
                  <p
                    className={`wot-tournament-widget-table-player-name-precenter`}
                  >
                    {chart.players[chart.playerIndex - 1]?.name}
                  </p>
                  <p
                    className={`wot-tournament-widget-table-player-scores-precenter`}
                  >
                    {chart.players[chart.playerIndex - 1]?.scores ?? '-'}
                  </p>

                  <p
                    className={`wot-tournament-widget-table-player-place-center`}
                  >
                    {chart.playerIndex + 1}
                  </p>
                  <p
                    className={`wot-tournament-widget-table-player-name-center`}
                  >
                    {chart.players[chart.playerIndex]?.name}
                  </p>
                  <p
                    className={`wot-tournament-widget-table-player-scores-center`}
                  >
                    {chart.players[chart.playerIndex]?.scores ?? '-'}
                  </p>

                  <p
                    className={`wot-tournament-widget-table-player-place-postcenter`}
                  >
                    {chart.playerIndex + 2}
                  </p>
                  <p
                    className={`wot-tournament-widget-table-player-name-postcenter`}
                  >
                    {chart.players[chart.playerIndex + 1]?.name}
                  </p>
                  <p
                    className={`wot-tournament-widget-table-player-scores-postcenter`}
                  >
                    {chart.players[chart.playerIndex + 1]?.scores ?? '-'}
                  </p>
                </>
              )}
            </>
          )}
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}
