import './tournaments.scss';
import { WotBlitzTournamentsList } from './wot-blitz/wot-blitz-tournaments-list';
import { Route, Routes, useParams } from 'react-router-dom';
import { WotBlitzTournament } from './wot-blitz/wot-blitz-tournament';
import { WotTournamentsList } from './list/wot-tournaments-list';
import { WotTournament } from './item/wot-tournament';

export function Tournaments() {
  const { game } = useParams<{ game: string }>();

  return (
    <Routes>
      {game === 'wot_blitz' && (
        <Route path="/" element={<WotBlitzTournamentsList />} />
      )}
      {game === 'wot_blitz' && (
        <Route path="/:id" element={<WotBlitzTournament />} />
      )}

      {game === 'wot' && <Route path="/" element={<WotTournamentsList />} />}
      {game === 'wot' && <Route path="/:id" element={<WotTournament />} />}
    </Routes>
  );
}
