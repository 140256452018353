import fetchApi from './fetch-api';
import { paths } from './interfaces/api-interfase';

export class AuthApiService {
  async loginLocal(
    payload: paths['/api/auth/login/local']['post']['requestBody']['content']['application/json'],
  ): Promise<
    paths['/api/auth/login/local']['post']['responses']['200']['content']['application/json']
  > {
    const response = await fetchApi('auth/login/local', {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body =
      (await response.json()) as paths['/api/auth/login/local']['post']['responses']['200']['content']['application/json'];
    return body;
  }

  async register(
    payload: paths['/api/auth/register']['post']['requestBody']['content']['application/json'],
  ): Promise<
    paths['/api/auth/register']['post']['responses']['200']['content']['application/json']
  > {
    const response = await fetchApi('auth/register', {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body =
      (await response.json()) as paths['/api/auth/register']['post']['responses']['200']['content']['application/json'];
    return body;
  }

  async refresh(): Promise<
    paths['/api/auth/refresh']['post']['responses']['200']['content']['application/json']
  > {
    const response = await fetchApi('auth/refresh', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body =
      (await response.json()) as paths['/api/auth/refresh']['post']['responses']['200']['content']['application/json'];
    return body;
  }
}
