import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../redux/store';

export type ToastVariant =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'light'
  | 'dark';

export interface ToastState {
  show: boolean;
  title: string;
  message: string;
  variant: ToastVariant;
}

const InitialState: ToastState = {
  show: false,
  title: '',
  message: '',
  variant: 'primary',
};

export const toastSlice = createSlice({
  name: 'toast',
  initialState: InitialState,
  reducers: {
    showToast: (
      state,
      action: PayloadAction<{
        title: string;
        message: string;
        variant: ToastVariant;
      }>,
    ) => {
      state.show = true;
      state.title = action.payload.title;
      state.message = action.payload.message;
      state.variant = action.payload.variant;
    },
    hideToast: (state) => {
      state.show = false;
      state.title = '';
      state.message = '';
    },
  },
});

export const { showToast, hideToast } = toastSlice.actions;

export const selectToast = (state: RootState) => state.toast;

export default toastSlice.reducer;
