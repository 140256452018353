import { useSelector } from 'react-redux';
import {
  getWotBlitzTournamentsAsync,
  selectWotBlitzTournamentsList,
  setWotBlitzTournamentItem,
} from './wot-blitz-tournaments-slice';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../redux/store';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { TournamentsListHeader } from '../list/components/tournaments-list-header';
import { formatDate } from '../../../../utils/date-utils';
import { WotBlitzTournamentShortDto } from '../../../../services/api/wot-blitz-tournaments-api.service';
import { Game } from '../tournaments.interface';
import { setWotTournamentItem } from '../item/redux/wot-tournament-slice';

export function WotBlitzTournamentsList() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { game } = useParams<{ game: string }>();
  const navigate = useNavigate();

  const wotBlitzTournaments = useSelector(selectWotBlitzTournamentsList);

  const [onlyPublic, setOnlyPublic] = useState<boolean>(false);
  const [sorting, setSorting] = useState<{
    column: 'name' | 'startDate' | 'endDate' | 'status' | 'calculatedAt';
    desc: boolean;
  }>({
    column: 'name',
    desc: true,
  });
  const [sortedList, setSortedList] =
    useState<WotBlitzTournamentShortDto[]>(wotBlitzTournaments);

  useEffect(() => {
    // TODO: why is loading twice?
    dispatch(getWotBlitzTournamentsAsync());
  }, [dispatch]);

  useEffect(() => {
    setSortedList(
      [...wotBlitzTournaments]
        .filter((tournament) => {
          if (onlyPublic) {
            return tournament.status === 'Public';
          }

          return true;
        })
        .sort((a, b) => {
          switch (sorting.column) {
            case 'name':
              return sorting.desc
                ? b.name.localeCompare(a.name)
                : a.name.localeCompare(b.name);
            case 'startDate':
              return sorting.desc
                ? b.startAt.localeCompare(a.startAt)
                : a.startAt.localeCompare(b.startAt);
            case 'endDate':
              return sorting.desc
                ? b.endAt.localeCompare(a.endAt)
                : a.endAt.localeCompare(b.endAt);
            case 'calculatedAt':
              return sorting.desc
                ? (b.calculatedAt ?? '').localeCompare(a.calculatedAt ?? '')
                : (a.calculatedAt ?? '').localeCompare(b.calculatedAt ?? '');
            case 'status':
              return sorting.desc
                ? b.status.localeCompare(a.status)
                : a.status.localeCompare(b.status);
            default:
              return sorting.desc
                ? b.name.localeCompare(a.name)
                : a.name.localeCompare(b.name);
          }
        }),
    );
  }, [onlyPublic, sorting.column, sorting.desc, wotBlitzTournaments]);

  const handleGameChange = (game: Game) => {
    navigate(`/tournaments/${game}`);
  };

  const handleNewTournament = () => {
    dispatch(setWotBlitzTournamentItem(null));
    dispatch(setWotTournamentItem(null));
    navigate(`/tournaments/${game}/new`);
  };

  return (
    <>
      <Row>
        <TournamentsListHeader
          onlyPublic={onlyPublic}
          onOnlyPublicChange={() => setOnlyPublic(!onlyPublic)}
          game={game as Game}
          onGameChange={(game: Game) => handleGameChange(game)}
          onNewTournamentClick={() => handleNewTournament()}
        />
      </Row>

      <Row className="mt-3">
        <Col>
          <Table bordered hover className="mb-5" responsive>
            <thead>
              <tr>
                <th></th>
                <th>
                  <Button
                    variant="link"
                    onClick={() =>
                      setSorting({ column: 'name', desc: !sorting.desc })
                    }
                  >
                    {t('WotBlitzTournamentsList.ColumnName', 'Name')}
                  </Button>
                </th>
                <th>
                  <Button
                    variant="link"
                    onClick={() =>
                      setSorting({ column: 'startDate', desc: !sorting.desc })
                    }
                  >
                    {t('WotBlitzTournamentsList.ColumnStartDate', 'Start')}
                  </Button>
                </th>
                <th>
                  <Button
                    variant="link"
                    onClick={() =>
                      setSorting({ column: 'endDate', desc: !sorting.desc })
                    }
                  >
                    {t('WotBlitzTournamentsList.ColumnEndDate', 'End')}
                  </Button>
                </th>
                <th>
                  <Button
                    variant="link"
                    onClick={() =>
                      setSorting({ column: 'status', desc: !sorting.desc })
                    }
                  >
                    {t('WotBlitzTournamentsList.ColumnStatus', 'Status')}
                  </Button>
                </th>
                <th>
                  <Button
                    variant="link"
                    onClick={() =>
                      setSorting({
                        column: 'calculatedAt',
                        desc: !sorting.desc,
                      })
                    }
                  >
                    {t(
                      'WotBlitzTournamentsList.ColumnCalculatedAt',
                      'Calculated',
                    )}
                  </Button>
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedList.length > 0 &&
                [...sortedList].map((tournament, i) => (
                  <tr key={tournament.id}>
                    <td>{i + 1}</td>
                    <td>
                      <Link to={`/tournaments/wot_blitz/${tournament.id}`}>
                        {tournament.name}
                      </Link>
                    </td>
                    <td>
                      {tournament.startAt == null
                        ? undefined
                        : formatDate(new Date(tournament.startAt))}
                    </td>
                    <td>
                      {tournament.endAt == null
                        ? undefined
                        : formatDate(new Date(tournament.endAt))}
                    </td>
                    <td>{tournament.status}</td>
                    <td>
                      {tournament.calculatedAt == null
                        ? '-'
                        : formatDate(new Date(tournament.calculatedAt))}
                    </td>
                  </tr>
                ))}

              {sortedList.length === 0 && (
                <tr>
                  <td colSpan={10} className="text-center">
                    {t(
                      'WotBlitzTournamentsList.NoTableRows',
                      'There are no records matching the selected filters.',
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
}
