import fetch from 'cross-fetch';
import { WotBlitzServer } from '../../../../../services/api/interfaces/common-api-interfase';
import {
  IWotBlitzApiAccountSearch,
  IWotBlitzApiResponse,
} from './wot-blitz-api-interface';
import {
  LESTA_WOT_BLITZ_APPLICATION_ID,
  WG_WOT_BLITZ_APPLICATION_ID,
} from '../../../../../configs/config';

export class WotBlitzApiService {
  public async searchAccount(
    search: string,
    server: WotBlitzServer,
  ): Promise<IWotBlitzApiResponse<IWotBlitzApiAccountSearch[]>> {
    let domain =
      server === 'RU'
        ? 'https://papi.tanksblitz.ru'
        : `https://api.wotblitz.${
            server === 'NA' ? 'com' : server.toLowerCase()
          }`;

    let url = `${domain}/wotb/account/list/?application_id=${
      server === 'RU'
        ? LESTA_WOT_BLITZ_APPLICATION_ID
        : WG_WOT_BLITZ_APPLICATION_ID
    }&search=${search}`;

    const response = await fetch(url, {
      method: 'GET',
    });

    const result = (await response.json()) as IWotBlitzApiResponse<
      IWotBlitzApiAccountSearch[]
    >;

    return result;
  }
}
