import './Header.scss';
import {
  Navbar,
  Container,
  Nav,
  Button,
  Spinner,
  Stack,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectLoadingState } from '../loading/loadingSlice';
import { getUserMeAsync, selectUser } from '../user/userSlice';
import { useTranslation } from 'react-i18next';
import { ReactComponent as HeaderIcon } from '../../../images/header.svg';
import { useAppDispatch } from '../../../redux/store';
import { Gear } from 'react-bootstrap-icons';

export function Header() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const user = useSelector(selectUser);
  const loading = useSelector(selectLoadingState);

  const handleLogout = () => {
    localStorage.removeItem('jwt');
    dispatch(getUserMeAsync());
    navigate('/');
  };

  return (
    <Navbar expand="lg" className="bg-metal mb-3">
      <Container fluid className="header-container">
        <Navbar.Brand
          className="header-icon-container"
          onClick={() => navigate('/')}
        >
          <HeaderIcon />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="navbar-nav" />

        <Navbar.Collapse>
          <Nav className="me-auto">
            <Nav.Link className="me-3" onClick={() => navigate(`/tournaments`)}>
              <span className="header-menu-item label-no-wrap">
                {t('Header.Tournaments', 'Tournaments')}
              </span>
            </Nav.Link>
          </Nav>

          <Stack
            gap={1}
            direction="horizontal"
            className="header-buttons-container"
          >
            {user == null && (
              <>
                <Button onClick={() => navigate('/login')} variant="link">
                  <strong className="label-no-wrap">
                    {t('Header.Login', 'Log in')}
                  </strong>
                </Button>

                <Button
                  onClick={() => navigate('/registration')}
                  variant="link"
                >
                  <strong className="label-no-wrap">
                    {t('Header.Resistration', 'Register')}
                  </strong>
                </Button>
              </>
            )}

            {user != null && (
              <>
                <Button onClick={() => handleLogout()} variant="link">
                  <span className="label-no-wrap">
                    {t('Header.Logout', 'Log out')}
                  </span>
                </Button>

                <Button onClick={() => navigate('/settings')} variant="link">
                  <Gear size={25} color="grey" />
                </Button>
              </>
            )}

            <div className="header-spinner-container">
              {loading.some((l) => l.value === true) && (
                <Spinner animation="border" variant="secondary" size="sm" />
              )}
            </div>
          </Stack>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
