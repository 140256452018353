import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IWotTournamentRulesDto } from '../../../../../../services/api/wot-tournaments-api.service';

export function WotTournamentRules(props: {
  mode: IWotTournamentRulesDto['mode'];
  onModeChange: (mode: IWotTournamentRulesDto['mode']) => void;

  mvpBattleMode: IWotTournamentRulesDto['mvpBattleMode'];
  onMvpBattleModeChange: (
    mvpBattleMode: IWotTournamentRulesDto['mvpBattleMode'],
  ) => void;

  battlesRemovalsLimit: IWotTournamentRulesDto['battlesRemovalsLimit'] | null;
  onBattlesRemovalsLimitChange: (
    battlesRemovalsLimit: IWotTournamentRulesDto['battlesRemovalsLimit'] | null,
  ) => void;
  isBattlesRemovalsLimitValid: boolean;

  accountsRemovalsLimit: IWotTournamentRulesDto['accountsRemovalsLimit'] | null;
  onAccountsRemovalsLimitChange: (
    accountsRemovalsLimit:
      | IWotTournamentRulesDto['accountsRemovalsLimit']
      | null,
  ) => void;
  isAccountsRemovalsLimitValid: boolean;
}) {
  const { t } = useTranslation();

  return (
    <>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={2}>
          {t('WotTournament.Mode', 'Mode')}
        </Form.Label>

        <Col sm={10}>
          <Form.Select
            className="standard-input-width"
            onChange={(event) =>
              props.onModeChange(
                event.target.value as unknown as IWotTournamentRulesDto['mode'],
              )
            }
            value={props.mode}
          >
            <option value="individual">Individual</option>
            <option value="team">Team</option>
          </Form.Select>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={2}>
          {t('WotTournament.MvpBattleMode', 'MVP battle mode')}
        </Form.Label>

        <Col sm={10}>
          <Form.Select
            className="standard-input-width"
            onChange={(event) =>
              props.onMvpBattleModeChange(
                event.target
                  .value as unknown as IWotTournamentRulesDto['mvpBattleMode'],
              )
            }
            value={props.mvpBattleMode ?? undefined}
          >
            <option value="scores">Scores</option>
            <option value="damage">Damage</option>
          </Form.Select>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={2}>
          {t('WotTournament.AccountsRemovalsLimit', 'Account reserts limit')}
        </Form.Label>

        <Col sm={10}>
          <Form.Control
            className="standard-input-width"
            type="number"
            min={1}
            value={props.accountsRemovalsLimit ?? ''}
            onChange={(event) =>
              props.onAccountsRemovalsLimitChange(
                event.target.value === '' ? null : +event.target.value,
              )
            }
            isInvalid={!props.isAccountsRemovalsLimitValid}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={2}>
          {t('WotTournament.BattlesRemovalsLimit', 'Battles removals limit')}
        </Form.Label>

        <Col sm={10}>
          <Form.Control
            className="standard-input-width"
            type="number"
            min={1}
            value={props.battlesRemovalsLimit ?? ''}
            onChange={(event) =>
              props.onBattlesRemovalsLimitChange(
                event.target.value === '' ? null : +event.target.value,
              )
            }
            isInvalid={!props.isBattlesRemovalsLimitValid}
          />
        </Col>
      </Form.Group>
    </>
  );
}
