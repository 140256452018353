import { useState } from 'react';
import { Alert, Button, Col, Form, Row, Stack, Table } from 'react-bootstrap';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { Trash3 } from 'react-bootstrap-icons';
import { useAppDispatch } from '../../../../../redux/store';
import {
  LoadingParts,
  selectLoadingState,
} from '../../../loading/loadingSlice';
import {
  selectWotTournament,
  updateWotTournamentAccountAsync,
} from '../redux/wot-tournament-slice';
import { WotTournamentTeamsTabModalDelete } from './components/wot-tournament-teams-tab-modal-delete';
import { IWotTournamentTeamDto } from '../../../../../services/api/wot-tournaments-api.service';

export function WotTournamentTeamsTab() {
  const dispatch = useAppDispatch();

  const tournament = useSelector(selectWotTournament);
  const loading = useSelector(selectLoadingState);

  const [sorting, setSorting] = useState<{
    column: 'name' | 'account1' | 'account2' | 'totalScores';
    desc: boolean;
  }>({
    column: 'name',
    desc: true,
  });

  const [adding, setAdding] = useState<Partial<IWotTournamentTeamDto>>();
  const [deletingTeamName, setDeletingTeamName] = useState<string>();

  const sortedTeams = [...(tournament?.teams ?? [])].sort((a, b) => {
    switch (sorting.column) {
      case 'name':
        return sorting.desc
          ? b.name.localeCompare(a.name)
          : a.name.localeCompare(b.name);
      case 'account1':
        return sorting.desc
          ? b.accounts[0].name.localeCompare(a.accounts[0].name)
          : a.accounts[0].name.localeCompare(b.accounts[0].name);
      case 'account2':
        return sorting.desc
          ? (b.accounts[1]?.name ?? '').localeCompare(a.accounts[1]?.name ?? '')
          : (a.accounts[1]?.name ?? '').localeCompare(
              b.accounts[1]?.name ?? '',
            );
      case 'totalScores':
        return sorting.desc
          ? (b.totalScores ?? 0) - (a.totalScores ?? 0)
          : (a.totalScores ?? 0) - (b.totalScores ?? 0);
      default:
        return sorting.desc
          ? b.name.localeCompare(a.name)
          : a.name.localeCompare(b.name);
    }
  });

  return (
    <>
      {adding == null && (
        <Row>
          <Col>
            {tournament == null && (
              <Alert variant="warning">
                {t(
                  'WotTournamentTeamsTab.NoTournamentAlert',
                  'Please select or save a tournament first',
                )}
              </Alert>
            )}
          </Col>

          <Col>
            <div className="filter-container mt-2">
              <Button
                variant="primary"
                className="mb-3"
                onClick={() => setAdding({})}
                disabled={tournament == null || deletingTeamName != null}
              >
                {t('WotTournamentTeamsTab.ButtonAdd', 'Add team')}
              </Button>
            </div>
          </Col>
        </Row>
      )}

      <WotTournamentTeamsTabModalDelete
        team={tournament?.teams?.find((t) => t.name === deletingTeamName)}
        onCancelClick={() => setDeletingTeamName(undefined)}
        onOkClick={() => {
          if (tournament != null) {
            const deletingTeam = tournament.teams?.find(
              (t) => t.name === deletingTeamName,
            );

            if (deletingTeam?.accounts[0] != null) {
              dispatch(
                updateWotTournamentAccountAsync(
                  tournament.id,
                  deletingTeam.accounts[0].id,
                  null,
                ),
              );
            }

            if (deletingTeam?.accounts[1] != null) {
              dispatch(
                updateWotTournamentAccountAsync(
                  tournament.id,
                  deletingTeam.accounts[1].id,
                  null,
                ),
              );
            }
          }

          setDeletingTeamName(undefined);
        }}
      />

      <Table bordered hover className="mb-5" responsive>
        <thead>
          <tr>
            <th></th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({ column: 'name', desc: !sorting.desc })
                }
              >
                {t('WotTournamentTeamsTab.ColumnName', 'Name')}
              </Button>
            </th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({ column: 'account1', desc: !sorting.desc })
                }
              >
                {t('WotTournamentTeamsTab.ColumnAccount1', 'Account 1')}
              </Button>
            </th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({ column: 'account2', desc: !sorting.desc })
                }
              >
                {t('WotTournamentTeamsTab.ColumnAccount2', 'Account 2')}
              </Button>
            </th>
            <th>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({ column: 'totalScores', desc: !sorting.desc })
                }
              >
                {t('WotTournamentTeamsTab.ColumnTotalScores', 'Total scores')}
              </Button>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {adding && (
            <tr key="new">
              <td></td>
              <td>
                <Form.Control
                  value={adding?.name ?? ''}
                  onChange={(event) =>
                    setAdding({ ...adding, name: event.target.value })
                  }
                  isInvalid={
                    adding?.name == null ||
                    adding.name.length === 0 ||
                    tournament?.accounts?.find((a) => a.team === adding.name) !=
                      null
                  }
                />
              </td>
              <td>
                <Form.Select
                  onChange={(event) => {
                    const account = tournament?.accounts.find(
                      (a) => a.accountId === event.target.value,
                    );

                    if (account != null) {
                      const accounts = adding?.accounts ?? [];
                      accounts[0] = {
                        name: account.name,
                        id: account.accountId,
                      };
                      setAdding({ ...adding, accounts });
                    }
                  }}
                  value={(adding.accounts ?? [])[0]?.id}
                  isInvalid={
                    adding == null ||
                    adding.accounts == null ||
                    adding.accounts[0] == null
                  }
                >
                  {(adding == null ||
                    adding.accounts == null ||
                    adding.accounts[0] == null) && (
                    <option value="">
                      {t(
                        'WotTournamentTeamsTab.SelectAccount',
                        'Select account',
                      )}
                    </option>
                  )}

                  {tournament?.accounts
                    ?.filter(
                      (account) =>
                        account.team == null || account.team.length === 0,
                    )
                    .filter(
                      (account) =>
                        account.accountId !== adding?.accounts?.[1]?.id,
                    )
                    .map((account) => (
                      <option key={account.accountId} value={account.accountId}>
                        {account.name}
                      </option>
                    ))}
                </Form.Select>
              </td>
              <td>
                <Form.Select
                  onChange={(event) => {
                    const account = tournament?.accounts.find(
                      (a) => a.accountId === event.target.value,
                    );

                    if (account != null) {
                      const accounts = adding?.accounts ?? [];
                      accounts[1] = {
                        name: account.name,
                        id: account.accountId,
                      };
                      setAdding({ ...adding, accounts });
                    }
                  }}
                  value={(adding.accounts ?? [])[1]?.id}
                  isInvalid={
                    adding == null ||
                    adding.accounts == null ||
                    adding.accounts[1] == null
                  }
                >
                  {(adding == null ||
                    adding.accounts == null ||
                    adding.accounts[1] == null) && (
                    <option value="">
                      {t(
                        'WotTournamentTeamsTab.SelectAccount',
                        'Select account',
                      )}
                    </option>
                  )}

                  {tournament?.accounts
                    ?.filter(
                      (account) =>
                        account.team == null || account.team.length === 0,
                    )
                    .filter(
                      (account) =>
                        account.accountId !== adding?.accounts?.[0]?.id,
                    )
                    .map((account) => (
                      <option key={account.accountId} value={account.accountId}>
                        {account.name}
                      </option>
                    ))}
                </Form.Select>
              </td>
              <td>
                {(tournament?.accounts?.find(
                  (a) => a.accountId === adding?.accounts?.[0]?.id,
                )?.results.totalScores ?? 0) +
                  (tournament?.accounts?.find(
                    (a) => a.accountId === adding?.accounts?.[1]?.id,
                  )?.results.totalScores ?? 0)}
              </td>
              <td>
                <Stack direction="horizontal">
                  <Button
                    disabled={
                      tournament == null ||
                      adding == null ||
                      adding.name == null ||
                      adding.name === '' ||
                      adding.accounts == null ||
                      adding.accounts[0] == null ||
                      adding.accounts[1] == null ||
                      tournament?.accounts?.find(
                        (a) => a.team === adding.name,
                      ) != null ||
                      loading.find(
                        (x) => x.key === LoadingParts.WotTournamentAccountSave,
                      )?.value === true
                    }
                    onClick={() => {
                      if (
                        tournament != null &&
                        adding != null &&
                        adding.name != null &&
                        adding.name !== '' &&
                        adding.accounts != null
                      ) {
                        if (adding.accounts[0] != null) {
                          dispatch(
                            updateWotTournamentAccountAsync(
                              tournament.id,
                              adding.accounts[0].id,
                              adding.name,
                            ),
                          );
                        }

                        if (adding.accounts[1] != null) {
                          dispatch(
                            updateWotTournamentAccountAsync(
                              tournament.id,
                              adding.accounts[1].id,
                              adding.name,
                            ),
                          );
                        }
                      }

                      setAdding(undefined);
                    }}
                    variant="success"
                  >
                    {t('WotTournamentTeamsTab.ButtonSave', 'Save')}
                  </Button>

                  <Button
                    className="ms-3"
                    onClick={() => setAdding(undefined)}
                    variant="outline-danger"
                  >
                    {t('WotTournamentTeamsTab.ButtonCancel', 'Cancel')}
                  </Button>
                </Stack>
              </td>
            </tr>
          )}
          {sortedTeams.length > 0 &&
            [...sortedTeams].map((row, i) => (
              <tr key={row.name}>
                <td>{i + 1}</td>
                <td>{row.name}</td>
                <td>{row.accounts[0]?.name}</td>
                <td>{row.accounts[1]?.name}</td>
                <td>{row.totalScores}</td>
                <td>
                  <Button
                    variant="link"
                    disabled={
                      loading.find(
                        (x) => x.key === LoadingParts.WotTournamentAccountSave,
                      )?.value === true || adding != null
                    }
                    onClick={() => setDeletingTeamName(row.name)}
                  >
                    <Trash3 size={20} color="red" />
                  </Button>
                </td>
              </tr>
            ))}

          {sortedTeams.length === 0 && (
            <tr>
              <td colSpan={10} className="text-center">
                {t(
                  'WotTournamentTeamsTab.NoTableRows',
                  'There are no records matching the selected filters.',
                )}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}
