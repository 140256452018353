import { useEffect, useState } from 'react';
import { Button, Form, Row, Stack, Table } from 'react-bootstrap';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { ArrowClockwise, ArrowRepeat, Trash3 } from 'react-bootstrap-icons';
import { useAppDispatch } from '../../../../../redux/store';
import {
  LoadingParts,
  selectLoadingState,
} from '../../../loading/loadingSlice';
import { WotAccountSearch } from './components/wot-account-search';
import { WotServer } from '../../../../../services/api/interfaces/common-api-interfase';
import { Link } from 'react-router-dom';
import {
  addWotTournamentAccountAsync,
  clearWotTournamentAccountResetsAsync,
  deleteWotTournamentAccountAsync,
  getWotTournamentAsync,
  resetWotTournamentAccountAsync,
  selectWotTournament,
} from '../redux/wot-tournament-slice';
import { WotTournamentAccountsTabHeader } from './components/wot-tournament-accounts-tab-header';
import { WotTournamentAccountsTabModalDelete } from './components/wot-tournament-accounts-tab-modal-delete';
import { WotTournamentAccountsTabModalReset } from './components/wot-tournament-accounts-tab-modal-reset';
import { WotTournamentAccountsTabModalResetClear } from './components/wot-tournament-accounts-tab-modal-reset-clear';

export function WotTournamentAccountsTab() {
  const PageSize = 100;

  const dispatch = useAppDispatch();

  const tournament = useSelector(selectWotTournament);
  const loading = useSelector(selectLoadingState);

  const [sorting, setSorting] = useState<{
    column:
      | 'name'
      | 'server'
      | 'id'
      | 'totalScores'
      | 'bonusScores'
      | 'battleScores'
      | 'battles'
      | 'wins'
      | 'frags'
      | 'damage'
      | 'xp'
      | 'removals'
      | 'snapshottedAt';
    desc: boolean;
  }>({
    column: 'totalScores',
    desc: true,
  });

  const [adding, setAdding] = useState(false);
  const [addingServer, setAddingServer] = useState<WotServer>('EU');
  const [addingId, setAddingId] = useState<string>();

  const [deletingAccountId, setDeletingAccountId] = useState<string>();
  const [forceDelete, setForceDelete] = useState<boolean>(false);

  const [resetingAccountId, setResetingAccountId] = useState<string>();
  const [forceReset, setForceReset] = useState<boolean>(false);

  const [clearingResetsAccountId, setClearingResetsAccountId] =
    useState<string>();

  const [accountFilter, setAccountFilter] = useState<string>('');
  const [page, setPage] = useState(1);

  const sortedAccounts = (tournament?.accounts ?? [])
    .filter(
      (x) =>
        accountFilter == null ||
        accountFilter === '' ||
        x.name.toLowerCase().includes(accountFilter.toLowerCase()) ||
        x.accountId.toLowerCase().includes(accountFilter.toLowerCase()) ||
        x.wotId.toString().toLowerCase().includes(accountFilter.toLowerCase()),
    )
    .sort((a, b) => {
      switch (sorting.column) {
        case 'name':
          return sorting.desc
            ? b.name.localeCompare(a.name)
            : a.name.localeCompare(b.name);
        case 'server':
          return sorting.desc
            ? b.server.localeCompare(a.server)
            : a.server.localeCompare(b.server);
        case 'id':
          return sorting.desc ? b.wotId - a.wotId : a.wotId - b.wotId;
        case 'totalScores':
          return sorting.desc
            ? (b.results?.totalScores ?? 0) - (a.results?.totalScores ?? 0)
            : (a.results?.totalScores ?? 0) - (b.results?.totalScores ?? 0);
        case 'bonusScores':
          return sorting.desc
            ? (b.results?.bonusScores ?? 0) - (a.results?.bonusScores ?? 0)
            : (a.results?.bonusScores ?? 0) - (b.results?.bonusScores ?? 0);
        case 'battleScores':
          return sorting.desc
            ? (b.results?.battleScores ?? 0) - (a.results?.battleScores ?? 0)
            : (a.results?.battleScores ?? 0) - (b.results?.battleScores ?? 0);
        case 'battles':
          return sorting.desc
            ? (b.results?.battles ?? 0) - (a.results?.battles ?? 0)
            : (a.results?.battles ?? 0) - (b.results?.battles ?? 0);
        case 'wins':
          return sorting.desc
            ? (b.results?.wins ?? 0) - (a.results?.wins ?? 0)
            : (a.results?.wins ?? 0) - (b.results?.wins ?? 0);
        case 'frags':
          return sorting.desc
            ? (b.results?.frags ?? 0) - (a.results?.frags ?? 0)
            : (a.results?.frags ?? 0) - (b.results?.frags ?? 0);
        case 'damage':
          return sorting.desc
            ? (b.results?.damage ?? 0) - (a.results?.damage ?? 0)
            : (a.results?.damage ?? 0) - (b.results?.damage ?? 0);
        case 'xp':
          return sorting.desc
            ? (b.results?.xp ?? 0) - (a.results?.xp ?? 0)
            : (a.results?.xp ?? 0) - (b.results?.xp ?? 0);
        case 'removals':
          return sorting.desc
            ? (tournament?.accountsRemovals?.filter(
                (x) => x.accountId === b.accountId,
              )?.length ?? 0) -
                (tournament?.accountsRemovals?.filter(
                  (x) => x.accountId === a.accountId,
                )?.length ?? 0)
            : (tournament?.accountsRemovals?.filter(
                (x) => x.accountId === a.accountId,
              )?.length ?? 0) -
                (tournament?.accountsRemovals?.filter(
                  (x) => x.accountId === b.accountId,
                )?.length ?? 0);
        case 'snapshottedAt':
          return sorting.desc
            ? (b.snapshottedAt ?? '').localeCompare(a.snapshottedAt ?? '')
            : (a.snapshottedAt ?? '').localeCompare(b.snapshottedAt ?? '');
        default:
          return sorting.desc
            ? (b.results?.totalScores ?? 0) - (a.results?.totalScores ?? 0)
            : (a.results?.totalScores ?? 0) - (b.results?.totalScores ?? 0);
      }
    });

  useEffect(() => {
    if (
      tournament?.id != null &&
      tournament.id !== '' &&
      tournament.id !== 'new'
    ) {
      const interval = setInterval(() => {
        dispatch(getWotTournamentAsync(tournament.id, true));
      }, 1000 * 30);

      return () => clearInterval(interval);
    }
  }, [dispatch, tournament?.id]);

  return (
    <>
      {adding === false && (
        <Row>
          <WotTournamentAccountsTabHeader
            pageSize={PageSize}
            page={page}
            onPageChange={(p) => setPage(p)}
            filter={accountFilter}
            onFilterChange={(f) => setAccountFilter(f)}
            disabledAccountAdding={deletingAccountId != null}
            onAccountAddClick={() => setAdding(true)}
          />
        </Row>
      )}

      <WotTournamentAccountsTabModalDelete
        account={tournament?.accounts.find(
          (a) => a.accountId === deletingAccountId,
        )}
        force={forceDelete}
        onForceChange={() => setForceDelete(!forceDelete)}
        onCancelClick={() => {
          setDeletingAccountId(undefined);
          setForceDelete(false);
        }}
        onOkClick={() => {
          if (tournament?.id != null && deletingAccountId != null) {
            dispatch(
              deleteWotTournamentAccountAsync(
                tournament.id,
                deletingAccountId,
                forceDelete,
              ),
            );
          }
          setDeletingAccountId(undefined);
          setForceDelete(false);
        }}
      />

      <WotTournamentAccountsTabModalReset
        account={tournament?.accounts.find(
          (a) => a.accountId === resetingAccountId,
        )}
        force={forceReset}
        onForceChange={() => setForceReset(!forceReset)}
        onCancelClick={() => {
          setResetingAccountId(undefined);
          setForceReset(false);
        }}
        onOkClick={() => {
          if (tournament?.id != null && resetingAccountId != null) {
            dispatch(
              resetWotTournamentAccountAsync(
                tournament.id,
                resetingAccountId,
                forceReset,
              ),
            );
          }
          setResetingAccountId(undefined);
          setForceReset(false);
        }}
      />

      <WotTournamentAccountsTabModalResetClear
        account={tournament?.accounts.find(
          (a) => a.accountId === clearingResetsAccountId,
        )}
        onCancelClick={() => setClearingResetsAccountId(undefined)}
        onOkClick={() => {
          if (tournament?.id != null && clearingResetsAccountId != null) {
            dispatch(
              clearWotTournamentAccountResetsAsync(
                tournament.id,
                clearingResetsAccountId,
              ),
            );
          }
          setClearingResetsAccountId(undefined);
        }}
      />

      <Table bordered hover className="mb-5" responsive>
        <thead>
          <tr>
            <th></th>
            <th style={{ whiteSpace: 'nowrap' }}>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({ column: 'name', desc: !sorting.desc })
                }
              >
                {t('WotTournamentAccountsTab.ColumnName', 'Name ')}
                {sorting.column === 'name' && <>{sorting.desc ? '▼' : '▲'}</>}
              </Button>
            </th>
            <th style={{ whiteSpace: 'nowrap' }}>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({ column: 'server', desc: !sorting.desc })
                }
              >
                {t('WotTournamentAccountsTab.ColumnServer', 'Server ')}
                {sorting.column === 'server' && <>{sorting.desc ? '▼' : '▲'}</>}
              </Button>
            </th>
            <th style={{ whiteSpace: 'nowrap' }}>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({ column: 'id', desc: !sorting.desc })
                }
              >
                {t('WotTournamentAccountsTab.ColumnId', 'ID ')}
                {sorting.column === 'id' && <>{sorting.desc ? '▼' : '▲'}</>}
              </Button>
            </th>
            <th style={{ whiteSpace: 'nowrap' }}>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({ column: 'totalScores', desc: !sorting.desc })
                }
              >
                {t('WotTournamentAccountsTab.ColumnTotalScores', 'Scores ')}
                {sorting.column === 'totalScores' && (
                  <>{sorting.desc ? '▼' : '▲'}</>
                )}
              </Button>
            </th>
            <th style={{ whiteSpace: 'nowrap' }}>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({ column: 'bonusScores', desc: !sorting.desc })
                }
              >
                {t('WotTournamentAccountsTab.ColumnBonusScores', 'Bonuses ')}
                {sorting.column === 'bonusScores' && (
                  <>{sorting.desc ? '▼' : '▲'}</>
                )}
              </Button>
            </th>
            <th style={{ whiteSpace: 'nowrap' }}>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({
                    column: 'battles',
                    desc: !sorting.desc,
                  })
                }
              >
                {t('WotTournamentAccountsTab.ColumnBattles', 'Battles ')}
                {sorting.column === 'battles' && (
                  <>{sorting.desc ? '▼' : '▲'}</>
                )}
              </Button>
            </th>
            <th style={{ whiteSpace: 'nowrap' }}>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({
                    column: 'wins',
                    desc: !sorting.desc,
                  })
                }
              >
                {t('WotTournamentAccountsTab.ColumnWins', 'Wins ')}
                {sorting.column === 'wins' && <>{sorting.desc ? '▼' : '▲'}</>}
              </Button>
            </th>
            <th style={{ whiteSpace: 'nowrap' }}>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({
                    column: 'frags',
                    desc: !sorting.desc,
                  })
                }
              >
                {t('WotTournamentAccountsTab.ColumnFrags', 'Frags ')}
                {sorting.column === 'frags' && <>{sorting.desc ? '▼' : '▲'}</>}
              </Button>
            </th>
            <th style={{ whiteSpace: 'nowrap' }}>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({
                    column: 'damage',
                    desc: !sorting.desc,
                  })
                }
              >
                {t('WotTournamentAccountsTab.ColumnDamage', 'Damage ')}
                {sorting.column === 'damage' && <>{sorting.desc ? '▼' : '▲'}</>}
              </Button>
            </th>
            <th style={{ whiteSpace: 'nowrap' }}>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({
                    column: 'xp',
                    desc: !sorting.desc,
                  })
                }
              >
                {t('WotTournamentAccountsTab.ColumnXp', 'XP ')}
                {sorting.column === 'xp' && <>{sorting.desc ? '▼' : '▲'}</>}
              </Button>
            </th>
            <th style={{ whiteSpace: 'nowrap' }}>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({
                    column: 'removals',
                    desc: !sorting.desc,
                  })
                }
              >
                {t('WotTournamentAccountsTab.ColumnRemovals', 'Resets s/b ')}
                {sorting.column === 'removals' && (
                  <>{sorting.desc ? '▼' : '▲'}</>
                )}
              </Button>
            </th>
            <th style={{ whiteSpace: 'nowrap' }}>
              <Button
                variant="link"
                onClick={() =>
                  setSorting({
                    column: 'snapshottedAt',
                    desc: !sorting.desc,
                  })
                }
              >
                {t(
                  'WotTournamentAccountsTab.ColumnSnapshottedAt',
                  'Snapshotted ',
                )}
                {sorting.column === 'snapshottedAt' && (
                  <>{sorting.desc ? '▼' : '▲'}</>
                )}
              </Button>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {adding && (
            <tr key="new">
              <td></td>
              <td>
                <WotAccountSearch
                  server={addingServer}
                  onSelect={(selected) => {
                    setAddingId(selected.account_id.toString());
                  }}
                />
              </td>
              <td>
                <Form.Select
                  onChange={(event) =>
                    setAddingServer(event.target.value as WotServer)
                  }
                  value={addingServer}
                >
                  <option value="EU">EU</option>
                  <option value="RU">RU</option>
                  <option value="NA">NA</option>
                  <option value="ASIA">ASIA</option>
                </Form.Select>
              </td>
              <td>{addingId || '-'}</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>
                <Stack direction="horizontal">
                  <Button
                    disabled={
                      addingId == null ||
                      addingId.length === 0 ||
                      loading.find(
                        (x) => x.key === LoadingParts.WotTournamentAccountSave,
                      )?.value === true
                    }
                    onClick={() => {
                      dispatch(
                        addWotTournamentAccountAsync(
                          tournament?.id as string,
                          +(addingId as string),
                          addingServer,
                        ),
                      );
                      setAdding(false);
                      setAddingId('');
                      setAddingServer('EU');
                    }}
                    variant="success"
                  >
                    {t('WotTournamentAccountsTab.ButtonSave', 'Save')}
                  </Button>

                  <Button
                    className="ms-3"
                    onClick={() => {
                      setAdding(false);
                      setAddingId('');
                      setAddingServer('EU');
                    }}
                    variant="outline-danger"
                  >
                    {t('WotTournamentAccountsTab.ButtonCancel', 'Cancel')}
                  </Button>
                </Stack>
              </td>
            </tr>
          )}
          {sortedAccounts.length > 0 &&
            [...sortedAccounts]
              .slice((page - 1) * PageSize, page * PageSize)
              .map((row, i) => (
                <tr key={row.wotId}>
                  <td>{(page - 1) * PageSize + i + 1}</td>
                  <td>{row.name}</td>
                  <td>{row.server}</td>
                  <td>{row.wotId}</td>
                  <td>{row.results?.totalScores ?? '-'}</td>
                  <td>{row.results?.bonusScores ?? '-'}</td>
                  <td>
                    <Link to={`?accountId=${row.accountId}#battles`}>
                      {row.results?.battles ?? '-'}
                    </Link>
                  </td>
                  <td>{row.results?.wins ?? '-'}</td>
                  <td>{row.results?.frags ?? '-'}</td>
                  <td>{row.results?.damage ?? '-'}</td>
                  <td>{row.results?.xp ?? '-'}</td>
                  <td>
                    <Stack direction="horizontal">
                      {`${
                        tournament?.accountsRemovals?.filter(
                          (x) => x.accountId === row.accountId,
                        ).length ?? 0
                      }/${row.results.deletedBattles}`}

                      <Button
                        variant="link"
                        disabled={
                          loading.find(
                            (x) =>
                              x.key === LoadingParts.WotTournamentAccountSave,
                          )?.value === true ||
                          adding === true ||
                          clearingResetsAccountId != null
                        }
                        onClick={() => {
                          setClearingResetsAccountId(row.accountId);
                        }}
                      >
                        <ArrowClockwise size={20} color="red" />
                      </Button>
                    </Stack>
                  </td>

                  <td
                    style={{ whiteSpace: 'nowrap' }}
                    className={
                      row.snapshottedAt != null &&
                      Date.now() - new Date(row.snapshottedAt).getTime() <
                        1000 * 60 * 4
                        ? 'bg-success'
                        : row.snapshottedAt != null &&
                            Date.now() - new Date(row.snapshottedAt).getTime() <
                              1000 * 60 * 6
                          ? 'bg-warning'
                          : 'bg-danger'
                    }
                  >
                    {row.snapshottedAt == null
                      ? '-'
                      : `${
                          Math.floor(
                            (Date.now() -
                              new Date(row.snapshottedAt).getTime()) /
                              1000 /
                              60,
                          ) < 10
                            ? `0${Math.floor(
                                (Date.now() -
                                  new Date(row.snapshottedAt).getTime()) /
                                  1000 /
                                  60,
                              )}`
                            : Math.floor(
                                (Date.now() -
                                  new Date(row.snapshottedAt).getTime()) /
                                  1000 /
                                  60,
                              )
                        }m ${
                          Math.floor(
                            ((Date.now() -
                              new Date(row.snapshottedAt).getTime()) /
                              1000) %
                              60,
                          ) < 10
                            ? `0${Math.floor(
                                ((Date.now() -
                                  new Date(row.snapshottedAt).getTime()) /
                                  1000) %
                                  60,
                              )}`
                            : Math.floor(
                                ((Date.now() -
                                  new Date(row.snapshottedAt).getTime()) /
                                  1000) %
                                  60,
                              )
                        }s`}
                  </td>

                  <td>
                    <Stack direction="horizontal" gap={3}>
                      <Button
                        variant="link"
                        disabled={
                          loading.find(
                            (x) =>
                              x.key === LoadingParts.WotTournamentAccountSave,
                          )?.value === true ||
                          adding === true ||
                          deletingAccountId != null
                        }
                        onClick={() => {
                          setDeletingAccountId(row.accountId);
                        }}
                      >
                        <Trash3 size={20} color="red" />
                      </Button>

                      <Button
                        variant="link"
                        disabled={
                          loading.find(
                            (x) =>
                              x.key === LoadingParts.WotTournamentAccountSave,
                          )?.value === true ||
                          adding === true ||
                          resetingAccountId != null
                        }
                        onClick={() => setResetingAccountId(row.accountId)}
                      >
                        <ArrowRepeat size={20} color="red" />
                      </Button>
                    </Stack>
                  </td>
                </tr>
              ))}

          {sortedAccounts.length === 0 && (
            <tr>
              <td colSpan={14} className="text-center">
                {t(
                  'WotTournamentAccountsTab.NoTableRows',
                  'There are no records matching the selected filters.',
                )}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}
