export abstract class LogService {
  public static debug(message: string): void {
    console.debug(LogService.format(message));
  }

  public static log(message: string): void {
    console.log(LogService.format(message));
  }

  public static warn(message: string | Error): void {
    console.warn(LogService.format(message));
  }

  public static error(error: string | Error): void {
    let errorToLog: Error = error as Error;
    if (!(error instanceof Error)) {
      errorToLog = new Error(LogService.format(error));
    }

    console.error(errorToLog);
  }

  private static format(message: string | unknown): string {
    if (typeof message == 'object') {
      return `[APP] ${JSON.stringify(message)}`;
    }

    return `[APP] ${message as string}`;
  }
}
