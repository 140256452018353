import { Button, Modal } from 'react-bootstrap';
import { t } from 'i18next';
import { WotTournamentShortDto } from '../../../../../services/api/wot-tournaments-api.service';

export function TournamentsListModalDelete(props: {
  deleting: WotTournamentShortDto | undefined;

  onCancelClick: () => void;
  onDeleteClick: () => void;
}) {
  return (
    <Modal show={props.deleting != null} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>
          {t('WotTournamentsList.DeletingModalTitle', 'Deleting tournament')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {t(
          'WotTournamentsList.DeletingModalBody',
          'You are about to delete a tournament {{name}}. Are you sure?',
          {
            name: props.deleting?.name,
          },
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.onCancelClick()}>
          {t('WotTournamentsList.DeletingCancelButton', 'Cancel')}
        </Button>
        <Button variant="danger" onClick={() => props.onDeleteClick()}>
          {t('WotTournamentsList.DeletingDeleteButton', 'Delete')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
