/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import * as Translation_RU from './ru/translation.json';
import * as Translation_EN from './en/translation.json';
import * as Translation_UK from './uk/translation.json';

// eslint-disable-next-line import/no-named-as-default-member
void i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
    },
    fallbackLng: 'en',
    supportedLngs: ['en', 'uk', 'ru'],
    returnEmptyString: false,
    resources: {
      en: {
        translation: Translation_EN,
      },
      uk: {
        translation: Translation_UK,
      },
      ru: {
        translation: Translation_RU,
      },
    },
    interpolation: { escapeValue: false },
  });

export default i18n;
